import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';


@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    constructor(private keycloakService: KeycloakService) { }
    public model: any[];

    ngOnInit() {
        if (this.keycloakService.isUserInRole("closingAdmin") || this.keycloakService.isUserInRole("closingSuperUser")) {
            this.model = [
                { label: 'Closing', icon: 'pi pi-desktop', routerLink: ['/'] },
                { label: 'Closing R3', icon: 'pi pi-home', routerLink: ['/R3'] },
                {
                    label: 'Admin', icon: 'pi pi-user',
                    items: [
                        { label: 'Banks', icon: 'pi pi-home', routerLink: ['/banks'] },
                        { label: 'Notary', icon: 'pi pi-book', routerLink: ['/notary'] },
                        { label: 'Contractor', icon: 'pi pi-users', routerLink: ['/contractor'] },
                        { label: 'States', icon: 'pi pi-building', routerLink: ['/states'] },
                    ]
                },{
                    label: 'Notary Invoice', icon: 'pi pi-book',
                    items: [
                        { label: 'Invoices', icon: 'pi pi-book', routerLink: ['/notaryInvoice'] },
                    ]
                },

            ];

        } else if (this.keycloakService.isUserInRole("closingNotary") || this.keycloakService.isUserInRole("closingUser")) {
            this.model = [
                { label: 'Closing', icon: 'pi pi-desktop', routerLink: ['/'] },
                { label: 'Closing R3', icon: 'pi pi-home', routerLink: ['/R3'] },
                {
                    label: 'Notary Invoice', icon: 'pi pi-book',
                    items: [
                        { label: 'Invoices', icon: 'pi pi-book', routerLink: ['/notaryInvoice'] },
                    ]
                }

            ];
        } else if (this.keycloakService.isUserInRole("closingSellingAgent") && this.keycloakService.isUserInRole("closingListingAgent")) {
            this.model = [
                {
                    label: 'Type of Broker', icon: 'pi pi-user',
                    items: [
                        { label: 'ListingAgent', icon: 'pi pi-users', routerLink: ['/listingAgent'] },
                        { label: 'SellingAgent', icon: 'pi pi-users', routerLink: ['/sellingAgent'] }
                    ]
                },


            ];
        }
        else {
            this.model = [
                { label: 'Closing', icon: 'pi pi-desktop', routerLink: ['/'] }
            ];
        }

    }

}
