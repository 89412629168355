import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListingAgentElement } from 'app/models/listingAgent.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListingAgentServicesService {
  url:string = environment.urlBase
  constructor(private http: HttpClient) { }
  headers: HttpHeaders = new HttpHeaders({
    "Content-type": "application/json",
    "accept": "*"
  });
  getListingAgents() {
    let url_listingAgent = this.url+'users/listing-agents';
    return this.http.get<ListingAgentElement[]>(url_listingAgent,{headers: this.headers});
  }

  addListingAgent(listingAgent) {
    let url_listingAgent = this.url+'listingAgent/';
    return this.http.post<ListingAgentElement>(url_listingAgent,listingAgent,{headers: this.headers});
  }
  updateListingAgent(listingAgent) {
    let url_listingAgent = this.url+'listingAgent/';
    return this.http.put<ListingAgentElement>(url_listingAgent,listingAgent,{headers: this.headers});
  }

  deleteListingAgent(listingAgent){
    let url_listingAgent = this.url+'listingAgent/'+listingAgent.userName;
    return this.http.delete<ListingAgentElement>(url_listingAgent, {observe : 'response'});
  }

  getListingAgentPaginator(first, total){
    let url_banks = this.url+'listingAgent/'+first+'/'+total;
    return this.http.get<any>(url_banks,{headers: this.headers});
  }
}
