<div class="card">
    <form [formGroup]="formBank" (ngSubmit)="onSubmit()">

        <div class="p-fluid grid">

            <div class="field col-6 md:col-6">
                <label for="bankName">Bank Id</label>
                <input [readonly]="bank" type="number" name="bankId" id="bankId" pInputText formControlName="bankId">
            </div>

            <div class="field col-6 md:col-6">
                <label for="bankName">Name</label>
                <input type="text" name="bankName" id="bankName" pInputText formControlName="bankName">
            </div>
            <div class="field col-6 md:col-6">
                <label for="bankCity">City</label>
                <input type="text" name="bankCity" id="bankCity" pInputText formControlName="bankCity">


            </div>



            <div *ngIf="bank" class="field col-6 md:col-6">

                <label for="state">State</label>


                <p-dropdown [options]="states" [styleClass]="'squ-primeng-dropdowlist'" name="selectedGenderName"
                    ngValue="state" formControlName="bankState" optionLabel="stateName"></p-dropdown>

            </div>

            <div *ngIf="!bank" class="field col-6 md:col-6">
                <label for="state">State</label>
                <select pInputText id="bankState" name="bankState" formControlName="bankState">
                    <option value="">Choose State</option>
                    <option *ngFor="let state of states" [ngValue]="state">
                        {{ state.stateName }}
                    </option>
                </select>
            </div>

            <div class="field col-6 md:col-6">
                <label for="Address">Address</label>

                <input type="text" pInputText id="bankAddress" name="bankAddress" formControlName="bankAddress">

            </div>
        </div>
        <br />

        <div class="field col-6 md:col-6">
            <button *ngIf="this.isEdit== false" class="mr-2" type="submit" [disabled]="formBank.invalid" pButton
                label="Save"></button>
            <button *ngIf="this.isEdit== true" class="mr-2" type="submit" [disabled]="formBank.invalid" pButton
                label="Update"></button>
            <button type="button" pButton label="Cancel" (click)="Cancel()"></button>
        </div>

    </form>
</div>