<p-toast></p-toast>
<div class="card">
    <p-table #dt [rowHover]="true" [value]="banks" [lazy]="true" (onLazyLoad)="loadBanks($event)" 
        [(selection)]="selectedBanks" [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [rows]="10"
        [totalRecords]="allRegisters" [loading]="loading"
        [globalFilterFields]="['bankId','bankName', 'bankCity', 'bankState', 'bankAddress']">

        <ng-template pTemplate="caption">
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <button type="button" (click)="createBank()" pButton icon="pi pi-plus" label="New"
                        class="mr-2"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedBanks()"
                        [disabled]="!selectedBanks || !selectedBanks.length"></button>
                </ng-template>

                <ng-template pTemplate="right">
                    <span class="p-input-icon-left">
                        <em class="pi pi-search">
                            <input pInputText type="text"
                                (input)="dt.filterGlobal($any($event.target).value, 'contains')"
                                placeholder="Search..." />
                        </em>
                    </span>
                </ng-template>
            </p-toolbar>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem" id=" ">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th id=" " pSortableColumn="bankId">Bank Id. <p-sortIcon field="bankId"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="bankName">Name <p-sortIcon field="bankName"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="bankCity">City <p-sortIcon field="bankCity"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="bankState">State <p-sortIcon field="bankState"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="active">Active <p-sortIcon field="bankState"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="bankAddress">Address <p-sortIcon field="bankAddress"></p-sortIcon>
                </th>
                <th id=" "> </th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-bank>
            <tr>
                <td>
                    <p-tableCheckbox [value]="bank"></p-tableCheckbox>
                </td>
                <td>{{bank.bankId}}</td>
                <td>{{bank.bankName}}</td>
                <td>{{bank.bankCity}}</td>
                <td>{{bank.bankState.stateName}}</td>
                <td *ngIf="bank.active">
                    <p-tag styleClass="mr-2" icon="pi pi-check" severity="success" value="Yes"></p-tag>
                </td>
                <td *ngIf="!bank.active">
                    <p-tag styleClass="mr-2" icon="pi pi-times" severity="danger" value="No"></p-tag>
                </td>
                <td>{{bank.bankAddress}}</td>
                <td>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded mr-2"
                        (click)="editBank(bank)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded  mr-2"
                        (click)="deleteBank(bank)"></button>
                    <button pButton pRipple icon="pi pi-eye" pTooltip="Detail Bank" class="p-button-rounded mr-2"
                        (click)="detalleBank(bank)"></button>
                    <button *ngIf="bank.active" pButton pRipple icon="pi pi-times" pTooltip="inactivate Bank"
                        class="p-button-rounded" (click)="InactivateBank(bank)"></button>
                    <button *ngIf="!bank.active" pButton pRipple icon="pi pi-check" pTooltip="Activate Bank"
                        class="p-button-rounded" (click)="ActivateBank(bank)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                All Registers {{banks ? allRegisters : 0 }} banks.
            </div>
        </ng-template>
    </p-table>
</div>