<p-toast></p-toast>
<div class="card">

    <p-table #dt [value]="contractors" [paginator]="true" [rows]="10" [totalRecords]="allRegisters" [loading]="loading"
        [(selection)]="selectedcontractors" [lazy]="true" (onLazyLoad)="loadContractors($event)"  
        [rowsPerPageOptions]="[10,25,50]"
        [globalFilterFields]="['contractorId','name', 'middleName', 'lastName', 'address','zipcode','city','state']">
        
        <ng-template pTemplate="caption">
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <button type="button" (click)="createContractor()" pButton icon="pi pi-plus" label="New"
                        class="mr-2"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedContractor()"
                        [disabled]="!selectedcontractors || !selectedcontractors.length"></button>
                </ng-template>

                <ng-template pTemplate="right">
                    <span class="p-input-icon-left">
                        <em class="pi pi-search"></em>
                        <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Search..." />
                    </span>
                </ng-template>
            </p-toolbar>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem" id=" ">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th id=" ">Asset Manager Id <p-sortIcon field="contractorId"></p-sortIcon>
                </th>
                <th id=" ">Name <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th id=" ">City <p-sortIcon field="city"></p-sortIcon>
                </th>
                <th id=" ">State <p-sortIcon field="state"></p-sortIcon>
                </th>
                <th id=" ">Address <p-sortIcon field="address"></p-sortIcon>
                </th>
                <th id=" ">Zipcode <p-sortIcon field="zipcode"></p-sortIcon>
                </th>
                <th id=" ">Active<p-sortIcon field="active"></p-sortIcon>
                </th>
                <th id=" "> </th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contractor>
            <tr>
                <td>
                    <p-tableCheckbox [value]="contractor"></p-tableCheckbox>
                </td>
                <td>{{contractor.contractorId}}</td>
                <td>{{contractor.name}}</td>
                <td>{{contractor.city}}</td>
                <td>{{contractor.state.stateName}}</td>
                <td>{{contractor.address}}</td>
                <td>{{contractor.zipcode}}</td>
                <td *ngIf="contractor.active">
                    <p-tag styleClass="mr-2" icon="pi pi-check" severity="success" value="Yes"></p-tag>
                </td>
                <td *ngIf="!contractor.active">
                    <p-tag styleClass="mr-2" icon="pi pi-times" severity="danger" value="No"></p-tag>
                </td>
                <td>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded mr-2"
                        (click)="editContractor(contractor)"></button> 
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded  mr-2"
                        (click)="deleteContractor(contractor)"></button> 
                    <button pButton pRipple icon="pi pi-eye" pTooltip="Detail contractor"
                        class="p-button-rounded mr-2" (click)="detailNotay(contractor)"></button>
                
                        <button *ngIf="contractor.active" pButton pRipple icon="pi pi-times" pTooltip="Inactivate Contractor"
                            class="p-button-rounded" (click)="InactivateContractor(contractor)"></button>
                            <button *ngIf="!contractor.active" pButton pRipple icon="pi pi-check" pTooltip="Activate Contractor"
                            class="p-button-rounded" (click)="ActivateContractor(contractor)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                All Registers {{contractors ? allRegisters : 0 }} contractors.
            </div>
        </ng-template>
    </p-table>
</div>

