import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { StateElement } from 'app/models/state.model';


@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  url:string = environment.urlBase
  constructor(private http: HttpClient) { }
  headers: HttpHeaders = new HttpHeaders({
    "Content-type": "application/json"
  });

  getStates() {
    let urlBase = this.url+'states/';
    return this.http.get<any>(urlBase,{headers: this.headers});
  }

  updateState(state) {
    let urlBase = this.url+'states/';
    return this.http.put<StateElement>(urlBase,state,{headers: this.headers});
  }

  addState(state) {
    let urlBase = this.url+'states/';
    return this.http.post<StateElement>(urlBase,state,{headers: this.headers});
  }
  deleteState(state){
  
    let urlBase = this.url+'states/'+state.stateId;
    return this.http.delete<StateElement>(urlBase,{observe : 'response'});
  }

  getStatesPaginator(first, total){
    let urlBase = this.url+'states/'+first+'/'+total;
    return this.http.get<any>(urlBase,{headers: this.headers});
  }


}
