import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { ClosingElement } from './models/closing.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'closingpr';

  layoutMode = 'static';

  darkMenu = false;

  inputStyle = 'filled';

  ripple = true;

  compactMode = true;

  allGadets: ClosingElement[] = [];

  constructor(private primengConfig: PrimeNGConfig) { }

  ngOnInit() {
    this.compactMode = false;
    this.inputStyle = 'filled';
    this.primengConfig.ripple = true;
  }
}

