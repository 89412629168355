<div class="login">
        <form class="custom-form" method="post">
                <div class="header-form">
                        <p>send</p>
                </div>
                <img alt="logo" class="image-form" src="../../assets/logo.png">
                
                
                <button type="submit" (click)="send()">Send</button>
        </form>
</div>
    
