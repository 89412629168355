<p-toast></p-toast>
<div class="card">
    <p-table #dt1 [value]="R3s" [rows]="10" [totalRecords]="allRegisters"  [showCurrentPageReport]="true" id="myTable" (onLazyLoad)="onLazyLoad($event)" [lazy]="true"
        [(first)]="first" [rowsPerPageOptions]="[10,25,50]" [loading]="loading" styleClass="p-datatable-gridlines" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [(selection)]="selectedR3s"
        [globalFilterFields]="['fha','buyerInformation.name','propertyInformation.propertyAddress','propertyInformation.city','preClosingInformation.amContractor.name']">
        <ng-template pTemplate="caption">
            <div class="grid">
                <div class="field col-12 md:col-4" [hidden]="!isAdmin && !isUser">
                    <button type="button" (click)="openDialog()" pButton icon="pi pi-plus" label="New Closing R3"
                        class="mr-2"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-danger mr-2"
                        (click)="deleteSelectedR3s()"
                        [disabled]="!selectedR3s || !selectedR3s.length"></button>
                </div>
                <div class="field col-12 md:col-4">
                </div>
                <div class="flex justify-content-end flex-wrap card-container green-container col-12 md:col-4 md:flex-row" *ngIf="isClosingAsigned">
                    <div class="flex align-items-center justify-content-center field  ">
                        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                            class="p-button-success mr-2" pTooltip="Export to XLS" tooltipPosition="bottom"></button>
                        <button type="button" (click)="openDialogSearch()" pButton icon="pi pi-search"
                            label="Advanced Search" class="mr-2"></button>
                        <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash"
                            (click)="clear()"></button>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 3rem" id=" " *ngIf="isAdmin">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th id=" " pSortableColumn="closingName">
                    <div class="flex justify-content-center align-items-center">
                        File Number
                    </div>
                </th>
                <th id=" ">
                    <div class="flex justify-content-center align-items-center">
                        Status
                    </div>
                </th>
                <th id=" " pSortableColumn="propertyInformation.propertyAddress">
                    <div class="flex justify-content-center align-items-center">
                        Buyers Name
                    </div>
                </th>
                <th id=" " pSortableColumn="propertyInformation.propertyAddress">
                    <div class="flex justify-content-center align-items-center">
                        Property Address
                    </div>
                </th>
                <th id=" " pSortableColumn="propertyInformation.city" [hidden]="isAdmin || isUser">
                    <div class="flex justify-content-center align-items-center">
                        Sale Amount
                    </div>
                </th>
                <th id=" " [hidden]="isListingAgent || isSellingAgent || isNotary">
                    <div class="flex justify-content-center align-items-center">
                        Contract Date
                    </div>
                </th>
                <th id=" " [hidden]="isAdmin || isUser">
                    <div class="flex justify-content-center align-items-center">
                        Closing Date
                    </div>
                </th>
                <th id=" " [hidden]="isListingAgent || isSellingAgent || isNotary" pSortableColumn="preClosingInformation.amContractor.name">
                    <div class="flex justify-content-center align-items-center">
                        Asset Manager Contractor
                    </div>
                </th>
                <th id=" " pSortableColumn="preClosingInformation.amContractor.name">
                    <div class="flex justify-content-center align-items-center">
                        Lender Name
                    </div>
                </th>
                <!-- Campos Visibles para el sellingAgent-->
                <th id=" ">
                    <div class="flex justify-content-center align-items-center">
                        Selling Agent Name
                    </div>
                </th>
                <th id=" " [hidden]="isAdmin || isUser">
                    <div class="flex justify-content-center align-items-center">
                        Selling Agent PhoneNumber
                    </div>
                </th>
                <!-- Campos Visibles para el listingAgent-->
                <th id=" ">
                    <div class="flex justify-content-center align-items-center">
                        Listing Agent Name
                    </div>
                </th>
                <th id=" " [hidden]="isAdmin || isUser">
                    <div class="flex justify-content-center align-items-center">
                        Listing Agent PhoneNumber
                    </div>
                </th>
                <th id=" " *ngIf="isAdmin || isUser"> </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-R3>
            <tr>
                <td *ngIf="isAdmin">
                    <p-tableCheckbox [value]="R3"></p-tableCheckbox>
                </td>
                <td>{{R3.r3FileNumber}}</td>
                <td>{{R3.status}}</td>
                <td>
                    <div *ngFor="let buyer of R3.buyerInformation">
                        {{( (buyer.name ? buyer.name : '') + " " +
                        (buyer.middleName ? buyer.middleName : '' ) + " " +
                        (buyer.lastName? buyer.lastName : '') + "\n")}}
                    </div>
                </td>
                <td>
                    {{((R3.propertyInformation.propertyAddress ? R3.propertyInformation.propertyAddress : '') + " "+ 
                    (R3.propertyInformation.city ? R3.propertyInformation.city : '') +", "+
                    (R3.propertyInformation.state.stateName ? R3.propertyInformation.state.stateId : '') + " "+
                    (R3.propertyInformation.zipCode ? R3.propertyInformation.zipCode : ''))}}
                </td>
                <td [hidden]="isAdmin || isUser">{{R3.preClosingInformation.saleAmount | currency}}</td>
                <td [hidden]="isListingAgent || isSellingAgent || isNotary">{{R3.contractDate| date:'MM/dd/YYYY'}}</td>
                <td [hidden]="isAdmin || isUser"> 
                    {{(R3.closingInformation.actualClosingDate ? R3.closingInformation.actualClosingDate : 
                    (R3.closingInformation.closingCancelledDate ? R3.closingInformation.closingCancelledDate : 
                    (R3.closingInformation.scheduleClosingDate ? R3.closingInformation.scheduleClosingDate : null)))   
                    | date:'MM/dd/YYYY'}} 
                </td>
                <td [hidden]="isListingAgent || isSellingAgent || isNotary">{{R3.preClosingInformation.amContractor ? R3.preClosingInformation.amContractor.name : ''}}</td>
                <td>
                    {{(R3.preClosingInformation.lenderBank ? R3.preClosingInformation.lenderBank.bankName: '')}}
                </td>
                <!-- Campos Visibles para el sellingAgent-->
                <td>{{(R3.closingInformation.sellingAgent ? R3.closingInformation.sellingAgent.fullName : '')}}</td>
                <td [hidden]="isAdmin || isUser">{{(R3.closingInformation.sellingAgent ? R3.closingInformation.sellingAgent.phoneNumber : '')}}</td>
                <!-- Campos Visibles para el listingAgent-->
                <td>{{(R3.closingInformation.listingAgent ? R3.closingInformation.listingAgent.fullName : '')}}</td>
                <td [hidden]="isAdmin || isUser">{{(R3.closingInformation.listingAgent ? R3.closingInformation.listingAgent.phoneNumber : '')}}</td>
                <td *ngIf="isAdmin || isUser">
                    <div class="grid p-fluid">
                        <div>
                            <button pButton pRipple icon="pi pi-bars" class="p-button-sm p-button-rounded"
                                (click)="viewButtons()"></button>
                        </div>
                        <div *ngIf="isViewButtons">
                            <button *ngIf="((!R3.postClosingComplete && isUser) || isAdmin)" pButton pRipple icon="pi pi-pencil" class="p-button-rounded mr-2"
                                (click)="editR3(R3)"></button>
                            <button *ngIf="isAdmin" pButton pRipple icon="pi pi-trash" class="p-button-rounded  mr-2"
                                (click)="deleteR3(R3)"></button>
                            <button pButton pRipple icon="pi pi-eye" pTooltip="Detail R3" class="p-button-rounded"
                                (click)="detalleR3(R3)"></button>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                All Registers {{R3s ? allRegisters : 0 }} R3s.
            </div>
        </ng-template>
    </p-table>
</div>

<p-dialog [style]="{width: '550px', height: '550px'}" [(visible)]="dialogSearch" header="Advanced Search" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="grid fluid">
            <div class="field col-12 md:col-12">
                <label for="integeronly">Available Parameters</label>
                <p-dropdown (onChange)="cleanParameters()" [options]="parameters" optionLabel="name" styleClass="form-control"
                    [(ngModel)]="selectedParameter" placeholder="Available Parameters" id="id" name="id" dataKey="id"
                    class="p-inputtext-sm">
                </p-dropdown>
                <small class="p-error" *ngIf="!selectedParameter">Available Parameters is required.</small>
            </div>
            <div class="field col-12 md:col-12"
                *ngIf="selectedParameter.value == '2' || selectedParameter.value == '3'">
                <label for="integeronly">Status</label>
                <p-dropdown [options]="dates" optionLabel="name" styleClass="form-control" [(ngModel)]="selectedDate"
                    placeholder="Status" id="id" name="id" dataKey="id" class="p-inputtext-sm">
                </p-dropdown>
            </div>
            <div class="field col-12 md:col-6" *ngIf="selectedParameter.value == '2' || selectedParameter.value == '3'">
                <label for="integeronly">Date From</label>
                <p-calendar  inputId="calendar" placeholder="mm/dd/yyyy" class=" ng-dirty p-inputtext-sm" dateFormat="mm/dd/yy"
                    [(ngModel)]="searchR3Element.r3ContractDateFrom" [showIcon]="true" [readonlyInput]="true" appendTo="body">
                </p-calendar>
            </div>
            <div class="field col-12 md:col-6" *ngIf="selectedParameter.value == '2' || selectedParameter.value == '3'">
                <label for="integeronly">Date To</label>
                <p-calendar  inputId="calendar" placeholder="mm/dd/yyyy" class=" ng-dirty p-inputtext-sm" dateFormat="mm/dd/yy"
                    [(ngModel)]="searchR3Element.r3ContractDateTo" [showIcon]="true" [readonlyInput]="true" appendTo="body">
                </p-calendar>
            </div>
            <div class="field col-12 md:col-12"
                *ngIf="selectedParameter.value == '1' || selectedParameter.value == '3' ">
                <label for="integeronly">FHA</label>
                <span class="p-input-icon-left">
                    <em class="pi pi-file"></em>
                    <input type="text" pInputText placeholder="Search for R3 File Number" [(ngModel)]="searchR3Element.r3FileNumber" />
                </span>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hideDialogSearch()"></button>
        <button [hidden]="isView" pButton pRipple label="Search" icon="pi pi-search" class="p-button-text"
            (click)="search()"></button>
    </ng-template>
</p-dialog>