import { Component, OnInit } from '@angular/core';
import { ClosingrequestsService } from 'app/services/closingServices/closingrequests.service';
import { MessageService, ConfirmationService, PrimeNGConfig, LazyLoadEvent } from 'primeng/api';
import { ClosingComponent } from '../closing/closing.component';
import { ClosingElement } from 'app/models/closing.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { gql, Apollo } from 'apollo-angular';
import * as FileSaver from 'file-saver';
import { ClosingReportElement } from 'app/models/closingReport.model';
import { SearchElement } from 'app/models/search.model';
import { GenericElement } from 'app/models/generic.model';
import { DatePipe } from '@angular/common';
import { KeycloakService } from 'keycloak-angular';
import { ClosingReportNameElement } from 'app/models/closingReportName.model';
import { CanActivate, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DialogService, MessageService]
})

export class DashboardComponent implements OnInit {

  closingDialog: boolean;
  closings: ClosingElement[];
  closing: ClosingElement;
  selectedClosings: ClosingElement[];
  submitted: boolean;
  loading: boolean;
  allRegisters: number;
  cols: any[];
  isView: boolean = false;
  isEdit: boolean = false;
  isAdmin: boolean = false;
  isSellingAgent: boolean = false;
  isListingAgent: boolean = false;
  isNotary: boolean = false;
  isUser: boolean = false;
  error: string;
  closingId: string;
  closingsExcel: ClosingElement[];
  closingsReport: ClosingReportElement[];
  closingsReportName: ClosingReportNameElement[];
  isViewButtons: boolean = false;
  dialogSearch: boolean = false;
  isClosingAsigned: boolean = true;
  searchElement: SearchElement = {};
  parameters: GenericElement[];
  selectedParameter: GenericElement = {};
  typesOfBrokers: GenericElement[];
  selectedTypeBroker: GenericElement = {};
  dates: GenericElement[];
  selectedDate: GenericElement = {};
  datepipe: DatePipe;
  lastTableLazyLoadEvent: LazyLoadEvent;
  isSearch: boolean = false;
  first: number = 0;


  constructor(
    public messageService?: MessageService,
    public dialogService?: DialogService,
    private closingService?: ClosingrequestsService,
    public primengConfig?: PrimeNGConfig,
    public confirmationService?: ConfirmationService,
    public apollo?: Apollo,
    public keycloakService?: KeycloakService,
    private router?: Router
  ) {
    this.validateNavigateToListing();
  }


  ref: DynamicDialogRef;

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.loading = true;
    this.validarIsAdmin();
    this.loadParameters();
    this.loadTypesBroker();
    this.loadDates();
    this.ValidateCloser();
    this.validarIsSellingAgent();
    this.validarIsListingAgent();
    this.validarIsNotary();
  }

  ValidateCloser() {
    if (this.keycloakService.isUserInRole("closingUser")) {
      this.isUser = true;
    }
    else {
      this.isUser = false;
    }
  }

  validarIsAdmin() {
    if (this.keycloakService.isUserInRole("closingAdmin") || this.keycloakService.isUserInRole("closingSuperUser")) {
      this.isAdmin = true;
    }
    else {
      this.isAdmin = false;
    }
  }

  validarIsSellingAgent() {
    if (this.keycloakService.isUserInRole("closingSellingAgent")) {
      if (this.keycloakService.isUserInRole("closingListingAgent")) {
        this.isSellingAgent = false;
      } else {
        this.isSellingAgent = true;
      }
    }
    else {
      this.isSellingAgent = false;
    }
  }

  validarIsListingAgent() {
    if (this.keycloakService.isUserInRole("closingListingAgent")) {
      this.isListingAgent = true;
    }
    else {
      this.isListingAgent = false;
    }
  }

  validarIsNotary() {
    if (this.keycloakService.isUserInRole("closingNotary")) {
      this.isNotary = true;
    }
    else {
      this.isNotary = false;
    }
  }

  loadDates() {
    this.dates = [
      { name: 'Select Status', value: '' },
      { name: 'Open Files', value: 'Open' },
      { name: 'Closed Files', value: 'Closed' },
      { name: 'Cancelled', value: 'Cancelled' },
      { name: 'All', value: 'all' }
    ]
  }
  loadParameters() {
    this.parameters = [
      { name: 'Select Filter Type', value: '' },
      { name: 'By File', value: '1' },
      { name: 'By Dates', value: '2' },
      { name: 'All', value: '3' }
    ]
  }

  loadTypesBroker() {
    this.typesOfBrokers = [
      { name: 'Select Type of Broker', value: '' },
      { name: 'Listing Agent', value: 'listing' },
      { name: 'Selling Agent', value: 'selling' }
    ]
  }

  detalleClosing(closing) {
    this.isView = true;
    this.ref = this.dialogService.open(ClosingComponent, {
      header: 'View details',
      width: '100vw',
      height: '100vh',
      baseZIndex: 10000,
      data: { devalFileNumber: closing.devalFileNumber, isView: this.isView }
    });
  }

  editClosing(closing) {
    this.isView = false;
    this.isEdit = true;
    this.ref = this.dialogService.open(ClosingComponent, {
      header: 'Edit Closing',
      width: '100vw',
      height: '100vh',
      baseZIndex: 10000,
      closable: false,
      data: { devalFileNumber: closing.devalFileNumber, isView: this.isView, isEdit: this.isEdit }
    });
    this.ref.onClose.subscribe((closing: ClosingComponent) => {
      if (closing) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Closing Edit ' + closing.devalFileNumber, life: 4000 });
        this.recargar();
      }

    });
  }

  deleteClosing(closing: ClosingElement) {
    this.isView = false;
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete  Deval File Number ' + closing.devalFileNumber + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.closingService.deleteClosing(closing).subscribe(_res => {
          this.closings = this.closings.filter(val => val.devalFileNumber !== closing.devalFileNumber);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Closing Deleted', life: 3000 });
          this.recargar();

        },
          err => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.error, life: 3000 });
          });
      }
    });
  }

  public openDialog() {
    //"this.dialogRef.open(CreateClosingComponent);
    this.ref = this.dialogService.open(ClosingComponent, {
      header: 'Add closing',
      width: '100vw',
      height: '100vh',
      baseZIndex: 10000,
      closable: false
    });

    this.ref.onClose.subscribe((closing: ClosingElement) => {
      this.recargar();
    });
  }

  public openDialogSearch() {
    this.dialogSearch = true;
    this.searchElement = {};
    this.selectedDate = {};
  }
  cleanParameters() {
    this.searchElement = {};
    this.selectedDate = {};
  }
  public hideDialogSearch() {
    this.dialogSearch = false;
  }

  clear() {
    this.isSearch = false;
    this.searchElement = {};
    this.selectedDate = {};
    this.first = 0;
    this.lastTableLazyLoadEvent.first = 0;
    this.lastTableLazyLoadEvent.rows = 10;
    this.cargarClosingGraphql(this.lastTableLazyLoadEvent);
  }

  deleteSelectedClosings() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected closings?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        for (let i = 0; i < this.selectedClosings.length; i++) {
          this.closingService.deleteClosing(this.selectedClosings[i]).subscribe();
        }
        this.closings = this.closings.filter(val => !this.selectedClosings.includes(val));

        this.selectedClosings = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Closings Deleted', life: 3000 });
      }
    });
  }

  cargarClosingGraphql(event: LazyLoadEvent) {

    this.lastTableLazyLoadEvent = event;
    let currentPage = event.first / event.rows;
    this.first = event.first;
    console.log('currentPage', currentPage);
    console.log('first', event.first);
    console.log('rows', event.rows);
    this.error = "";
    this.loading = true;
    var sellingAgent = ' ';
    var listingAgent = ' ';
    var notarygAgent = ' ';

    if (this.keycloakService.isUserInRole("closingSellingAgent") && this.keycloakService.isUserInRole("closingListingAgent")) {
      sellingAgent = `sellingAgent:"${this.keycloakService.getUsername()}",`;
      listingAgent = `listingAgent:"${this.keycloakService.getUsername()}"`;
    }
    else if (this.keycloakService.isUserInRole("closingSellingAgent")) {
      sellingAgent = `sellingAgent:"${this.keycloakService.getUsername()}"`;
    }
    else if (this.keycloakService.isUserInRole("closingListingAgent")) {
      listingAgent = `listingAgent:"${this.keycloakService.getUsername()}"`;
    }
    else if (this.keycloakService.isUserInRole("closingNotary")) {
      notarygAgent = `notary:"${this.keycloakService.getUsername()}"`;
    }
    this.apollo
      .query<any>({
        query: gql`
        {
          findAllClosingPaginatorSearch(page: ${currentPage}, size: ${event.rows}, searchData:{
            ${sellingAgent}
            ${listingAgent}
            ${notarygAgent}
          }){
            totalPages
            totalElements
            last
            numberOfElements
            empty
            first
            content{
              fha
              devalFileNumber
              postClosingComplete
              status
              contractDate
              propertyInformation{
                propertyAddress
                city
                state{
                  stateName
                  stateId
                }
                zipCode
              }
              preClosingInformation{
                saleAmount
                amContractor{name}
                lenderBank{
                  bankName
                }
              }
              financials{
                sellingAgent{
                  userData{
                    fullName
                    phoneNumber
                  }
                  gross
                  net
                }
                listingAgent{
                  userData{
                    fullName
                    phoneNumber
                  }
                  gross
                  net
                }
              }
              closingInformation{
                scheduleClosingDate
                actualClosingDate
                closingCancelledDate
              }
              buyerInformation{
                name
                middleName
                lastName
              }
            }
          }
        }
        `
      })
      .subscribe(
        ({ data, loading }) => {
          if (data.findAllClosingPaginatorSearch.empty) {
            this.messageService.add({ severity: 'info', summary: 'You have no assigned closing' });
            this.closing = {};
            this.loading = loading;
            this.isClosingAsigned = false;
          } else {
            this.recargar();
            this.loading = loading;
            this.closings = [];
            this.closings = data.findAllClosingPaginatorSearch.content;
            console.log(this.closings.length)
            this.closing = {};
            this.closing.financials = {};
            for (let i of this.closings) {
              this.closing.fha = i.fha;
              this.closing.devalFileNumber = i.devalFileNumber;
              this.closing.buyerInformation = i.buyerInformation;
              this.closing.propertyInformation = i.propertyInformation;
              this.closing.preClosingInformation = i.preClosingInformation;
              this.closing.status = i.status;
              this.closing.contractDate = i.contractDate;
              this.closing.financials = i.financials;
              this.closing.closingInformation = i.closingInformation;
              this.closing.postClosingComplete = i.postClosingComplete;
            }
            this.allRegisters = data.findAllClosingPaginatorSearch.totalElements;

          }
        }
      );

  }

  recargar() {
    this.error = "";
    this.loading = true;
    var sellingAgent = ' ';
    var listingAgent = ' ';
    var notarygAgent = ' ';
    var notary = ' ';
    let currentPage = this.lastTableLazyLoadEvent.first / this.lastTableLazyLoadEvent.rows;
    this.first = this.lastTableLazyLoadEvent.first;
    if (this.keycloakService.isUserInRole("closingSellingAgent") && this.keycloakService.isUserInRole("closingListingAgent")) {
      sellingAgent = `sellingAgent:"${this.keycloakService.getUsername()}",`;
      listingAgent = `listingAgent:"${this.keycloakService.getUsername()}"`;

    }
    else if (this.keycloakService.isUserInRole("closingSellingAgent")) {
      sellingAgent = `sellingAgent:"${this.keycloakService.getUsername()}"`;
    }
    else if (this.keycloakService.isUserInRole("closingListingAgent")) {
      listingAgent = `listingAgent:"${this.keycloakService.getUsername()}"`;
    }
    else if (this.keycloakService.isUserInRole("closingNotary")) {
      notarygAgent = `notary:"${this.keycloakService.getUsername()}"`;
    }
    this.apollo
      .query<any>({
        query: gql`
        {
          findAllClosingPaginatorSearch(page: ${currentPage}, size: ${this.lastTableLazyLoadEvent.rows}, searchData:{
            ${sellingAgent}
            ${listingAgent}
            ${notarygAgent}
          }){
            totalPages
            totalElements
            last
            numberOfElements
            empty
            first
            content{
              fha
              devalFileNumber
              postClosingComplete
              status
              contractDate
              propertyInformation{
                propertyAddress
                city
                state{
                  stateName
                  stateId
                }
                zipCode
              }
              preClosingInformation{
                saleAmount
                amContractor{name}
                lenderBank{
                  bankName
                }
              }
              financials{
                sellingAgent{
                  userData{
                    fullName
                    phoneNumber
                  }
                  gross
                  net
                }
                listingAgent{
                  userData{
                    fullName
                    phoneNumber
                  }
                  gross
                  net
                }
              }
              closingInformation{
                scheduleClosingDate
                actualClosingDate
                closingCancelledDate
              }
              buyerInformation{
                name
                middleName
                lastName
              }
            }
          }
        }
        `
      })
      .subscribe(
        ({ data, loading }) => {
          if (data.findAllClosingPaginatorSearch.empty) {
            this.messageService.add({ severity: 'info', summary: 'You have no assigned closing' });
            this.closing = {};
            this.loading = loading;
            this.isClosingAsigned = false;
          } else {
            this.loading = loading;
            this.closings = data.findAllClosingPaginatorSearch.content;
            console.log(this.closings.length)
            this.closing = {};
            this.closing.financials = {};
            for (let i of this.closings) {
              this.closing.fha = i.fha;
              this.closing.devalFileNumber = i.devalFileNumber;
              this.closing.buyerInformation = i.buyerInformation;
              this.closing.propertyInformation = i.propertyInformation;
              this.closing.preClosingInformation = i.preClosingInformation;
              this.closing.status = i.status;
              this.closing.contractDate = i.contractDate;
              this.closing.financials = i.financials;
              this.closing.closingInformation = i.closingInformation;
              this.closing.postClosingComplete = i.postClosingComplete;
            }
            this.allRegisters = data.findAllClosingPaginatorSearch.totalElements;

          }
        }
      );

  }
  search(event?: LazyLoadEvent) {
    this.isSearch = true;
    var inputFha = (this.searchElement.fha ? `fha: "${this.searchElement.fha}",` : '');
    var inputDevalFileNumber = (this.searchElement.devalFileNumber ? `devalFileNumber: "${this.searchElement.devalFileNumber}",` : '');
    var inputStatus = (this.selectedDate.value ? `status: "${this.selectedDate.value}",` : ' ');
    var sellingAgent = ' ';
    var listingAgent = ' ';
    var notarygAgent = ' ';
    let currentPage;
    let size;
    if (event) {
      currentPage = event.first / event.rows;
      this.first = event.first;
      size = event.rows;
    } else {
      this.first = 0;
      size = 10;
      currentPage = this.first / size;
    }
    if (this.searchElement.contractDateFrom) {
      var dateFrom = new Date(this.searchElement.contractDateFrom.getTime() - this.searchElement.contractDateFrom.getTimezoneOffset() * 60000).toISOString();
    }
    if (this.searchElement.contractDateTo) {
      var dateTo = new Date(this.searchElement.contractDateTo.getTime() - this.searchElement.contractDateTo.getTimezoneOffset() * 60000).toISOString();
    }
    if ((this.keycloakService.isUserInRole("closingSellingAgent") && this.keycloakService.isUserInRole("closingListingAgent"))) {
      sellingAgent = `sellingAgent:"${this.keycloakService.getUsername()}",`;
      listingAgent = `listingAgent:"${this.keycloakService.getUsername()}"`;
    } else if (this.keycloakService.isUserInRole("closingSellingAgent")) {
      sellingAgent = `sellingAgent:"${this.keycloakService.getUsername()}"`;
    }
    else if (this.keycloakService.isUserInRole("closingListingAgent")) {
      listingAgent = `listingAgent:"${this.keycloakService.getUsername()}"`;
    }
    else if (this.keycloakService.isUserInRole("closingNotary")) {
      notarygAgent = `notary:"${this.keycloakService.getUsername()}"`;
    }



    var inputContractDateFrom = (this.searchElement.contractDateFrom ? `contractDateFrom:"${dateFrom}",` : ' ');
    var inputContractDateTo = (this.searchElement.contractDateTo ? `contractDateTo:"${dateTo}",` : ' ');

    if (inputFha || inputStatus || inputContractDateFrom || inputContractDateTo || listingAgent || sellingAgent || notarygAgent) {
      this.dialogSearch = false;
      this.apollo
        .query<any>({
          query: gql`
        {
          findAllClosingPaginatorSearch(page: ${currentPage}, size: ${size}, searchData:{
            ${inputFha}
            ${inputDevalFileNumber}
            ${inputStatus}
            ${inputContractDateFrom}
            ${inputContractDateTo}
            ${sellingAgent}
            ${listingAgent}
            ${notarygAgent}
          }){
            totalPages
            totalElements
            last
            numberOfElements
            empty
            first
            content{
                fha
                devalFileNumber
                postClosingComplete
                status
                contractDate
                propertyInformation{
                  propertyAddress
                  city
                  state{
                    stateName
                    stateId
                  }
                  zipCode
                }
                preClosingInformation{
                  saleAmount
                  amContractor{name}
                  lenderBank{
                    bankName
                  }
                }
                financials{
                  sellingAgent{
                    userData{
                      fullName
                      phoneNumber
                    }
                    gross
                    net
                  }
                  listingAgent{
                    userData{
                      fullName
                      phoneNumber
                    }
                    gross
                    net
                  }
                }
                closingInformation{
                  scheduleClosingDate
                  actualClosingDate
                  closingCancelledDate
                }
                buyerInformation{
                  name
                  middleName
                  lastName
                }
              }
            }
          }
        `
        })
        .subscribe(
          ({ data }) => {
            if (data.findAllClosingPaginatorSearch.empty) {
              this.messageService.add({ severity: 'info', summary: 'There are no results' });
              this.closings = [];
              this.allRegisters = 0;
            } else {
              this.closings = data.findAllClosingPaginatorSearch.content;
              this.allRegisters = data.findAllClosingPaginatorSearch.totalElements;

            }

          }
        );
    }
    else {
      this.dialogSearch = true;
      this.messageService.add({ severity: 'info', summary: 'Please add a filter to search' });
    }

  }

  exportExcel() {
    if (this.keycloakService.isUserInRole("closingSellingAgent") || this.keycloakService.isUserInRole("closingListingAgent") || this.keycloakService.isUserInRole("closingNotary")) {
      this.closingsExcel = this.closings;
      this.formatReportAgents();
    } else {
      this.closingService.getClosings().subscribe(res => {
        this.closingsExcel = [];
        if (res) {
          this.closingsExcel = res;
          this.formatReport();
        }
      })
    }
  }

  formatReportAgents() {
    this.closingsReport = [];
    let i = 0;
    this.closingsExcel.forEach(element => {
      this.closingsReport[i] = {};
      this.closingsReport[i].Deval_File_Number = (element.devalFileNumber ? element.devalFileNumber : null);
      this.closingsReport[i].FHA = (element.fha ? element.fha : null);
      this.closingsReport[i].Status = (element.status ? element.status : null);
      if (element.buyerInformation) {
        /**Setea la primer posicion del buyerInformation en el campo borrowerName y el resto lo pone en el campo coBorrowerName*/
        this.closingsReport[i].Borrower_Name = ((element.buyerInformation[0].name ? element.buyerInformation[0].name : '') + " " +
          (element.buyerInformation[0].middleName ? element.buyerInformation[0].middleName : '') + " " +
          (element.buyerInformation[0].lastName ? element.buyerInformation[0].lastName : '') + "\n")
        for (let j = 1; j < element.buyerInformation.length; j++) {
          this.closingsReport[i].Co_Borrower_Name = ((element.buyerInformation[j].name ? element.buyerInformation[j].name : '') + " " +
            (element.buyerInformation[j].middleName ? element.buyerInformation[j].middleName : '') + " " +
            (element.buyerInformation[j].lastName ? element.buyerInformation[j].lastName : '') + "\n")
        }
      } else {
        this.closingsReport[i].Borrower_Name = '';
        this.closingsReport[i].Co_Borrower_Name = '';
      }
      if (element.propertyInformation) {
        this.closingsReport[i].Property_Address = ((element.propertyInformation.propertyAddress ? element.propertyInformation.propertyAddress : '') + " " +
          (element.propertyInformation.city ? element.propertyInformation.city : '') + ", " +
          (element.propertyInformation.state.stateId ? element.propertyInformation.state.stateId : ' ') + " " +
          (element.propertyInformation.zipCode ? element.propertyInformation.zipCode : '') + "\n");
      } else {
        this.closingsReport[i].Property_Address = null;
      }
      this.closingsReport[i].Sale_Amount = (element.preClosingInformation.saleAmount ? element.preClosingInformation.saleAmount : null);
      this.datepipe = new DatePipe('en-US');
      if (element.closingInformation.actualClosingDate) {
        /**Define el valor segun la fecha escogida de lo contratio es null*/
        this.closingsReport[i].Closing_Date = this.datepipe.transform(element.closingInformation.actualClosingDate, 'MM/dd/yyyy');
      } else if (element.closingInformation.closingCancelledDate) {
        this.closingsReport[i].Closing_Date = this.datepipe.transform(element.closingInformation.closingCancelledDate, 'MM/dd/yyyy');
      } else if (element.closingInformation.scheduleClosingDate && element.closingInformation.actualClosingDate) {
        this.closingsReport[i].Closing_Date = this.datepipe.transform(element.closingInformation.actualClosingDate, 'MM/dd/yyyy');
      } else if (element.closingInformation.scheduleClosingDate && element.closingInformation.closingCancelledDate) {
        this.closingsReport[i].Closing_Date = this.datepipe.transform(element.closingInformation.closingCancelledDate, 'MM/dd/yyyy');
      } else if (element.closingInformation.scheduleClosingDate) {
        this.closingsReport[i].Closing_Date = this.datepipe.transform(element.closingInformation.scheduleClosingDate, 'MM/dd/yyyy');
      }
      else {
        this.closingsReport[i].Closing_Date = null;
      }
      console.log("esta es la fecha");
      console.log(this.closingsReport[i].Closing_Date);

      this.closingsReport[i].Lender_Bank_Name = (element.preClosingInformation.lenderBank ? element.preClosingInformation.lenderBank.bankName : null);
      if (this.keycloakService.isUserInRole("closingSellingAgent")) {
        this.closingsReport[i].SellingAgent_FullName = (element.financials.sellingAgent.userData.fullName ? element.financials.sellingAgent.userData.fullName : null);
        this.closingsReport[i].SellingAgent_PhoneNumber = (element.financials.sellingAgent.userData.phoneNumber ? element.financials.sellingAgent.userData.phoneNumber : null);
        this.closingsReport[i].SellingAgent_Gross = (element.financials.sellingAgent.gross ? element.financials.sellingAgent.gross : null);
        this.closingsReport[i].SellingAgent_Net = (element.financials.sellingAgent.net ? element.financials.sellingAgent.net : null);
        this.closingsReport[i].ListingAgent_FullName = (element.financials.listingAgent.userData.fullName ? element.financials.listingAgent.userData.fullName : null);
        this.closingsReport[i].ListingAgent_PhoneNumber = (element.financials.listingAgent.userData.phoneNumber ? element.financials.listingAgent.userData.phoneNumber : null);

      } else if (this.keycloakService.isUserInRole("closingListingAgent")) {
        this.closingsReport[i].SellingAgent_FullName = (element.financials.sellingAgent.userData.fullName ? element.financials.sellingAgent.userData.fullName : null);
        this.closingsReport[i].SellingAgent_PhoneNumber = (element.financials.sellingAgent.userData.phoneNumber ? element.financials.sellingAgent.userData.phoneNumber : null);
        this.closingsReport[i].ListingAgent_FullName = (element.financials.listingAgent.userData.fullName ? element.financials.listingAgent.userData.fullName : null);
        this.closingsReport[i].ListingAgent_PhoneNumber = (element.financials.listingAgent.userData.phoneNumber ? element.financials.listingAgent.userData.phoneNumber : null);
        this.closingsReport[i].ListingAgent_Gross = (element.financials.listingAgent.gross ? element.financials.listingAgent.gross : null);
        this.closingsReport[i].ListingAgent_Net = (element.financials.listingAgent.net ? element.financials.listingAgent.net : null);

      } else if (this.keycloakService.isUserInRole("closingNotary")) {
        this.closingsReport[i].SellingAgent_FullName = (element.financials.sellingAgent.userData.fullName ? element.financials.sellingAgent.userData.fullName : null);
        this.closingsReport[i].SellingAgent_PhoneNumber = (element.financials.sellingAgent.userData.phoneNumber ? element.financials.sellingAgent.userData.phoneNumber : null);
        this.closingsReport[i].ListingAgent_FullName = (element.financials.listingAgent.userData.fullName ? element.financials.listingAgent.userData.fullName : null);
        this.closingsReport[i].ListingAgent_PhoneNumber = (element.financials.listingAgent.userData.phoneNumber ? element.financials.listingAgent.userData.phoneNumber : null);

      }

      i = i + 1
    }
    );
    this.createExcel(this.closingsReport);
  }

  formatReport() {
    this.closingsReportName = [];
    let i = 0;
    this.closingsExcel.forEach(element => {
      if (element) {
        this.closingsReportName[i] = {};
        this.closingsReportName[i].FHA = (element.fha ? element.fha : "");
        this.closingsReportName[i].Deval_File_Number = (element.devalFileNumber ? element.devalFileNumber : "");
        this.datepipe = new DatePipe('en-US');
        this.closingsReportName[i].Contract_Date = (element.contractDate ? this.datepipe.transform(element.contractDate, 'MM/dd/yyyy') : "");
        this.closingsReportName[i].Closing_Processor = (element.closingProcessor ? element.closingProcessor.fullName : "");
        this.closingsReportName[i].Closing_Received_Package_From_Lender = (element.closingInformation.receivedPachageFromLender ? this.dateToString(element.closingInformation.receivedPachageFromLender) : "");
        if (element.postClosingComplete) {
          var complete = (element.postClosingComplete ? "Yes" : "No");
          this.closingsReportName[i].Post_Closing_Complete = (complete ? complete : "");
        }
        if (element.propertyInformation) {
          this.closingsReportName[i].Property_Address = (element.propertyInformation.propertyAddress ? element.propertyInformation.propertyAddress : "");
          this.closingsReportName[i].Property_City = (element.propertyInformation.city ? element.propertyInformation.city : "");
          this.closingsReportName[i].Property_State = (element.propertyInformation.state ? element.propertyInformation.state.stateId : "");
          this.closingsReportName[i].Property_Zipcode = (element.propertyInformation.zipCode ? element.propertyInformation.zipCode : "");
        }
        if (element.buyerInformation) {
          var typeOfBuyer = "";
          var phoneNumber = "";
          var name = "";
          var address = "";
          var city = "";
          var stateName = "";
          var zipcode = "";
          element.buyerInformation.forEach(elementBuyer => {
            if (typeOfBuyer != "" && elementBuyer.typeOfBuyer) {
              typeOfBuyer = typeOfBuyer + ", " + elementBuyer.typeOfBuyer;
            } else if (elementBuyer.typeOfBuyer) {
              typeOfBuyer = elementBuyer.typeOfBuyer;
            }
            if (phoneNumber != "" && elementBuyer.phoneNumber) {
              phoneNumber = phoneNumber + ", " + elementBuyer.phoneNumber;
            } else if (elementBuyer.phoneNumber) {
              phoneNumber = elementBuyer.phoneNumber;
            }
            if (name != "") {
              name = name + ", " + elementBuyer.name + (elementBuyer.middleName ? " " + elementBuyer.middleName : "") + " " + (elementBuyer.lastName ? elementBuyer.lastName : "");
            } else {
              name = elementBuyer.name + (elementBuyer.middleName ? " " + elementBuyer.middleName : "") + " " + (elementBuyer.lastName ? elementBuyer.lastName : "");
            }
            if (address != "" && elementBuyer.address) {
              address = address + ", " + elementBuyer.address;
            } else if (elementBuyer.address) {
              address = elementBuyer.address;
            }
            if (city != "" && elementBuyer.city) {
              city = city + ", " + elementBuyer.city;
            } else if (elementBuyer.city) {
              city = elementBuyer.city;
            }
            if (stateName != "" && elementBuyer.state) {
              if (elementBuyer.state.stateId) {
                stateName = stateName + ", " + elementBuyer.state.stateId;
              }
            } else if (elementBuyer.state) {
              if (elementBuyer.state.stateId) {
                stateName = elementBuyer.state.stateId;
              }
            }
            if (zipcode != "" && elementBuyer.zipcode) {
              zipcode = zipcode + ", " + elementBuyer.zipcode;
            } else if (elementBuyer.zipcode) {
              zipcode = elementBuyer.zipcode;
            }
          });

          this.closingsReportName[i].Buyer_Name = (name ? name : "");
          this.closingsReportName[i].Buyer_Type_of_Buyer = (typeOfBuyer ? typeOfBuyer : "");
          this.closingsReportName[i].Buyer_Phone = (phoneNumber ? phoneNumber : "");
          this.closingsReportName[i].Buye_Address = (address ? address : "");
          this.closingsReportName[i].Buyer_City = (city ? city : "");
          this.closingsReportName[i].Buyer_State = (stateName ? stateName : "");
          this.closingsReportName[i].Buyer_Zipcode = (zipcode ? zipcode : "");
        }

        if (element.preClosingInformation) {
          this.closingsReportName[i].Pre_Sale_Amount = (element.preClosingInformation.saleAmount ? element.preClosingInformation.saleAmount + "" : "");
          this.closingsReportName[i].Pre_Sale_Method_of_Sale = (element.preClosingInformation.methodOfSale ? element.preClosingInformation.methodOfSale : "");
          this.closingsReportName[i].Pre_Lender = (element.preClosingInformation.lenderBank ? element.preClosingInformation.lenderBank.bankName : "");
          this.closingsReportName[i].Pre_Date_Earnest_Money_Received = (element.preClosingInformation.dateEarnestMoneyReceived ? this.dateToString(element.preClosingInformation.dateEarnestMoneyReceived) : "");
          this.closingsReportName[i].Pre_Earnest_Money_Amount = (element.preClosingInformation.ernestMoneyAmount ? element.preClosingInformation.ernestMoneyAmount + "" : "");
          this.closingsReportName[i].Pre_AM_Contractor = (element.preClosingInformation.amContractor ? element.preClosingInformation.amContractor.name + " " + (element.preClosingInformation.amContractor.middleName ? (element.preClosingInformation.amContractor.middleName + " ") : "") + (element.preClosingInformation.amContractor.lastName ? element.preClosingInformation.amContractor.lastName : "") : "");
        }
        if (element.closingInformation) {
          this.closingsReportName[i].Closing_Scheduled_Closing_Date = (element.closingInformation.scheduleClosingDate ? this.dateToString(element.closingInformation.scheduleClosingDate) : "");
          this.closingsReportName[i].Closing_Actual_Closing_Date = (element.closingInformation.actualClosingDate ? this.dateToString(element.closingInformation.actualClosingDate) : "");
          this.closingsReportName[i].Closing_Closing_Cancelled_Date = (element.closingInformation.closingCancelledDate ? this.dateToString(element.closingInformation.closingCancelledDate) : "");
          this.closingsReportName[i].Closing_Earnest_Money_Dispostion = (element.closingInformation.earnestMoneyDispostion ? element.closingInformation.earnestMoneyDispostion : "");
          this.closingsReportName[i].Closing_Cancellation_Reason = (element.closingInformation.cancellationReason ? element.closingInformation.cancellationReason : "");

          if (element.closingInformation.closingInformationExtentionInformationDTO) {
            var extension = "";

            element.closingInformation.closingInformationExtentionInformationDTO.forEach(elementExtension => {
              var date = new Date(elementExtension.extentionClosingDate);
              if (extension != "") {
                extension = extension + ", " + "( " + ((date.getMonth() + "/" + date.getDay() + "/" + date.getFullYear()) + " - " + elementExtension.comment + " - " + (elementExtension.waive ? "Waived" : "No Waived") + ")");
              } else {
                extension = "(" + ((date.getMonth() + "/" + date.getDay() + "/" + date.getFullYear()) + " - " + elementExtension.comment + " - " + (elementExtension.waive ? "Waived" : "No Waived")) + ")";
              }

            });
            this.closingsReportName[i].Closing_Extension = (extension ? extension : "");
          }
        }

        if (element.postClosingInformation) {
          this.closingsReportName[i].Post_Notary_Name = (element.postClosingInformation.notaryId ? ((element.postClosingInformation.notaryId.name ? element.postClosingInformation.notaryId.name : "") + " " + (element.postClosingInformation.notaryId.middleName ? element.postClosingInformation.notaryId.middleName + " " : "") + (element.postClosingInformation.notaryId.lastName ? element.postClosingInformation.notaryId.lastName : "")) : "");
          this.closingsReportName[i].Post_Deed_Number = (element.postClosingInformation.deedNumber ? element.postClosingInformation.deedNumber + "" : "");
          this.closingsReportName[i].Post_Deed_Date = (element.postClosingInformation.deedDate ? this.dateToString(element.postClosingInformation.deedDate) : "");
          this.closingsReportName[i].Post_Upload_Package_Date = (element.postClosingInformation.uploadPackageDate ? this.dateToString(element.postClosingInformation.uploadPackageDate) : "");
        }

        if (element.financials) {
          if (element.financials.closingAgent) {
            this.closingsReportName[i].Closing_Agent_Fee = (element.financials.closingAgent.fee ? element.financials.closingAgent.fee + "" : "");
            this.closingsReportName[i].Closing_Agent_Tax_Amount = (element.financials.closingAgent.taxAmount ? element.financials.closingAgent.taxAmount + "" : "");
            this.closingsReportName[i].Closing_Agent_Total_Closing_Fee = (element.financials.closingAgent.totalClosingFee ? element.financials.closingAgent.totalClosingFee + "" : "");
          }
          if (element.financials.listingAgent) {
            if (element.financials.listingAgent.userData) {
              this.closingsReportName[i].Listing_Agent = (element.financials.listingAgent.userData.fullName ? element.financials.listingAgent.userData.fullName : "");
            }
            this.closingsReportName[i].Listing_Agent_Fee = (element.financials.listingAgent.fee ? element.financials.listingAgent.fee + "" : "");
            this.closingsReportName[i].Listing_Agent_Gross = (element.financials.listingAgent.gross ? element.financials.listingAgent.gross + "" : "");
            this.closingsReportName[i].Listing_Agent_Taxable = (element.financials.listingAgent.taxable ? element.financials.listingAgent.taxable + "" : "");
            this.closingsReportName[i].Listing_Agent_Net = (element.financials.listingAgent.net ? element.financials.listingAgent.net + "" : "");
          }
          if (element.financials.sellingAgent) {
            if (element.financials.sellingAgent.userData) {
              this.closingsReportName[i].Selling_Agent = (element.financials.sellingAgent.userData.fullName ? element.financials.sellingAgent.userData.fullName : "");
            }
            this.closingsReportName[i].Selling_Agent_Fee = (element.financials.sellingAgent.fee ? element.financials.sellingAgent.fee + "" : "");
            this.closingsReportName[i].Selling_Agent_Gross = (element.financials.sellingAgent.gross ? element.financials.sellingAgent.gross + "" : "");
            this.closingsReportName[i].Selling_Agent_Taxable = (element.financials.sellingAgent.taxable ? element.financials.sellingAgent.taxable + "" : "");
            this.closingsReportName[i].Selling_Agent_Net = (element.financials.sellingAgent.net ? element.financials.sellingAgent.net + "" : "");
          }
        }
        i = i + 1
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not found registers', life: 3000 });
      }
    }
    );
    this.createExcel(this.closingsReportName);

  }

  dateToString(date: Date) {
    if (date) {
      this.datepipe = new DatePipe('en-US');
      var dateNew = this.datepipe.transform(date, 'MM/dd/YYYY');
      return "" + dateNew;
    } else {
      return "";
    }

  }

  createExcel(objeto: any) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(objeto);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "closings");
    });

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  viewButtons() {
    if (this.isViewButtons) {
      this.isViewButtons = false;
    } else {
      this.isViewButtons = true;
    }
  }

  loadClosings() {
    this.closingService.getClosings().subscribe(res => {
      this.closing = {};
      if (res) {
        this.closings = res;
        this.closing = {};
        for (let i of this.closings) {
          this.closing.fha = i.fha;
          this.closing.devalFileNumber = i.devalFileNumber;
          this.closing.buyerInformation = i.buyerInformation;
          this.closing.propertyInformation = i.propertyInformation;
          this.closing.preClosingInformation = i.preClosingInformation;
          this.closing.financials = i.financials;
        }
        this.allRegisters = this.closings.length;
      }
    })
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (this.isSearch) {
      this.search(event);
    } else {
      this.cargarClosingGraphql(event);
    }
  }

  validateNavigateToListing() {
    if (this.keycloakService.isUserInRole("closingSellingAgent") && this.keycloakService.isUserInRole("closingListingAgent")) {
      console.log(this.router.url)
      if (this.router.url != '/listingAgent' && this.router.url != '/sellingAgent') {
        this.router.navigateByUrl('/listingAgent');
      }
    }

  }
}
