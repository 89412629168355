<p-toast></p-toast>
<div class="card">
    <p-table #dt [value]="notaryInvoices" [paginator]="true" [rows]="10" [totalRecords]="allRegisters"
        [(first)]="first" [loading]="loading" [(selection)]="selectednotaryInvoices" [lazy]="true"
        (onLazyLoad)="onLazyLoad($event)" [paginator]="true" [rowsPerPageOptions]="[10,25,50]"
        [globalFilterFields]="['notaryId','name', 'middleName', 'lastName', 'address','zipcode','city','state']">

        <ng-template pTemplate="caption">
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <button type="button" *ngIf="isNotary" (click)="createNotaryInvoice()" pButton icon="pi pi-plus"
                        label="New" class="mr-2"></button>
                    <button pButton *ngIf="isNotary" pRipple icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedNotaryInvoice()"
                        [disabled]="!selectednotaryInvoices || !selectednotaryInvoices.length"></button>
                </ng-template>

                <ng-template pTemplate="right">
                    <button type="button" (click)="openDialogSearch()" pButton icon="pi pi-search"
                        label="Advanced Search" class="mr-2"></button>
                    <button pButton label="Clear" class="p-button-outlined mr-2" icon="pi pi-filter-slash"
                        (click)="clearSearch()"></button>
                    <div *ngIf="isAdmin" class="flex align-items-center justify-content-center">
                        <button type="button" label="Aceptados"pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                            class="p-button-success mr-2" pTooltip="Export to XLS Aceptados" tooltipPosition="bottom"></button>
                    </div>
                    <div *ngIf="isAdmin" class="flex align-items-center justify-content-center">
                        <button type="button" label="Todos" pButton pRipple icon="pi pi-file-excel" (click)="exportExcelAll()"
                            class="p-button-success mr-2" pTooltip="Export to XLS ALL" tooltipPosition="bottom"></button>
                    </div>
                </ng-template>

            </p-toolbar>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem" id=" ">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th id=" " pSortableColumn="notaryId">R3 <p-sortIcon field="notaryId"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="name">Contrato <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="middleName">Estado <p-sortIcon field="middleName"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="lastName">Fecha <p-sortIcon field="lastName"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="city">Cuantia <p-sortIcon field="city"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="state">Total de factura <p-sortIcon field="state"></p-sortIcon>
                </th>
                <th id=" "> </th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-notaryInvoice>
            <tr>
                <td>
                    <p-tableCheckbox [value]="notaryInvoice"></p-tableCheckbox>
                </td>
                <td>{{notaryInvoice.r3.r3FileNumber}}</td>
                <td>{{notaryInvoice.contrato}}</td>

                <td>
                    <p-tag styleClass="mr-2" [severity]="getStatus(notaryInvoice.status)"
                        [value]="(notaryInvoice.status).toUpperCase()"></p-tag>
                </td>
                <td>{{dateToString(notaryInvoice.fecha)}}</td>
                <td>{{(notaryInvoice.r3.preClosingInformation.voucherAmount != null ? (notaryInvoice.r3.preClosingInformation.voucherAmount).toLocaleString('en-US', {style:
                    'currency',currency: 'USD', minimumFractionDigits: 2}): "$0.00")}}</td>
                <td>{{(notaryInvoice.resumenGastosDTO.total != null ? (notaryInvoice.resumenGastosDTO.total).toLocaleString('en-US',
                    {style: 'currency',currency: 'USD', minimumFractionDigits: 2}) : "$0.00")}}</td>
                <td>
                    <button
                        *ngIf="isNotary && (estadoCreado(notaryInvoice.status) || estadoRechazado(notaryInvoice.status))"
                        pButton pRipple icon="pi pi-pencil" class="p-button-rounded mr-2"
                        (click)="editNotaryInvoice(notaryInvoice)"></button>
                    <button
                        *ngIf="isNotary && (estadoCreado(notaryInvoice.status) || estadoRechazado(notaryInvoice.status))"
                        pButton pRipple icon="pi pi-trash" class="p-button-rounded  mr-2"
                        (click)="deleteNotaryInvoice(notaryInvoice)"></button>
                    <button pButton pRipple icon="pi pi-eye" pTooltip="Detail notary" class="p-button-rounded mr-2"
                        (click)="detalleNotaryInvoice(notaryInvoice)"></button>
                    <button *ngIf="estadoEnviado(notaryInvoice.status) && isAdmin" pButton pRipple icon="pi pi-times"
                        pTooltip="Rechazar Invoice" class="p-button-rounded  mr-2"
                        (click)="openDialog(notaryInvoice)"></button>
                    <button *ngIf="estadoEnviado(notaryInvoice.status) && isAdmin" pButton pRipple icon="pi pi-check"
                        pTooltip="Aceptar Invoice" class="p-button-rounded  mr-2"
                        (click)="aceptarInvoice(notaryInvoice)"></button>
                    <button *ngIf="estadoCreado(notaryInvoice.status) && isNotary" pButton pRipple icon="pi pi-send"
                        pTooltip="Enviar Invoice" class="p-button-rounded  mr-2"
                        (click)="SendNotaryInvoice(notaryInvoice)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                All Registers {{notaryInvoices ? allRegisters : 0 }} notaryInvoices.
            </div>
        </ng-template>
    </p-table>


    <p-dialog [(visible)]="dialog" [focusTrap]="false" [style]="{width: '600px', height: '300px'}"
        header="Rechazar Invoice" [modal]="true" styleClass="p-fluid">
        <ng-template pTemplate="content">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-12">
                    <div class="field col-12 md:col-12">
                        <label for="comment">Comentario Rechazo</label>
                        <textarea [(ngModel)]="comment" pInputTextarea></textarea>
                        <small class="p-error" class="p-inputtext-sm" *ngIf="!comment">Comentario Rechazo is
                            required.</small>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialog()"></button>
            <button [hidden]="isView" pButton pRipple label="Rechazar" icon="pi pi-check" class="p-button-text"
                (click)="rechazar()"></button>
        </ng-template>
    </p-dialog>

    <p-dialog [style]="{width: '600px', height: '600px'}" [(visible)]="dialogSearch" header="Advanced Search"
        [focusTrap]="false" styleClass="p-fluid">
        <ng-template pTemplate="content">
            <div class="p-fluid grid formgrid">
                <div class="field col-12 md:col-12">
                    <label for="integeronly">Available Parameters</label>
                    <p-dropdown (onChange)="cleanParameters()" [options]="parameters" optionLabel="name" styleClass="form-control"
                        [(ngModel)]="selectedParameter" placeholder="Available Parameters" id="id" name="id"
                        dataKey="id" class="p-inputtext-sm">
                    </p-dropdown>
                    <small class="p-error" *ngIf="!selectedParameter">Available Parameters is required.</small>
                </div>
                <div class="field col-12 md:col-12"
                    *ngIf="selectedParameter.value == '5' || selectedParameter.value == '4'">
                    <label for="integeronly">Status</label>
                    <p-dropdown [options]="statuses" optionLabel="name" styleClass="form-control"
                        [(ngModel)]="selectedStatus" placeholder="Status" id="id" name="id" dataKey="id"
                        class="p-inputtext-sm">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-12"
                    *ngIf="selectedParameter.value == '2' || selectedParameter.value == '4'">
                    <label for="integeronly">Fecha</label>
                    <p-calendar  inputId="calendar" placeholder="mm/dd/yyyy"
                        class=" ng-dirty p-inputtext-sm" dateFormat="mm/dd/yy" [(ngModel)]="searchElement.fecha"
                        [showIcon]='true' [readonlyInput]="true" appendTo="body">
                    </p-calendar>
                </div>
                <div class="field col-12 md:col-12"
                    *ngIf="selectedParameter.value == '1' || selectedParameter.value == '4' ">
                    <label for="integeronly">R3 File Number</label>
                    <span class="p-input-icon-left">
                        <em class="pi pi-file"></em>
                        <input type="text" pInputText placeholder="Search for R3 File Number"
                            [(ngModel)]="searchElement.r3FileNumber" />
                    </span>
                </div>
                <div class="field col-12 md:col-12"
                    *ngIf="selectedParameter.value == '3' || selectedParameter.value == '4' ">
                    <label for="integeronly">Contrato</label>
                    <span class="p-input-icon-left">
                        <em class="pi pi-file"></em>
                        <input type="text" pInputText placeholder="Search for contract"
                            [(ngModel)]="searchElement.contrato" />
                    </span>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialogSearch()"></button>
            <button [hidden]="isView" pButton pRipple label="Search" icon="pi pi-search" class="p-button-text"
                (click)="searchFilter()"></button>
        </ng-template>
    </p-dialog>
</div>