<div class="card">
    <form [formGroup]="formState" (ngSubmit)="onSubmit()">

        <div class="p-fluid grid">

            <div class="field col-6 md:col-6">
                <label for="stateId">State Id</label>
                <input [readonly]="state" type="text" name="stateId" id="stateId" pInputText formControlName="stateId">
            </div>

            <div class="field col-6 md:col-6">
                <label for="stateName">Name</label>
                <input type="text" name="stateName" id="stateName" pInputText formControlName="stateName">
            </div>
        </div>
        <br />

        <div class="field col-6 md:col-6">
            <button *ngIf="this.isEdit== false" class="mr-2" type="submit" [disabled]="formState.invalid" pButton
                label="Save"></button>
            <button *ngIf="this.isEdit== true" class="mr-2" type="submit" [disabled]="formState.invalid" pButton
                label="Update"></button>
            <button type="button" pButton label="Cancel" (click)="Cancel()"></button>
        </div>

    </form>
</div>