import { Component, OnInit } from '@angular/core';
import {Table} from 'primeng/table';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {MessageService, ConfirmationService, PrimeNGConfig, LazyLoadEvent} from 'primeng/api'; 
import { NotaryElement } from 'app/models/notary.model';
import { NotaryRequestServiceService } from 'app/services/notaryServices/notary-request-service.service';
import {NotaryModalComponent} from '../notary-modal/notary-modal.component';
@Component({
  selector: 'app-notary',
  templateUrl: './notary.component.html',
  providers: [DialogService, MessageService]
})
export class NotaryComponent implements OnInit {

  private lastTableLazyLoadEvent: LazyLoadEvent;

  notaryDialog: boolean;
  notaries: NotaryElement[];
  notary: NotaryElement;
  selectednotaries: NotaryElement[];
  submitted: boolean;
  loading: boolean;
  allRegisters: number;
  cols: any[];

  constructor(
    public messageService: MessageService,
    public dialogService: DialogService,
    private notaryService: NotaryRequestServiceService,
    private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService
    ) { }

    ref: DynamicDialogRef;


  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.loading = true;
    //this.cargarNotaries();

  }

  cargarNotaries() {
    this.loading = true;
    this.notaries = [];
    setTimeout(() => {
      this.notaryService.getNotaries().subscribe(res => {
        this.loading = false;
        if(res){
          this.notaries = res;
          this.allRegisters = res.length;
        }
      })
    }, 1000);
  }
  clear(table: Table) {
    table.clear();

}

  createNotary(){
    this.ref = this.dialogService.open(NotaryModalComponent, {
      header: 'Add notary',
      width: '70%',
      contentStyle: {"max-height": "500px", "overflow": "auto"},
      baseZIndex: 10000
  });

  this.ref.onClose.subscribe((response: boolean) =>{
    if (response == true) {
        this.messageService.add({severity:'success', summary: 'Successful', detail: 'Notary Create ', life: 3000});
        this.loadNotaries(this.lastTableLazyLoadEvent);
   
    }
     
  });
  }

  deleteSelectedNotary(){
    
    this.confirmationService.confirm({
      message: 'Are you sure you want to deletes selected notaries?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        for (let i = 0; i < this.selectednotaries.length; i++) {
          
          this.notaryService.deleteNotary(this.selectednotaries[i]).subscribe()
        }
        
        this.loadNotaries(this.lastTableLazyLoadEvent);
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Notaries Deleted', life: 3000});
      }
  });
  }

  editNotary(notary){
    this.notary = notary;
    this.ref = this.dialogService.open(NotaryModalComponent, {
      header: 'Edit Notary',
      width: '70%',
      contentStyle: {"max-height": "500px", "overflow": "auto"},
      baseZIndex: 10000,
      data: {notary: this.notary}
  });
  this.ref.onClose.subscribe((response: boolean) =>{
    if (response== true) {
        this.messageService.add({severity:'success', summary: 'Successful', detail: 'Notary Edit '+ notary.name, life: 3000});
        this.loadNotaries(this.lastTableLazyLoadEvent);
      }
   
    });
  }

  deleteNotary(notary){
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + notary.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.notaryService.deleteNotary(notary).subscribe(  (_apiResp: any) => {
         
          this.loadNotaries(this.lastTableLazyLoadEvent);
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Notary Deleted', life: 3000});
        },
        (_err: any) => {
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Notary not  Deleted', life: 3000});
         
        }
        )
          
          
      }
  });
  }

  detailNotay(notary){
    this.ref = this.dialogService.open(NotaryModalComponent, {
      header: 'View details',
      width: '70%',
      contentStyle: {"max-height": "500px", "overflow": "auto"},
      baseZIndex: 10000,
      data: {notary: notary}
  });
  }


  ActivateNotary(notary){
    this.confirmationService.confirm({
      message: 'Are you sure you want to activate  ' + notary.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        notary.active = true;
         this.notaryService.updateNotary(notary).subscribe(
          (_apiResp: any) => {
            this.loadNotaries(this.lastTableLazyLoadEvent);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Notary activate', life: 3000});
           
          },
          (_err: any) => {
            
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Notary not activate', life: 3000});
           
          }
        ) 
      }
  });
  }


  
  InactivateNotary(notary){
  this.confirmationService.confirm({
    message: 'Are you sure you want to inactivate  ' + notary.name + '?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      notary.active = false;
       this.notaryService.updateNotary(notary).subscribe(
         
        (_apiResp: any) => {
          this.loadNotaries(this.lastTableLazyLoadEvent);
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Notary Inactivate', life: 3000});
         
        },
        (_err: any) => {

          this.messageService.add({severity:'error', summary: 'Error', detail: 'Notary not inactivate', life: 3000});
         
        }
      ) 
    }
});
}


loadNotaries(event: LazyLoadEvent) {  
  this.loading = true;
  this.lastTableLazyLoadEvent = event;
  let currentPage = event.first / event.rows ;
  setTimeout(() => {
    this.notaries = [];
    this.notaryService.getNotariesPaginator(currentPage, event.rows).subscribe(res => {
      this.loading = false;
    
      if(res){
      
        this.notaries = res.content;
        this.allRegisters = res.totalElements;
        
      }
    })
  }, 1000);
}

}
