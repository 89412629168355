<p-toast></p-toast>
<div class="card">


    <p-table #dt [value]="states" [paginator]="true" [rows]="10" [totalRecords]="allRegisters" [loading]="loading"
        [(selection)]="selectedStates" [lazy]="true" (onLazyLoad)="loadStates($event)" 
        [rowsPerPageOptions]="[10,25,50]" [globalFilterFields]="['stateId','stateName']">

        <ng-template pTemplate="caption">
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <button type="button" (click)="openDialog()" pButton icon="pi pi-plus" label="New"
                        class="mr-2"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedStates()"
                        [disabled]="!selectedStates || !selectedStates.length"></button>
                </ng-template>

                <ng-template pTemplate="right">
                    <span class="p-input-icon-left">
                        <em class="pi pi-search"></em>
                        <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Search..." />
                    </span>
                </ng-template>
            </p-toolbar>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem" id=" ">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th id=" " pSortableColumn="stateId">State Id. <p-sortIcon field="stateId"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="stateName">Name <p-sortIcon field="stateName"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="active">Active <p-sortIcon field="stateName"></p-sortIcon>
                </th>
                <th id=" "> </th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-state>
            <tr>
                <td>
                    <p-tableCheckbox [value]="state"></p-tableCheckbox>
                </td>
                <td>{{state.stateId}}</td>
                <td>{{state.stateName}}</td>
                <td *ngIf="state.active">
                    <p-tag styleClass="mr-2" icon="pi pi-check" severity="success" value="Yes"></p-tag>
                </td>
                <td *ngIf="!state.active">
                    <p-tag styleClass="mr-2" icon="pi pi-times" severity="danger" value="No"></p-tag>
                </td>

                <td>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded mr-2"
                        (click)="editState(state)"></button> 
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded  mr-2"
                        (click)="deleteState(state)"></button> 
                    <button pButton pRipple icon="pi pi-eye" pTooltip="Detail State" class="p-button-rounded mr-2"
                        (click)="detailState(state)"></button>
                    <button *ngIf="state.active" pButton pRipple icon="pi pi-times" pTooltip="Inactivate State"
                        class="p-button-rounded" (click)="InactivateState(state)"></button>
                    <button *ngIf="!state.active" pButton pRipple icon="pi pi-check" pTooltip="Activate State"
                        class="p-button-rounded" (click)="ActivateState(state)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                All Registers {{states ? allRegisters : 0 }} states.
            </div>
        </ng-template>
    </p-table>
</div>