import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BankElement } from 'app/models/bank.model';
import { catchError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BankrequestsService {
  url:string = environment.urlBase
  constructor(private http: HttpClient) { }
  headers: HttpHeaders = new HttpHeaders({
    "Content-type": "application/json",
    "accept": "*"
  });

  getBanks() {
    let url_banks = this.url+'banks/';
    return this.http.get<any>(url_banks,{headers: this.headers});
  }

  addBanks(bank) {
    let url_banks = this.url+'banks/';
    return this.http.post<BankElement>(url_banks,bank,{headers: this.headers});
  }
  updateBanks(bank) {
    let url_banks = this.url+'banks/';
    return this.http.put<BankElement>(url_banks,bank,{headers: this.headers});
  }
  deleteBanks(bank) {
    let url_banks = this.url+'banks/'+bank.bankId;
    return this.http.delete(url_banks, {observe : 'response'});
  }

  getBanksPaginator(first, total){
    let url_banks = this.url+'banks/'+first+'/'+total;
    return this.http.get<any>(url_banks,{headers: this.headers});
  }

  
}

