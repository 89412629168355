<div class="layout-wrapper" [ngClass]="{'layout-wrapper-menu-active':sidebarActive,
                                        'layout-overlay-menu':overlay, 'p-ripple-disabled': !app.ripple, 'p-input-filled': app.inputStyle === 'filled'}">

    <app-topbar></app-topbar>

    <app-sidebar></app-sidebar>

    <div class="layout-main">
        <div class="layout-main-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-config></app-config>

    <app-footer></app-footer>

</div>
 