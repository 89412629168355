import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { R3Element } from 'app/models/R3.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class R3requestsService {
  url:string = environment.urlBase
  constructor(private http: HttpClient) { }
  headers: HttpHeaders = new HttpHeaders({
    "Content-type": "application/json",
    "accept": "*"
  });

  getR3s() {
    let url_R3s = this.url+'r3/';
    return this.http.get<R3Element[]>(url_R3s,{headers: this.headers});
  }
  getR3(r3FileNumber) {
    let url_R3s = this.url+'r3/'+r3FileNumber;
    return this.http.get<R3Element>(url_R3s,{headers: this.headers});
  }
  getR3sNotary(notaryName) {
    let url_R3s = this.url+'r3/notaryUserName/'+notaryName;
    return this.http.get<R3Element[]>(url_R3s,{headers: this.headers});
  }
  addR3s(R3) {
    let url_R3s = this.url+'r3/';
    return this.http.post<R3Element>(url_R3s,R3,{headers: this.headers});
  }
  updateR3s(R3) {
    let url_R3s = this.url+'r3/';
    return this.http.put<R3Element>(url_R3s,R3,{headers: this.headers});
  }
  deleteR3(R3){
    let url_R3 = this.url+'r3/'+R3.r3FileNumber;
    return this.http.delete<R3Element>(url_R3, {observe : 'response'});
  }
}

