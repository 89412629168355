import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from 'primeng/api';
import { Component } from '@angular/core';
import { AppMainComponent } from '../main/app.main.component';
import {KeycloakService} from 'keycloak-angular';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent  {

  data = [];
  constructor(
    public appMain: AppMainComponent,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private keycloakService :KeycloakService
  ) {
    
   }

   ngOnInit(): void {
    this.initializeUserOptions();  
   }
   initializeUserOptions(){
     
     this.user = this.keycloakService.getUsername();
     this.data =  this.keycloakService.getKeycloakInstance().tokenParsed["roles"];
     this.data = this.data.filter(val => val.substring(0, 7).toLowerCase() == "closing");
     this.rol = this.data[0];
   }
  user : string ="";
  rol : string ="";
  confirmar() {
    this.confirmationService.confirm({
      message:
        'Al cerrar sesión automaticamente se cerrara la caja actual ¿Desea cerrar sesión?',
      header: 'Cerrar Sesión',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Cerrar Sesión',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmado',
          detail: 'Usted ha aceptado',
        });     
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              severity: 'Error',
              summary: 'Rechazado',
              detail: 'Usted ha rechazado',
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              severity: 'Advertencia',
              summary: 'Cancelado',
              detail: 'Usted ha cancelado',
            });
            break;
        }
      },
    });
  }

  cerrarSesion() {
    this.keycloakService.logout();
    this.keycloakService.init();
  }
}
