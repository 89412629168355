import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClosingElement } from 'app/models/closing.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClosingrequestsService {
  url:string = environment.urlBase
  constructor(private http: HttpClient) { }
  headers: HttpHeaders = new HttpHeaders({
    "Content-type": "application/json",
    "accept": "*"
  });

  getClosings() {
    let url_closings = this.url+'closing/';
    return this.http.get<ClosingElement[]>(url_closings,{headers: this.headers});
  }
  getClosing(devalFileNumber) {
    let url_closings = this.url+'closing/'+devalFileNumber;
    return this.http.get<ClosingElement>(url_closings,{headers: this.headers});
  }
  addClosings(closing) {
    let url_closings = this.url+'closing/';
    return this.http.post<ClosingElement>(url_closings,closing,{headers: this.headers});
  }
  updateClosings(closing) {
    let url_closings = this.url+'closing/';
    return this.http.put<ClosingElement>(url_closings,closing,{headers: this.headers});
  }
  deleteClosing(closing){
    let url_closing = this.url+'closing/'+closing.devalFileNumber;
    return this.http.delete<ClosingElement>(url_closing, {observe : 'response'});
  }
}

