import { Component, OnInit } from '@angular/core';

import { BankrequestsService } from 'app/services/bankServices/bankrequests.service';
import {MessageService, ConfirmationService} from 'primeng/api'; 
import {BankModalComponent} from '../bank-modal/bank-modal.component';
import { BankElement } from 'app/models/bank.model';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from "primeng/api";
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import { LazyLoadEvent } from 'primeng/api';
import { KeycloakService } from 'keycloak-angular';



@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.css'],
  providers: [DialogService, MessageService]
})

export class BanksComponent implements OnInit {
  
  
  private lastTableLazyLoadEvent: LazyLoadEvent;

  bankDialog: boolean;
  banks: BankElement[];
  bank: BankElement;
  selectedBanks: BankElement[];
  submitted: boolean;
  loading: boolean;
  allRegisters: number;
  cols: any[];
  response : any[];



  constructor( 
    public messageService: MessageService,
    public dialogService: DialogService,
    private bankService: BankrequestsService, 
    private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService,
    private keycloakService: KeycloakService
    ) { }

  ref: DynamicDialogRef;

  ngOnInit(): void {
    //"this.getBanks();
    this.primengConfig.ripple = true;
    this.loading = true;
    //this.cargarBanks();

  }

  detalleBank(bank){
    this.bank = bank;
    this.ref = this.dialogService.open(BankModalComponent, {
      header: 'View details',
      width: '70%',
      contentStyle: {"max-height": "500px", "overflow": "auto"},
      baseZIndex: 10000,
      data: {bank: this.bank}
  });
  }

  editBank(bank){
    this.bank = bank;
    this.ref = this.dialogService.open(BankModalComponent, {
      header: 'Edit Bank',
      width: '70%',
      contentStyle: {"max-height": "500px", "overflow": "auto"},
      baseZIndex: 10000,
      data: {bank: this.bank}
  });
  this.ref.onClose.subscribe((response: boolean) =>{
      if (response == true) {
       // this.banks = this.banks.filter(val => val.bankId !== bank.bankId);
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Bank Edit '+ bank.bankName, life: 3000});
          this.loadBanks(this.lastTableLazyLoadEvent);
     
      }
     
  });
  }

  deleteBank(bank: BankElement) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + bank.bankName + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          
           this.bankService.deleteBanks(bank).subscribe(
            (_apiResp: any) => {
             
              this.loadBanks(this.lastTableLazyLoadEvent);
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Bank Deleted', life: 3000});
            },
            (_err: any) => {
             
              this.messageService.add({severity:'error', summary: 'Error', detail: 'Bank not  Deleted', life: 3000});
             
            }
          ) 
        }
    });
}

  findIndexById(id: number): number {
    let index = -1;
    for (let i = 0; i < this.banks.length; i++) {
      if (this.banks[i].bankId === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  public createBank(){
    this.ref = this.dialogService.open(BankModalComponent, {
      header: 'Add bank',
      width: '70%',
      contentStyle: {"max-height": "500px", "overflow": "auto"},
      baseZIndex: 10000
  });

  this.ref.onClose.subscribe((response: boolean) =>{
    if (response == true) {
        this.messageService.add({severity:'success', summary: 'Successful', detail: 'Bank Create ', life: 3000});
        this.loadBanks(this.lastTableLazyLoadEvent);
   
    }
     
  });
  }





  cargarBanks() {
    this.loading = true;
    this.banks = [];
    setTimeout(() => {
      this.bankService.getBanks().subscribe(res => {
        this.loading = false;
      
        if(res){
          this.banks = res;
          this.allRegisters = res.length;
          
        }
      })
    }, 1000);
  }
  clear(table: Table) {
    table.clear();

}

deleteSelectedBanks() {
  this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected banks?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        for (let i = 0; i < this.selectedBanks.length; i++) {
        this.bankService.deleteBanks(this.selectedBanks[i]).subscribe();
      }
      this.loadBanks(this.lastTableLazyLoadEvent);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Banks Deleted', life: 3000});
    }
  });
}

InactivateBank(bank){
  this.confirmationService.confirm({
    message: 'Are you sure you want to inactivate  ' + bank.bankName + '?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      bank.active = false;
       this.bankService.updateBanks(bank).subscribe(
         
        (_apiResp: any) => {
          this.loadBanks(this.lastTableLazyLoadEvent); 
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Bank Inactivate', life: 3000});
         
        },
        (_err: any) => {

          this.messageService.add({severity:'error', summary: 'Error', detail: 'Bank not inactivate', life: 3000});
         
        }
      ) 
    }
});
}


ActivateBank(bank){
  this.confirmationService.confirm({
    message: 'Are you sure you want to activate  ' + bank.bankName + '?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      bank.active = true;
       this.bankService.updateBanks(bank).subscribe(
        (_apiResp: any) => {
          this.loadBanks(this.lastTableLazyLoadEvent);
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Bank activate', life: 3000});
         
        },
        (_err: any) => {
         
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Bank not activate', life: 3000});
         
        }
      ) 
    }
});
}


loadBanks(event: LazyLoadEvent) {  
  this.loading = true;
  this.lastTableLazyLoadEvent = event;
  let currentPage = event.first / event.rows ;
  setTimeout(() => {
    this.banks = [];
    this.bankService.getBanksPaginator(currentPage, event.rows).subscribe(res => {
      this.loading = false;
    
      if(res){
      
        this.banks = res.content;
        this.allRegisters = res.totalElements;
        
      }
    })
  }, 1000);
}



}
