import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotaryElement } from 'app/models/notary.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotaryRequestServiceService {
  url:string = environment.urlBase
  constructor(private http: HttpClient) { }
  headers: HttpHeaders = new HttpHeaders({
    "Content-type": "application/json",
    "accept": "*"
  });
  getNotaries() {
    let url_notary = this.url+'notary/';
    return this.http.get<NotaryElement[]>(url_notary,{headers: this.headers});
  }
  getNotaryByName(name) {
    let url_notary = this.url+'notary/username/'+name;
    return this.http.get<NotaryElement[]>(url_notary,{headers: this.headers});
  }
  addNotary(notary) {
    let url_notary = this.url+'notary/';
    return this.http.post<NotaryElement>(url_notary,notary,{headers: this.headers});
  }
  updateNotary(notary) {
    let url_notary = this.url+'notary/';
    return this.http.put<NotaryElement>(url_notary,notary,{headers: this.headers});
  }
  deleteNotary(notary){
    let url_notary = this.url+'notary/'+notary.notaryId;
    return this.http.delete<NotaryElement>(url_notary, {observe : 'response'});
  }
  getNotariesPaginator(first, total){
    let url_notary = this.url+'notary/'+first+'/'+total;
    return this.http.get<any>(url_notary,{headers: this.headers});
  }
}
