import {Component} from '@angular/core';


@Component({
    /* tslint:disable:component-selector */
    selector: 'app-sidebarTabContent',
    /* tslint:enable:component-selector */
    templateUrl: './app.sidebartabcontent.component.html'
})
export class AppSidebartabcontentComponent {
}
