import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ContractorRequestServiceService } from 'app/services/contractorServices/contractor-request-service.service';
import { RequestsService } from 'app/services/requestServices/requests.service';
import { ContractorElement } from 'app/models/contractor.model';
import { MessageService, } from "primeng/api";
import { StateElement } from 'app/models/state.model';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: 'app-contractor-modal',
  templateUrl: './contractor-modal.component.html',
})
export class ContractorModalComponent implements OnInit {

  public formContractor: UntypedFormGroup;
  public isEdit: any = false;
  public states: StateElement[];
  contractor: ContractorElement;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private formBuilder: UntypedFormBuilder, private contractorService: ContractorRequestServiceService,
    private StatesService: RequestsService, public messageService: MessageService) {
    if (config.data) {
      this.contractor = (config.data.contractor);
    }
  }

  ngOnInit(): void {

    this.StatesService.getStates().subscribe(res => {
      if (res) {
        this.states = res
      }
    }

    );

    this.formContractor = this.formBuilder.group({
      contractorId: ['', [Validators.required, Number]],
      name: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      address: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      active: [true],
      createdByUser: [''],
      createdDate: [Date],
      lastModifiedDate: [Date],
      modifiedByUser: [''],
      version: [0]
    });
    if (this.contractor) {
      this.isEdit = true;
      this.formContractor.patchValue(this.contractor);
    }
  }



  Cancel() {
    this.ref.close()
  }

  onSubmit() {
    if (this.formContractor.dirty && this.formContractor.valid) {
      if (this.isEdit) {
        this.contractorService.updateContractor(this.formContractor.value).subscribe((_apiResp: any) => {
          this.ref.close(true)
        },
          (_err: any) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contractor not  edit', life: 3000 });
          }
        )
      } else {
        console.log(this.formContractor.value);

        this.contractorService.addContractor(this.formContractor.value).subscribe((_apiResp: any) => {
          this.ref.close(true)
        },
          (_err: any) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Contractor not  create', life: 3000 });
          }
        )
      }

    }

  }


}
