import { Component, OnInit } from '@angular/core';
import { DialogService} from 'primeng/dynamicdialog';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { DashboardComponent } from 'app/dashboard/dashboard.component';
import { gql } from 'apollo-angular';


@Component({
  selector: 'app-listing-agent',
  templateUrl: './listing-agent.component.html',
  providers: [DialogService, MessageService]
})
export class ListingAgentComponent extends DashboardComponent implements OnInit {
  cargarClosingListingAgentGraphql(event: LazyLoadEvent) {
    if (!this.searchElement.fha && !this.searchElement.devalFileNumber && !this.selectedDate.value && !this.searchElement.contractDateFrom && !this.searchElement.contractDateTo) {
    this.error = "";
    this.loading = true;
    var nameAgent = ' ';
    this.lastTableLazyLoadEvent = event;
    let currentPage = event.first / event.rows;

    if (this.keycloakService.isUserInRole("closingListingAgent")) {
      nameAgent = `listingAgent:"${this.keycloakService.getUsername()}"`;
    }
    this.apollo
      .query<any>({
        query: gql`
        {
          findAllClosingPaginatorSearch(page: ${currentPage}, size: ${event.rows}, searchData:{
            ${nameAgent}
          }){
            totalPages
            totalElements
            last
            numberOfElements
            empty
            first
            content{
              fha
              devalFileNumber
              postClosingComplete
              status
              contractDate
              propertyInformation{
                propertyAddress
                city
                state{
                  stateName
                  stateId
                }
                zipCode
              }
              preClosingInformation{
                saleAmount
                amContractor{name}
                lenderBank{
                  bankName
                }
              }
              financials{
                sellingAgent{
                  userData{
                    fullName
                    phoneNumber
                  }
                  gross
                  net
                }
                listingAgent{
                  userData{
                    fullName
                    phoneNumber
                  }
                  gross
                  net
                }
              }
              closingInformation{
                scheduleClosingDate
                actualClosingDate
                closingCancelledDate
              }
              buyerInformation{
                name
                middleName
                lastName
              }
            }
          }
        }
        `
      })
      .subscribe(
        ({ data, loading }) => {
          if (data.findAllClosingPaginatorSearch.empty) {
            this.messageService.add({ severity: 'info', summary: 'You have no assigned closing' });
            this.closing = {};
            this.loading = loading;
            this.isClosingAsigned = false;
          } else {
            this.loading = loading;
            this.closings = data.findAllClosingPaginatorSearch.content;
            console.log(this.closings.length)
            this.closing = {};
            this.closing.financials = {};
            for (let i of this.closings) {
              this.closing.fha = i.fha;
              this.closing.devalFileNumber = i.devalFileNumber;
              this.closing.buyerInformation = i.buyerInformation;
              this.closing.propertyInformation = i.propertyInformation;
              this.closing.preClosingInformation = i.preClosingInformation;
              this.closing.status = i.status;
              this.closing.contractDate = i.contractDate;
              this.closing.financials = i.financials;
              this.closing.closingInformation = i.closingInformation;
              this.closing.postClosingComplete = i.postClosingComplete;
            }
            this.allRegisters = data.findAllClosingPaginatorSearch.totalElements;

          }
        }
      );
      }
  }
  clearListing() {
    this.isSearch = false;
    this.searchElement = {};
    this.selectedDate = {};
    this.first = 0;
    this.lastTableLazyLoadEvent.first = 0;
    this.lastTableLazyLoadEvent.rows = 10;
    this.searchListing(this.lastTableLazyLoadEvent);
  }
  searchListing(event?: LazyLoadEvent) {
    this.isSearch = true;
    var inputFha = (this.searchElement.fha ? `fha: "${this.searchElement.fha}",` : '');
    var inputDevalFileNumber = (this.searchElement.devalFileNumber ? `devalFileNumber: "${this.searchElement.devalFileNumber}",` : '');
    var inputStatus = (this.selectedDate.value ? `status: "${this.selectedDate.value}",` : ' ');
    var listingAgent = ' ';
    let currentPage;
    let size;
    if (event) {
      currentPage = event.first / event.rows;
      this.first = event.first;
      size = event.rows;
    } else {
      this.first = 0;
      size = 10;
      currentPage = this.first / size;  
    }
    if (this.searchElement.contractDateFrom) {
      var dateFrom = new Date(this.searchElement.contractDateFrom.getTime() - this.searchElement.contractDateFrom.getTimezoneOffset() * 60000).toISOString();
    }
    if (this.searchElement.contractDateTo) {
      var dateTo = new Date(this.searchElement.contractDateTo.getTime() - this.searchElement.contractDateTo.getTimezoneOffset() * 60000).toISOString();
    }

      listingAgent = `listingAgent:"${this.keycloakService.getUsername()}"`;

    var inputContractDateFrom = (this.searchElement.contractDateFrom ? `contractDateFrom:"${dateFrom}",` : ' ');
    var inputContractDateTo = (this.searchElement.contractDateTo ? `contractDateTo:"${dateTo}",` : ' ');

    if (inputFha || inputStatus || inputContractDateFrom || inputContractDateTo || listingAgent) {
      this.dialogSearch = false;
      this.apollo
        .query<any>({
          query: gql`
        {
          findAllClosingPaginatorSearch(page: ${currentPage}, size: ${size}, searchData:{
            ${inputFha}
            ${inputDevalFileNumber}
            ${inputStatus}
            ${inputContractDateFrom}
            ${inputContractDateTo}
            ${listingAgent}
          }){
            totalPages
            totalElements
            last
            numberOfElements
            empty
            first
            content{
                fha
                devalFileNumber
                postClosingComplete
                status
                contractDate
                propertyInformation{
                  propertyAddress
                  city
                  state{
                    stateName
                    stateId
                  }
                  zipCode
                }
                preClosingInformation{
                  saleAmount
                  amContractor{name}
                  lenderBank{
                    bankName
                  }
                }
                financials{
                  sellingAgent{
                    userData{
                      fullName
                      phoneNumber
                    }
                    gross
                    net
                  }
                  listingAgent{
                    userData{
                      fullName
                      phoneNumber
                    }
                    gross
                    net
                  }
                }
                closingInformation{
                  scheduleClosingDate
                  actualClosingDate
                  closingCancelledDate
                }
                buyerInformation{
                  name
                  middleName
                  lastName
                }
              }
            }
          }
        `
        })
        .subscribe(
          ({ data }) => {
            if (data.findAllClosingPaginatorSearch.empty) {
              this.messageService.add({ severity: 'info', summary: 'There are no results' });
              this.closings = [];
              this.allRegisters = 0;
            } else {
              this.closings = data.findAllClosingPaginatorSearch.content;
              this.allRegisters = data.findAllClosingPaginatorSearch.totalElements;

            }

          }
        );
    }
    else {
      this.dialogSearch = true;
      this.messageService.add({ severity: 'info', summary: 'Please add a filter to search' });
    }

  }

}
