<div class="layout-sidebar" [ngStyle]="{'overflow':appMain.sidebarActive ? 'hidden' : 'visible'}"
                            [ngClass]="{'layout-sidebar-dark':app.darkMenu}" (click)="appMain.onSidebarClick($event)">
    <div class="layout-tabmenu">
        <ul class="layout-tabmenu-nav">
            <li [ngClass]="{'active-item':appMain.activeTabIndex === 0}">
                <a href="#" pRipple class="tabmenuitem-link" (click)="appMain.onTabClick($event, 0)"><em class="pi pi-fw pi-bars"></em></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Menu</div>
                </div>
            </li>
        </ul>
        <div class="layout-tabmenu-contents">
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': appMain.activeTabIndex === 0}">
                <div class="layout-submenu-title clearfix">
                    <span>Menu</span>
                    <a href="#" class="menu-button pi pi-times" (click)="appMain.closeSidebar($event)"></a>
                </div>
                <app-sidebarTabContent>
                    <app-menu></app-menu>
                </app-sidebarTabContent>
            </div>
        </div>
    </div>
</div>
