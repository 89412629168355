<div class="card">
    <p-progressSpinner *ngIf="!isLoad" style="margin-left: 50%; transform: translateX(-50%);"></p-progressSpinner>
    <p-tabView *ngIf="isLoad">
        <p-accordion [multiple]="true">
            <p-accordionTab header="Invoice:" [selected]="true">
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-3">
                        <label for="R3">R3 File number</label>
                        <p-dropdown [readonly]="isView" (onChange)="myAlert()" [options]="R3s"
                            optionLabel="r3FileNumber" optionValue="r3FileNumber" styleClass="form-control"
                            [(ngModel)]="R3" placeholder="R3 File number" class="p-inputtext-sm">
                        </p-dropdown>
                        <small class="p-error" *ngIf="submitted && !R3">R3 File number is
                            required.</small>
                    </div>

                    <div class="field col-12 md:col-3">
                        <label for="fecha">Fecha</label>
                        <p-calendar  [disabled]="isView" inputId="calendar" placeholder="mm/dd/yyyy"
                            class=" ng-dirty p-inputtext-sm" [(ngModel)]="notaryInvoice.fecha" [showIcon]="true">
                        </p-calendar>
                        <small class="p-error" *ngIf="submitted && !notaryInvoice.fecha">Fecha is
                            required.</small>
                    </div>
                    <div class="field col-12 md:col-3">
                        <label for="numeroFactura">Número de Factura</label>
                        <input [readonly]="isView || isEdit" type="text" name="numeroFactura" id="numeroFactura"
                            pInputText [(ngModel)]="notaryInvoice.numeroFactura" class="p-inputtext-sm">
                        <small class="p-error" *ngIf="submitted && !notaryInvoice.numeroFactura">Número de Factura is
                            required.</small>
                    </div>
                    <div class="field col-12 md:col-3">
                        <label for="fechaCierre">Fecha Cierre</label>
                        <p-calendar [disabled]="isView" inputId="calendar" placeholder="mm/dd/yyyy"
                            class=" ng-dirty p-inputtext-sm" [(ngModel)]="notaryInvoice.fechaCierre" [showIcon]="true">
                        </p-calendar>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="A:">
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-8">
                        <label for="facturaA">A</label>
                        <input [readonly]="isView" type="text" name="facturaA" id="facturaA" pInputText
                            [(ngModel)]="notaryInvoice.facturaA" class="p-inputtext-sm">
                        <small class="p-error" *ngIf="submitted && !notaryInvoice.facturaA">A is
                            required.</small>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="contrato">Contrato</label>
                        <input [readonly]="isView" type="text" name="contrato" id="contrato" pInputText
                            [(ngModel)]="notaryInvoice.contrato" class="p-inputtext-sm">
                        <small class="p-error" *ngIf="submitted && !notaryInvoice.contrato">Contrato is
                            required.</small>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Por concepto de:">
                <div class="grid p-fluid">
                    <div *ngIf="notaries" class="field col-12 md:col-4">
                        <label for="notary">Transacci&oacute;n</label>
                        <p-dropdown [readonly]="isView" [options]="transacciones" optionLabel="name" optionValue="value"
                            styleClass="form-control" [(ngModel)]="transaccion" placeholder="Transacciones"
                            class="p-inputtext-sm">
                        </p-dropdown>
                        <small class="p-error" *ngIf="submitted && !transaccion">Transacci&oacute;n is
                            required.</small>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="participante">Participante</label>
                        <input [readonly]="true" type="text" name="participante" id="participante" pInputText
                            [(ngModel)]="notaryInvoice.r3.sellerInformation.fullName" class="p-inputtext-sm">
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="propiedad">Propiedad</label>
                        <input [readonly]="true" type="text" name="propiedad" id="propiedad" pInputText
                            [(ngModel)]="notaryInvoice.r3.propertyInformation.propertyAddress" class="p-inputtext-sm">
                    </div>
                    <div *ngIf="notaries" class="field col-12 md:col-4">
                        <label for="notary">Notario Asignado</label>
                        <p-dropdown [readonly]="isView" (onChange)="myAlert2()" [options]="notaries"
                            optionLabel="fullName" optionValue="notaryId" styleClass="form-control" [(ngModel)]="notary"
                            placeholder="Notario" class="p-inputtext-sm">
                        </p-dropdown>
                        <small class="p-error" *ngIf="submitted && !notary">Notario Asignado is
                            required.</small>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="bankCity">Cuantia</label>
                        <p-inputNumber [readonly]="true" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.r3.preClosingInformation.voucherAmount" class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div *ngIf="notaries" class="field col-12 md:col-4">
                        <label for="notary">Tipo</label>
                        <p-dropdown [readonly]="true" [options]="tipos" optionLabel="name" styleClass="form-control"
                            [(ngModel)]="tipo" placeholder="Tipo" class="p-inputtext-sm">
                        </p-dropdown>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Descripción del servicio:">
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-12">
                        <label for="honorariosNotariales">Honorarios Notariales</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.honorariosNotariales" class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                </div>
                <br />
                <p-divider align="center">
                    <span class="p-tag">Aranceles</span>
                </p-divider>
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-4">
                        <label for="bankCity">Comprobante Presentación</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.arancelesDTO.comprobantePresentacion" class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="Zipcode">Comprobante Inscripción</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.arancelesDTO.comprobanteInscripcion" class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="bankCity">Código Político</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.arancelesDTO.codigoPolitico" class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                </div>
                <br />
                <p-divider align="center">
                    <span class="p-tag">Sellos Escritura Original</span>
                </p-divider>

                <div class="grid p-fluid">
                    <div class="field col-12 md:col-4">
                        <label for="city">Rentas Internas</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.sellosEscrituraOriginalDTO.rentasInternas"
                            class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="city">Sociedad para la Asistencia Legal</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.sellosEscrituraOriginalDTO.sociedadAsistenciaLegal"
                            class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="address">Impuesto Notarial</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.sellosEscrituraOriginalDTO.impuestoNotarial"
                            class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                </div>
                <br />
                <p-divider align="center">
                    <span class="p-tag">Sellos Escritura Certificada</span>
                </p-divider>
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-4">
                        <label for="city">Rentas Internas</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.sellosEscrituraCertificadaDTO.rentasInternas"
                            class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="city">Sociedad para la Asistencia Legal</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.sellosEscrituraCertificadaDTO.sociedadAsistenciaLegal"
                            class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="address">Impuesto Notarial</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.sellosEscrituraCertificadaDTO.impuestoNotarial"
                            class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                </div>
                <br />
                <p-divider align="center">
                    <span class="p-tag">Sellos / Aranceles Adicionales</span>
                </p-divider>
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-6">
                        <label for="city">Presentación Telemática</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.sellosArancelesAdicionalesDTO.presentacionTelematica"
                            class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-6">
                        <label for="city">Sello Asistencia Legal Affidavit</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.sellosArancelesAdicionalesDTO.selloAsistenciaLegalAffidavit"
                            class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                </div>
                <br />
                <p-divider align="center">
                    <span class="p-tag">Gastos Relacionados</span>
                </p-divider>
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-4">
                        <label for="city">Presentación Digital</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.gastosRelacionadosDTO.presentacionDigital"
                            class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="city">Planilla CRIM</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.gastosRelacionadosDTO.planillaCRIM" class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="city">Planilla Traslado de Dominio</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.gastosRelacionadosDTO.planillaTrasladoDominio"
                            class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-6">
                        <label for="city">Arancel Copia Certificada</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.gastosRelacionadosDTO.arancelCopiaCertificada"
                            class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-6">
                        <label for="city">Gastos Misceláneos (procesamiento de documentos, etc.)</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.gastosRelacionadosDTO.gastosMiscelaneos" class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                </div>
                <br />
                <p-divider align="center">
                    <span class="p-tag">Resumen Gastos</span>
                </p-divider>
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-3">
                        <label for="city">Notariales</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.resumenGastosDTO.notariales" class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-3">
                        <label for="city">Tarifas</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.resumenGastosDTO.tarifas" class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-3">
                        <label for="city">Reembolsables</label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.resumenGastosDTO.reembolsables" class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                    <div class="field col-12 md:col-3">
                        <label for="city"><strong>Total</strong></label>
                        <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                            [(ngModel)]="notaryInvoice.resumenGastosDTO.total" class="p-inputtext-sm">
                        </p-inputNumber>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Descripción del rechazo:" *ngIf="estadoRechazado(notaryInvoice.status)">
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-12">
                        <label for="comment">Comentario Rechazo</label>
                        <textarea [readonly]="true" [(ngModel)]="notaryInvoice.comentariosRechazo"
                            pInputTextarea></textarea>
                    </div>
                </div>
            </p-accordionTab>
            <br />
            <div class="grid p-fluid" *ngIf="isLoad">
                <div class="field col-0 md:col-6"></div>
                <div class="field col-12 md:col-3" [hidden]="isView">
                    <button type="button" class="mr-2" pButton label="Save" (click)="onSubmit()"></button>
                </div>
                <div class="field col-12 md:col-3" [hidden]="isView">
                    <button type="button" class="mr-2" label="Close" (click)="ConfirmClose()" pButton></button>
                </div>
                <div class="field col-12 md:col-6"
                    *ngIf="isView && (!isAdmin || (isAdmin && !estadoEnviado(notaryInvoice.status)))">
                    <button type="button" class="mr-2" label="Close" (click)="CloseNI()" pButton></button>
                </div>
                <div class="field col-12 md:col-2" *ngIf="isView && isAdmin && estadoEnviado(notaryInvoice.status)">
                    <button type="button" class="mr-2" label="Rechazar" (click)="openDialog()" pButton></button>
                </div>
                <div class="field col-12 md:col-2" *ngIf="isView && isAdmin && estadoEnviado(notaryInvoice.status)">
                    <button type="button" class="mr-2" label="Aceptar" (click)="onSubmit('aceptado')" pButton></button>
                </div>
                <div class="field col-12 md:col-2" *ngIf="isView && isAdmin && estadoEnviado(notaryInvoice.status)">
                    <button type="button" class="mr-2" label="Close" (click)="CloseNI()" pButton></button>
                </div>
            </div>

        </p-accordion>
    </p-tabView>

    <p-dialog [(visible)]="dialog" [focusTrap]="false" [style]="{width: '600px', height: '300px'}"
        header="Rechazar Invoice" [modal]="true" styleClass="p-fluid">
        <ng-template pTemplate="content">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-12">
                    <div class="field col-12 md:col-12">
                        <label for="comment">Comentario Rechazo</label>
                        <textarea [(ngModel)]="comment" pInputTextarea></textarea>
                        <small class="p-error" class="p-inputtext-sm" *ngIf="!comment">Comentario Rechazo is
                            required.</small>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialog()"></button>
            <button [hidden]="isView" pButton pRipple label="Rechazar" icon="pi pi-check" class="p-button-text"
                (click)="onSubmit('rechazado')"></button>
        </ng-template>
    </p-dialog>
</div>