<ng-container>
    <a [attr.href]="item.url" (click)="itemClick($event)" *ngIf="!item.routerLink || item.items"
        (keydown.enter)="itemClick($event)" [attr.target]="item.target" [attr.tabindex]="0" pRipple
        (mouseenter)="hover=true" (mouseleave)="hover=false" [ngClass]="item.class">
        <em [ngClass]="item.icon" class="layout-menuitem-icon"></em>
        <span>{{item.label}}</span>
        <span class="ink" *ngIf="hover"></span>
        <span class="menuitem-badge" *ngIf="item.badge">{{item.badge}}</span>
        <em class="pi pi-fw pi-angle-down submenu-toggler" *ngIf="item.items"></em>
    </a>
    <a (click)="itemClick($event)" (mouseenter)="hover=true" (mouseleave)="hover=false"
        *ngIf="item.routerLink && !item.items" [ngClass]="item.class" [routerLink]="item.routerLink"
        routerLinkActive="active-menuitem-routerlink" pRipple [routerLinkActiveOptions]="{exact: true}"
        [attr.target]="item.target" [attr.tabindex]="0">
        <em [ngClass]="item.icon" class="layout-menuitem-icon"></em>
        <span>{{item.label}}</span>
        <span class="ink" *ngIf="hover"></span>
        <span class="menuitem-badge" *ngIf="item.badge">{{item.badge}}</span>
        <em class="pi pi-fw pi-angle-down submenu-toggler" *ngIf="item.items"></em>
    </a>
    <ul *ngIf="item.items && active" [@children]="(active ? 'visibleAnimated' : 'hiddenAnimated')">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
            <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
        </ng-template>
    </ul>
</ng-container>