import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common'
import { BankElement } from 'app/models/bank.model';
import { ClosingElement } from 'app/models/closing.model';
import { ContractorElement } from 'app/models/contractor.model';
import { extensionClosing } from 'app/models/extensionClosing.model';
import { GenericElement } from 'app/models/generic.model';
import { NotaryElement } from 'app/models/notary.model';
import { StateElement } from 'app/models/state.model';
import { BankrequestsService } from 'app/services/bankServices/bankrequests.service';
import { ClosingrequestsService } from 'app/services/closingServices/closingrequests.service';
import { ContractorRequestServiceService } from 'app/services/contractorServices/contractor-request-service.service';
import { NotaryRequestServiceService } from 'app/services/notaryServices/notary-request-service.service';
import { RequestsService } from 'app/services/requestServices/requests.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DashboardComponent } from 'app/dashboard/dashboard.component';
import { BuyerInformationElement } from 'app/models/buyerInformation.model';
import { ListingAgentServicesService } from 'app/services/listingAgentServices/listing-agent-services.service';
import { SellingAgentServicesService } from 'app/services/sellingAgentServices/selling-agent-services.service';
import { SellingAgentElement } from 'app/models/sellingAgent.model';
import { ListingAgentElement } from 'app/models/listingAgent.model';
import { KeycloakService } from 'keycloak-angular';
import { ClosingProcessorService } from 'app/services/closingProcessorServices/closing-processor.service';
import { ClosingProcessorElement } from 'app/models/closingProcessor.model';

@Component({
  selector: 'app-closing',
  templateUrl: './closing.component.html',
  styleUrls: ['./closing.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ClosingComponent implements OnInit {

  public closing: ClosingElement;

  public closings: ClosingElement[];

  public selectedClosings: ClosingElement[];

  public extensionClosingsBack: extensionClosing[] = [];

  public extensionClosings: extensionClosing[] = [];

  public extensionClosing: extensionClosing;

  public selectedExtensionClosings: extensionClosing[];

  public buyerInformations: BuyerInformationElement[] = [];

  public buyerInformation: BuyerInformationElement;

  public selectedBuyerInformations: BuyerInformationElement[];

  public submitted: boolean = true;

  public minValueZipCode: boolean = true;

  public dialog: boolean;

  public banks: BankElement[];

  public states: StateElement[];

  public contractors: ContractorElement[];

  public notaries: NotaryElement[];

  public sellingAgents: SellingAgentElement[];

  public listingAgents: ListingAgentElement[];

  public closingProcessors: ClosingProcessorElement[];

  public methods: GenericElement[];

  public typesBuyer: GenericElement[];

  public title = "Save Closing";

  public isView: boolean;

  public isEdit: boolean;

  public devalFileNumber: string;

  public isLoad: boolean = true;

  public isContactInformation: boolean = false;

  public datepipe: DatePipe;

  public dialogBuyer: boolean = false;

  public earnestMoneyDispositions: GenericElement[];

  public tiempoTranscurrido = Date.now();

  public lenderDisabled: boolean = true;

  public editBuyer: boolean = false;

  public editExtension: boolean = false;

  public isAdmin: boolean = false;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private bankService: BankrequestsService,
    private stateService: RequestsService,
    private contractorService: ContractorRequestServiceService,
    private notaryService: NotaryRequestServiceService,
    private listingAgentService: ListingAgentServicesService,
    private sellingAgentService: SellingAgentServicesService,
    private closingProcessorService: ClosingProcessorService,
    public closingService: ClosingrequestsService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dashboard: DashboardComponent,
    private keycloakService: KeycloakService
  ) {
    if (config.data) {
      this.devalFileNumber = (config.data.devalFileNumber);
      this.isLoad = false;
      this.loadClosing(this.devalFileNumber);
      this.isView = (config.data.isView);
      this.isEdit = (config.data.isEdit);

    }
    if (!this.closing) {
      this.closing = {};
      this.closing.propertyInformation = {};
      this.closing.propertyInformation.state = {};
      this.closing.financials = {};
      this.closing.financials.closingAgent = {};
      this.closing.financials.listingAgent = {};
      this.closing.financials.sellingAgent = {};
      this.closing.financials.listingAgent.userData = {};
      this.closing.financials.sellingAgent.userData = {};
      this.closing.postClosingInformation = {};
      this.closing.postClosingInformation.notaryId = {};
      this.closing.closingInformation = {};
      this.closing.closingInformation.closingInformationExtentionInformationDTO = [];
      this.closing.preClosingInformation = {};
      this.closing.preClosingInformation.amContractor = {};
      this.closing.buyerInformation = [];
      this.closing.active = true;
      this.closing.postClosingComplete = false;

    }
  }


  ngOnInit(): void {
    this.validarIsAdmin();
    this.loadBanks();
    this.loadStates();
    this.loadContractors();
    this.loadNotaries();
    this.loadListingAgents();
    this.loadSellingAgents();
    this.loadClosingProcessors();
    this.loadMethods();
    this.loadTypesBuyer();
    this.loadEarnest();
    if (this.isEdit) {
      this.title = "Update";
    }

  }
  loadMethods() {
    this.methods = [
      { name: 'Cash', value: 'cash' },
      { name: 'FHA', value: 'fha' },
      { name: 'Conventional', value: 'conventional' },
    ]
  }
  loadEarnest() {
    this.earnestMoneyDispositions = [
      { name: 'Forfeit', value: 'forfeit' },
      { name: 'Refund', value: 'refund' },
    ]
  }
  loadTypesBuyer() {
    this.typesBuyer = [
      { name: 'Investor', value: 'investor' },
      { name: 'Owner occupant', value: 'owner_occupant' },
    ]
  }

  loadClosing(devalFileNumber) {
    setTimeout(() => {
      this.closingService.getClosing(devalFileNumber).subscribe(res => {
        this.closing = {};
        if (res) {
          this.closing = res;
          this.extensionClosing = {};
          this.extensionClosings = [];
          this.extensionClosingsBack = [];
          this.extensionClosings = this.closing.closingInformation.closingInformationExtentionInformationDTO;
          this.extensionClosingsBack = this.extensionClosings;
          this.buyerInformations = [];
          this.buyerInformations = this.closing.buyerInformation;
          this.datepipe = new DatePipe('en-US');
          this.closing.hudContractDate = (res.hudContractDate != null ? new Date(this.datepipe.transform(res.hudContractDate, 'MM/dd/yyyy')) : null);
          this.closing.contractDate = (res.contractDate != null ? new Date(this.datepipe.transform(res.contractDate, 'MM/dd/yyyy')) : null);
          this.closing.postClosingInformation.uploadPackageDate = (res.postClosingInformation.uploadPackageDate != null ? new Date(this.datepipe.transform(res.postClosingInformation.uploadPackageDate, 'MM/dd/yyyy')) : null);
          this.closing.closingInformation.receivedPachageFromLender = (res.closingInformation.receivedPachageFromLender != null ? new Date(this.datepipe.transform(res.closingInformation.receivedPachageFromLender, 'MM/dd/yyyy')) : null);
          this.closing.preClosingInformation.dateEarnestMoneyReceived = (res.preClosingInformation.dateEarnestMoneyReceived != null ? new Date(this.datepipe.transform(res.preClosingInformation.dateEarnestMoneyReceived, 'MM/dd/yyyy')) : null);
          this.closing.closingInformation.scheduleClosingDate = (res.closingInformation.scheduleClosingDate != null ? new Date(this.datepipe.transform(res.closingInformation.scheduleClosingDate, 'MM/dd/yyyy')) : null);
          this.closing.closingInformation.actualClosingDate = (res.closingInformation.actualClosingDate != null ? new Date(this.datepipe.transform(res.closingInformation.actualClosingDate, 'MM/dd/yyyy')) : null);
          this.closing.closingInformation.closingCancelledDate = (res.closingInformation.closingCancelledDate != null ? new Date(this.datepipe.transform(res.closingInformation.closingCancelledDate, 'MM/dd/yyyy')) : null);
          this.closing.postClosingInformation.deedDate = (res.postClosingInformation.deedDate != null ? new Date(this.datepipe.transform(res.postClosingInformation.deedDate, 'MM/dd/yyyy')) : null);
          this.changeMethod();

          if ((res.closingInformation.closingCancelledDate != null || res.closingInformation.actualClosingDate != null) && (this.isAdmin != true)) {
            this.isEdit = false;
            this.isView = true;
          }
          this.isLoad = true;
        }
      })
    }, 1000);
  }
  loadBanks() {
    setTimeout(() => {
      this.bankService.getBanks().subscribe(res => {
        this.banks = [];
        if (res) {
          this.banks = res;
        }
      })
    }, 1000);
  }
  loadStates() {
    setTimeout(() => {
      this.stateService.getStates().subscribe(res => {
        this.states = [];
        if (res) {
          this.states = res;
          this.closing.propertyInformation.state = this.states.filter(val => val.stateId == "PR")[0];
        }
      })
    }, 1000);
  }

  validarIsAdmin() {
    if (this.keycloakService.isUserInRole("closingAdmin") || this.keycloakService.isUserInRole("closingSuperUser")
    ) {
      this.isAdmin = true;
    }
    else {
      this.isAdmin = false;
    }
  }

  loadContractors() {
    setTimeout(() => {
      this.contractorService.getContractors().subscribe(res => {
        this.contractors = [];
        if (res) {
          this.contractors = res;
        }
      })
    }, 1000);
  }

  loadNotaries() {
    setTimeout(() => {
      this.notaryService.getNotaries().subscribe(res => {
        this.notaries = [];
        if (res) {
          this.notaries = res;
        }
      })
    }, 1000);
  }

  loadListingAgents() {
    setTimeout(() => {
      this.listingAgentService.getListingAgents().subscribe(res => {
        this.listingAgents = [];
        if (res) {
          this.listingAgents = res;
        }
      })
    }, 1000);
  }

  loadSellingAgents() {
    setTimeout(() => {
      this.sellingAgentService.getSellingAgents().subscribe(res => {
        this.sellingAgents = [];
        if (res) {
          this.sellingAgents = res;
        }
      })
    }, 1000);
  }

  loadClosingProcessors() {
    setTimeout(() => {
      this.closingProcessorService.getClosingProcessors().subscribe(res => {
        this.closingProcessors = [];
        if (res) {
          this.closingProcessors = res;
        }
      })
    }, 1000);
  }

  deleteExtensionClosing(extensionClosing2: extensionClosing) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.extensionClosings = this.extensionClosings.filter(val => val.extentionClosingDate !== extensionClosing2.extentionClosingDate);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Extension Closing Deleted', life: 3000 });
      }
    });
  }

  deleteSelectedExtensionClosings() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Extension Closings',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.extensionClosings = this.extensionClosings.filter(val => !this.selectedExtensionClosings.includes(val));
        this.selectedExtensionClosings = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'ExtensionClosings Deleted', life: 3000 });
      }
    });
  }

  public openDialog() {
    this.extensionClosing = {};
    this.dialog = true;
  }

  public openDialogBuyer() {
    this.buyerInformation = {};
    this.buyerInformation.state = this.states.filter(val => val.stateId == "PR")[0];
    this.dialogBuyer = true;
  }

  hideDialog() {
    this.dialog = false;
    this.editExtension = false;

  }

  hideDialogBuyer() {
    this.dialogBuyer = false;
    this.editBuyer = false;

  }

  saveExtensionClosing() {
    this.submitted = true;

    if (this.editExtension) {
      this.extensionClosings[this.findIndexByIdExtension(this.extensionClosing.id)] = this.extensionClosing;
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Extension Closing Update',
        life: 3000,
      });
    } else {
      this.extensionClosing.id = this.extensionClosings.length + 1;
      this.extensionClosings.push(this.extensionClosing);
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Extension Closing Create',
        life: 3000,
      });
    }

    this.extensionClosings = [...this.extensionClosings];
    this.dialog = false;
    this.editExtension = false;

  }

  onSubmit() {
    var error = false;

    var errorMessage = "Error";

    if (this.extensionClosings) {
      this.closing.closingInformation.closingInformationExtentionInformationDTO = this.extensionClosings;
    }
    if (this.buyerInformations) {
      this.closing.buyerInformation = this.buyerInformations;
    }
    if (!this.closing.fha) {
      error = true;
      errorMessage = "FHA is required";
    } else if (this.closing.buyerInformation.length <= 0) {
      error = true;
      errorMessage = "Buyer Information is required";
    } else if (!this.closing.devalFileNumber) {
      error = true;
      errorMessage = "Deval File Number is required";
    } else if (!this.closing.closingProcessor) {
      error = true;
      errorMessage = "Closing Processor is required";
    } else if (!this.closing.contractDate) {
      error = true;
      errorMessage = "Contract Date is required";
    } else if (!this.closing.propertyInformation.propertyAddress) {
      error = true;
      errorMessage = "Property Address is required";
    } else if (!this.closing.propertyInformation.city) {
      error = true;
      errorMessage = "City is required";
    } else if (!this.closing.propertyInformation.state.stateId) {
      error = true;
      errorMessage = "State is required";
    } else if (!this.closing.propertyInformation.zipCode) {
      error = true;
      errorMessage = "ZipCode is required";
    } else if (!this.closing.preClosingInformation.saleAmount) {
      error = true;
      errorMessage = "Sale Amount is required";
    } else if (!this.closing.preClosingInformation.methodOfSale) {
      error = true;
      errorMessage = "Method Of Sale is required";
    } else if (!this.closing.preClosingInformation.amContractor.contractorId) {
      error = true;
      errorMessage = "AM Contractor is required";
    }
    /** Setear el Status segun la fehca ingresada */
    if (this.closing.closingInformation.actualClosingDate) {
      this.closing.status = 'Closed';
    } else if (this.closing.closingInformation.closingCancelledDate) {
      this.closing.status = 'Cancelled';
    }
    else {
      this.closing.status = 'Open';
    }

    if (!error) {
      if (this.isEdit) {
        this.closingService.updateClosings(this.closing).subscribe(_res => {
          this.ref.close(true);
        }, err => {
          this.messageAlert('error', 'Error', err.error.message);
        })
      } else {
        this.closingService.addClosings(this.closing).subscribe(_res => {
          this.messageAlert('success', 'Successful', 'Closing is ADD');
          this.ref.close(true);
        }, err => {
          this.messageAlert('error', 'Error', err.error.message);
        })
      }
    } else {
      this.messageAlert('error', 'Error', errorMessage);
    }
  }

  messageAlert(severity: string, summary: string, detail: string) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  }

  Cancel() {
    this.ref.close();
  }

  changeMethod() {
    if (this.closing.preClosingInformation.methodOfSale == 'fha' || this.closing.preClosingInformation.methodOfSale == 'conventional' ) {
      this.lenderDisabled = false;
    } else {
      this.closing.preClosingInformation.lenderBank = {};
      this.lenderDisabled = true;
    }
  }

  changeTab(e) {
    var n = e.index;

    console.warn(n);
    switch (n) {
      case 0:
        this.isContactInformation = false;
        break;
      case 1:
        this.isContactInformation = true;
        break;
      case 2:
        this.isContactInformation = true;
        break;
      case 3:
        this.isContactInformation = true;
        break;
      case 4:
        this.isContactInformation = true;
        break;
      default:
        this.isContactInformation = true;
    }


  }

  onKeydownLettersAndNumbers(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 90) || (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode == 8)) {
      return true;
    } else {
      return false;
    }
  }
  onKeydownLettersAndNumbersAndGuion(event) {
    console.log(event.keyCode);
    if ((event.keyCode >= 48 && event.keyCode <= 90) || (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode == 8) || (event.keyCode == 189 || event.keyCode == 109)) {
      return true;
    } else {
      return false;
    }
  }
  onKeydownLetters(event) {
    if ((event.keyCode >= 58 && event.keyCode <= 90) || (event.keyCode == 8)) {
      return true;
    } else {
      return false;
    }
  }
  onKeydownLettersSpacing(event) {
    if ((event.keyCode >= 58 && event.keyCode <= 90) || (event.keyCode == 8) || (event.keyCode == 32)) {
      return true;
    } else {
      return false;
    }
  }
  onKeydownNumbers(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode == 8)) {
      return true;
    } else {
      return false;
    }
  }
  onKeydownNumbersAndGuion(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode == 8) || (event.keyCode == 189 || event.keyCode == 109)) {
      return true;
    } else {
      return false;
    }
  }
  addFormatDevalFileNumber() {
    let shorten_D = this.closing.devalFileNumber ? this.closing.devalFileNumber.substring(0, 1) : '';
    let shorten_ = this.closing.devalFileNumber ? this.closing.devalFileNumber.substring(1, 2) : '';
    if (shorten_D != "D" && (shorten_ != "-")) {
      this.closing.devalFileNumber = "D-" + (this.closing.devalFileNumber ? this.closing.devalFileNumber : '');
    } else if (shorten_D == "D" && (shorten_ != "-")) {
      this.closing.devalFileNumber = "D-" + (this.closing.devalFileNumber ? this.closing.devalFileNumber.substring(1) : '');
    }
  }
  deleteDevalNumber() {
    this.closing.devalFileNumber = '';
  }

  deleteBuyerInformation(buyerInformation2: BuyerInformationElement) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete Buyer Information ',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.buyerInformations = this.buyerInformations.filter(val => val.id !== buyerInformation2.id);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Buyer Information Deleted', life: 3000 });
      }
    });
  }

  editBuyerInformation(buyerInformation: BuyerInformationElement) {
    this.buyerInformation = { ...buyerInformation };
    this.dialogBuyer = true;
    this.editBuyer = true;
  }

  editExtensionClosing(extensionClosing2: extensionClosing) {
    this.extensionClosing = { ...extensionClosing2 };
    this.dialog = true;
    this.editExtension = true;
  }


  deleteSelectedBuyerInformations() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Buyer Information',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.buyerInformations = this.buyerInformations.filter(val => !this.selectedBuyerInformations.includes(val));
        this.selectedBuyerInformations = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'BuyerInformations Deleted', life: 3000 });
      }
    });
  }
  saveBuyerInformation() {
    var error = false;
    var errorMessage = "";

    if (!this.buyerInformation.typeOfBuyer) {
      error = true;
      errorMessage = "Type Of Buyer is required";
    } else if (!this.buyerInformation.name) {
      error = true;
      errorMessage = "Name is required";
    } else if (!this.buyerInformation.lastName) {
      error = true;
      errorMessage = "Last Name is required";
    }
    if (!error) {
      if (this.editBuyer) {
        this.buyerInformations[this.findIndexById(this.buyerInformation.id)] = this.buyerInformation;
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Buyer information Update',
          life: 3000,
        });
      } else {
        this.buyerInformation.id = this.buyerInformations.length + 1;
        this.buyerInformations.push(this.buyerInformation);
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Buyer Information Create',
          life: 3000,
        });
      }

      this.buyerInformations = [...this.buyerInformations];
      this.dialogBuyer = false;
      this.editBuyer = false;
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
        life: 3000,
      });
    }
  }
  findIndexById(id: number): number {
    let index = -1;
    for (let i = 0; i < this.buyerInformations.length; i++) {
      if (this.buyerInformations[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  findIndexByIdExtension(id: number): number {
    let index = -1;
    for (let i = 0; i < this.extensionClosings.length; i++) {
      if (this.extensionClosings[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  validateZipCode() {
    var zipCodeBuyer = (<HTMLInputElement>document.getElementById("zipCodeBuyer")).value;
    var zipCodeProperty = (<HTMLInputElement>document.getElementById("zipCodeProperty")).value;

    if (zipCodeBuyer.length <= 4 || zipCodeProperty.length <= 4) {
      this.minValueZipCode = true;
    }
    else {
      this.minValueZipCode = false;
    }
  }

  changeActual() {
    console.log(this.extensionClosingsBack);
    this.extensionClosings = this.extensionClosingsBack;
  }

  ConfirmCloseClosing() {
    this.isView = false;
    this.confirmationService.confirm({
      message: 'Do you want to save changes?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onSubmit();
      },
      reject: () => {
        this.ref.close();
      }
    });
  }
  closeClosing() {
    this.ref.close();
  }

}
