import { Component, OnInit, NgModule, ViewChild } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BankElement } from 'app/models/bank.model';
import { BankrequestsService } from 'app/services/bankServices/bankrequests.service';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { StateElement } from 'app/models/state.model';
import { RequestsService } from 'app/services/requestServices/requests.service';


import { MessageService, } from "primeng/api";
@Component({
  selector: 'app-bank-modal',
  templateUrl: './bank-modal.component.html',
  styleUrls: ['./bank-modal.component.css']
})

export class BankModalComponent implements OnInit {

  public formBank: UntypedFormGroup;
  public isEdit : any = false;
  public states : StateElement[];

  bank: BankElement;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private formBuilder: UntypedFormBuilder, private bankService: BankrequestsService,
    private StatesService:RequestsService, public messageService: MessageService) {

    

      this.StatesService.getStates().subscribe(res =>{
        if(res){
          this.states = res
        }
      })

    if (config.data) {
      this.bank = (config.data.bank);
    }


  }

  ngOnInit(): void {
  
    

   
    
   
    this.formBank = this.formBuilder.group({
      bankId: ['', [Validators.required, Number]],
      bankName: ['', [Validators.required]],
      bankState: ['', [Validators.required]],
      bankCity: ['', [Validators.required]],
      bankAddress: ['', [Validators.required]],
      active: [true],
      createdByUser: [''],
      createdDate:[ Date],
      lastModifiedDate:[ Date],
      modifiedByUser: [''],
      version:[ 0]
    });
    if (this.bank) {
      this.isEdit = true;
      this.formBank.patchValue(this.bank);
      
    }

  }


  onSubmit() {
    console.log(this.formBank.value)
    if (this.formBank.dirty && this.formBank.valid) {
      if(this.isEdit){
        this.bankService.updateBanks(this.formBank.value).subscribe(
          (apiResp: any) => {
            this.ref.close(true)
          },
          (err: any) => {
  
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Bank not  Edit', life: 3000});
          }

         
        )
      }else{
        this.bankService.addBanks(this.formBank.value).subscribe(
           (apiResp: any) => {
          this.ref.close(true)
        },
        (err: any) => {
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Bank not  Create', life: 3000});

        }
        )
      }
      
    }

  }
  Cancel(){
    this.ref.close()
  }

  
}
