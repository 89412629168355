import { Component, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig, LazyLoadEvent } from 'primeng/api';
import { NotaryInvoiceElement } from 'app/models/notaryInvoice.model';
import { NotaryInvoiceRequestService } from 'app/services/notaryInvoiceServices/notary-invoice-request-service.service';
import { NotaryInvoiceModalComponent } from '../notary-invoice-modal/notary-invoice-modal.component';
import { KeycloakService } from 'keycloak-angular';
import { Apollo, gql } from 'apollo-angular';
import { NotaryInvoiceReportElement } from 'app/models/notaryInvoiceReport.model';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { SearchInvoiceElement } from 'app/models/searchInvoice.model';
import { GenericElement } from 'app/models/generic.model';
import { NotaryInvoiceReportFullElement } from 'app/models/notaryInvoiceReportFull.model';
@Component({
  selector: 'app-notary-invoice',
  templateUrl: './notary-invoice.component.html',
  styleUrls: ['./notary-invoice.component.css'],
  providers: [DialogService, MessageService]
})

export class NotaryInvoiceComponent implements OnInit {

  private lastTableLazyLoadEvent: LazyLoadEvent;

  notaryInvoiceDialog: boolean;
  notaryInvoices: NotaryInvoiceElement[];
  notaryInvoice: NotaryInvoiceElement;
  selectednotaryInvoices: NotaryInvoiceElement[];
  submitted: boolean;
  loading: boolean;
  allRegisters: number;
  cols: any[];
  error: string;
  isView: boolean = false;
  isEdit: boolean = false;
  isAdmin: boolean = false;
  isNotary: boolean = false;
  dialog: boolean = false;
  comment: string = "";
  notaryInvoiceRechazo: NotaryInvoiceElement;
  notaryInvoicesExcel: NotaryInvoiceElement[];
  notaryInvoicesExcelAll: NotaryInvoiceElement[];
  notaryInvoicesReport: NotaryInvoiceReportElement[];
  notaryInvoicesReportAll: NotaryInvoiceReportFullElement[];
  public datepipe: DatePipe;
  dialogSearch: boolean = false;
  searchElement: SearchInvoiceElement = {};
  parameters: GenericElement[];
  selectedParameter: GenericElement = {};
  statuses: GenericElement[];
  selectedStatus: GenericElement = {};
  isSearch: boolean = false;
  first: number = 0;
  constructor(
    public messageService: MessageService,
    public dialogService: DialogService,
    private notaryInvoiceService: NotaryInvoiceRequestService,
    private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService,
    public keycloakService?: KeycloakService,
    public apollo?: Apollo
  ) { }

  ref: DynamicDialogRef;


  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.loading = true;
    this.validarIsAdminOrNotary();
    this.loadParameters();
    this.loadStatuses();
    //this.cargarNotaryInvoices();
  }
  loadStatuses() {
    this.statuses = [
      { name: 'Select Status', value: '' },
      { name: 'Aceptados', value: 'aceptado' },
      { name: 'Rechazados', value: 'rechazado' },
      { name: 'Enviados', value: 'enviado' },
      { name: 'Creados', value: 'creado' },
      { name: 'Todos', value: 'all' }
    ]
  }
  loadParameters() {
    this.parameters = [
      { name: 'Select Filter Type', value: '' },
      { name: 'By R3 File Number', value: '1' },
      { name: 'By Date', value: '2' },
      { name: 'By Contract', value: '3' },
      { name: 'By Status', value: '5' },
      { name: 'All', value: '4' }
    ]
  }
  cleanParameters() {
    this.searchElement = {};
    this.selectedStatus = {};
  }
  cargarNotaryInvoices() {
    this.loading = true;
    this.notaryInvoices = [];
    setTimeout(() => {
      this.notaryInvoiceService.getNotaryInvoices().subscribe(res => {
        this.loading = false;
        if (res) {
          this.notaryInvoices = res;
          this.allRegisters = res.length;
        }
      })
    }, 1000);
  }
  clear(table: Table) {
    this.isSearch = false;
    this.searchElement = {};
    this.first = 0;
    this.lastTableLazyLoadEvent.first = 0;
    this.lastTableLazyLoadEvent.rows = 10;
    this.cargarInvoiceGraphql(this.lastTableLazyLoadEvent);
  }

  validarIsAdminOrNotary() {
    if (this.keycloakService.isUserInRole("closingAdmin") || this.keycloakService.isUserInRole("closingSuperUser")) {
      this.isAdmin = true;
      this.isNotary = false;
    } else if (this.keycloakService.isUserInRole("closingNotary")) {
      this.isAdmin = false;
      this.isNotary = true;
    } else {
      this.isAdmin = false;
      this.isNotary = false;
    }
  }

  createNotaryInvoice() {
    this.ref = this.dialogService.open(NotaryInvoiceModalComponent, {
      header: 'Add Notary Invoice',
      width: '100vw',
      height: '100vh',
      baseZIndex: 10000,
      closable: false
    });

    this.ref.onClose.subscribe((response: boolean) => {
      if (response == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'NotaryInvoice Create ', life: 3000 });
        this.recargar();
      }

    });
  }

  deleteSelectedNotaryInvoice() {

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete selected notary invoices?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        for (let i = 0; i < this.selectednotaryInvoices.length; i++) {

          this.notaryInvoiceService.deleteNotaryInvoice(this.selectednotaryInvoices[i]).subscribe()
        }
        this.recargar();
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'NotaryInvoices Deleted', life: 3000 });
      }
    });
  }


  editNotaryInvoice(notaryInvoice: NotaryInvoiceElement) {
    this.isView = false;
    this.isEdit = true;
    this.ref = this.dialogService.open(NotaryInvoiceModalComponent, {
      header: 'Edit Invoice',
      width: '100vw',
      height: '100vh',
      baseZIndex: 10000,
      closable: false,
      data: { numeroFactura: notaryInvoice.numeroFactura, isView: this.isView, isEdit: this.isEdit }
    });
    this.ref.onClose.subscribe((notaryInvoice: NotaryInvoiceModalComponent) => {
      if (notaryInvoice) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Invoice Edit ', life: 4000 });
        this.recargar();
      }

    });
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  deleteNotaryInvoice(notaryInvoice) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete Invoice #' + notaryInvoice.numeroFactura + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.notaryInvoiceService.deleteNotaryInvoice(notaryInvoice).subscribe((_apiResp: any) => {
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Invoice Deleted', life: 3000 });
          this.recargar();
        },
          (_err: any) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invoice not  Deleted', life: 3000 });

          }
        )


      }
    });
  }
  getStatus(status: string): string {
    if (status == "aceptado") {
      return "success";
    } else if (status == "rechazado") {
      return "danger";
    } else if (status == "creado") {
      return "warning";
    } else if (status == "enviado") {
      return "primary";
    } else {
      return "primary";
    }
  }
  detalleNotaryInvoice(notaryInvoice: NotaryInvoiceElement) {
    this.isView = true;
    this.ref = this.dialogService.open(NotaryInvoiceModalComponent, {
      header: 'View details',
      width: '100vw',
      height: '100vh',
      baseZIndex: 10000,
      data: { numeroFactura: notaryInvoice.numeroFactura, isView: this.isView }
    });
  }

  SendNotaryInvoice(notaryInvoice) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to send  ' + notaryInvoice.numeroFactura + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.notaryInvoiceService.getNotaryInvoice(notaryInvoice.numeroFactura).subscribe(res => {
          this.notaryInvoice = {};
          if (res) {
            this.notaryInvoice = res;
            this.updateInvoiceSend(this.notaryInvoice);
          }
        })
      }
    });
  }
  updateInvoiceSend(notaryInvoice: NotaryInvoiceElement) {
    notaryInvoice.status = "enviado";
    this.notaryInvoiceService.updateNotaryInvoice(notaryInvoice).subscribe(
      (_apiResp: any) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Invoice Send', life: 3000 });
        this.recargar();
      },
      (_err: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invoice not Send', life: 3000 });
      }
    )

  }

  aceptarInvoice(notaryInvoice) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to accept  ' + notaryInvoice.numeroFactura + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.notaryInvoiceService.getNotaryInvoice(notaryInvoice.numeroFactura).subscribe(res => {
          this.notaryInvoice = {};
          if (res) {
            this.notaryInvoice = res;
            this.updateInvoiceAccept(this.notaryInvoice);
          }
        })
      }
    });
  }
  updateInvoiceAccept(notaryInvoice: NotaryInvoiceElement) {
    notaryInvoice.status = "aceptado";
    this.notaryInvoiceService.updateNotaryInvoice(notaryInvoice).subscribe(
      (_apiResp: any) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Invoice Accept', life: 3000 });
        this.recargar();
      },
      (_err: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invoice not Accept', life: 3000 });
      }
    )

  }
  InactivateNotaryInvoice(notaryInvoice) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to inactivate  ' + notaryInvoice.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        notaryInvoice.active = false;
        this.notaryInvoiceService.updateNotaryInvoice(notaryInvoice).subscribe(

          (_apiResp: any) => {
            ;
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Invoice Inactivate', life: 3000 });

          },
          (_err: any) => {

            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invoice not inactivate', life: 3000 });

          }
        )
      }
    });
  }


  cargarInvoiceGraphql(event: LazyLoadEvent) {
   
      this.notaryInvoices = [];
      this.error = "";
      this.loading = true;
      this.lastTableLazyLoadEvent = event;
      let currentPage = event.first / event.rows;
      var nameNotary = ' ';
      if (this.keycloakService.isUserInRole("closingNotary")) {
        nameNotary = `notary:"${this.keycloakService.getUsername()}"`;
      }
      this.apollo
        .query<any>({
          query: gql`
        {
          findAllNotaryInvoicePaginatorSearch(page: ${currentPage}, size: ${event.rows}, searchData:{
            ${nameNotary}
          }){
            totalPages
            totalElements
            last
            numberOfElements
            empty
            first
            content{
              active
              transaccion
              createdDate
              lastModifiedDate
              createdByUser
              modifiedByUser
              version
              numeroFactura
              fecha
              fechaCierre
              facturaA
              contrato
              honorariosNotariales
              status
              resumenGastosDTO{
                notariales
                tarifas
                reembolsables
                total
              }
              gastosRelacionadosDTO{
                presentacionDigital
                planillaCRIM
                planillaTrasladoDominio
                arancelCopiaCertificada
                gastosMiscelaneos
              }
              sellosArancelesAdicionalesDTO{
                presentacionTelematica
                selloAsistenciaLegalAffidavit
              }
              sellosEscrituraCertificadaDTO{
                rentasInternas
                sociedadAsistenciaLegal
                impuestoNotarial
              }
              sellosEscrituraOriginalDTO{
                rentasInternas
                sociedadAsistenciaLegal
                impuestoNotarial
              }
              arancelesDTO{
                comprobantePresentacion
                comprobanteInscripcion
                codigoPolitico
              }
              r3{
                r3FileNumber
                status
                preClosingInformation{
                  voucherAmount
                }
              }
            }
          }
        }
        `
        })
        .subscribe(
          ({ data, loading }) => {
            if (data.findAllNotaryInvoicePaginatorSearch.empty) {
              this.messageService.add({ severity: 'info', summary: 'You have no assigned Invoice' });
              this.notaryInvoice = {};
              this.loading = loading;
            } else {
              this.loading = loading;
              this.notaryInvoices = data.findAllNotaryInvoicePaginatorSearch.content;
              this.allRegisters = data.findAllNotaryInvoicePaginatorSearch.totalElements;

            }
          }
        );
    

  }

  exportExcel() {
    this.notaryInvoiceService.getNotaryInvoices().subscribe(res => {
      this.notaryInvoicesExcel = [];
      if (res) {
        this.notaryInvoicesExcel = res;
        this.formatReport();
      }
    })

  }
  exportExcelAll() {
    this.notaryInvoiceService.getNotaryInvoices().subscribe(res => {
      this.notaryInvoicesExcelAll = [];
      if (res) {
        this.notaryInvoicesExcelAll = res;
        this.formatReportAll();
      }
    })

  }
  public openDialogSearch() {
    this.searchElement = {};
    this.selectedStatus = {};
    this.dialogSearch = true;
  }

  public hideDialogSearch() {
    this.dialogSearch = false;
  }

  clearSearch() {
    this.searchElement = {};
    this.selectedStatus = {};
    this.cargarInvoiceGraphql(this.lastTableLazyLoadEvent);
  }

  formatReport() {
    this.notaryInvoicesReport = [];
    let i = 0;
    this.notaryInvoicesExcel.forEach(element => {
      if (element) {
        if (element.status == "aceptado") {
          this.notaryInvoicesReport[i] = {};
          this.notaryInvoicesReport[i].line_item = (i + 1) + "";
          let array = (element.numeroFactura).split("-", 0);
          console.log(array);
          this.notaryInvoicesReport[i].Application_ID = (element.numeroFactura ? (array.length > 0 ? array[(array.length) - 1] : element.numeroFactura)
            : "");
          if (element.r3) {
            if (element.r3.sellerInformation) {
              this.notaryInvoicesReport[i].Applicant_Name = (element.r3.sellerInformation.fullName ? element.r3.sellerInformation.fullName : "");
            }
            if (element.r3.preClosingInformation) {
              this.notaryInvoicesReport[i].Type_of_Closing = "Non-Traditional";
            }
            this.notaryInvoicesReport[i].Property_Type = (element.r3.typeOfProperty ? element.r3.typeOfProperty : "");
          }
          this.notaryInvoicesReport[i].Notarial_Transaction = element.transaccion;
          if (element.r3) {
            if (element.r3.propertyInformation) {
              this.notaryInvoicesReport[i].Subject_Address = (element.r3.propertyInformation.propertyAddress ? element.r3.propertyInformation.propertyAddress : "");
              if(element.r3.propertyInformation.state){
                this.notaryInvoicesReport[i].State = (element.r3.propertyInformation.state.stateName ? element.r3.propertyInformation.state.stateName : "");
              }
              this.notaryInvoicesReport[i].Municipality = (element.r3.propertyInformation.city ? element.r3.propertyInformation.city : "");
             
              this.notaryInvoicesReport[i].Zip_Code = (element.r3.propertyInformation.zipCode ? element.r3.propertyInformation.zipCode : "");
            
            }
          }
          this.notaryInvoicesReport[i].Date_Closed = (element.fechaCierre ? this.dateToString(element.fechaCierre) : "");
          if (element.r3) {
            if (element.r3.sellerInformation) {
              this.notaryInvoicesReport[i].Transaction_Amount = (element.r3.preClosingInformation.voucherAmount ? element.r3.preClosingInformation.voucherAmount + "" : "");
            }
          }
          this.notaryInvoicesReport[i].H_Partitions_to_Registry_R3_Invoice = "";

          let honorariosNotariales = 0;
          let tarifas = 0;
          let costos = 0;

          if(element.honorariosNotariales > 0){
             honorariosNotariales = ((element.honorariosNotariales * 0.75)/0.5);
          }
          this.notaryInvoicesReport[i].H_Notarial_Deeds_R3_Invoice = (honorariosNotariales ? honorariosNotariales + "" : "");
          if (element.resumenGastosDTO) {
            if(element.resumenGastosDTO.tarifas){
              tarifas = element.resumenGastosDTO.tarifas;
            }
            this.notaryInvoicesReport[i].H_Allowance_Misc_OGPe_Municipality = (tarifas ? tarifas + "" : "");
            if(element.resumenGastosDTO.reembolsables){
              costos = element.resumenGastosDTO.reembolsables;
            }
            this.notaryInvoicesReport[i].H_EC_Allowance_for_Specialized_Services = (costos ? costos + "" : "");
          }
          let total = honorariosNotariales + tarifas + costos;
          this.notaryInvoicesReport[i].Billable_Amount = (total ? total + "" : "");
          i = i + 1
        }
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not found registers', life: 3000 });
      }
    }
    );
    this.createExcel(this.notaryInvoicesReport);

  }
  dateToString(date: Date) {
    if (date) {
      this.datepipe = new DatePipe('en-US');
      var dateNew = this.datepipe.transform(date, 'MM/dd/YYYY');
      return "" + dateNew;
    } else {
      return "";
    }
  }

  formatReportAll() {
    this.notaryInvoicesReportAll = [];
    let i = 0;
    this.notaryInvoicesExcelAll.forEach(element => {
      if (element) {
        this.notaryInvoicesReportAll[i] = {};

        if (element.r3) {
          this.notaryInvoicesReportAll[i].R3_File_number = (element.r3.r3FileNumber ? element.r3.r3FileNumber : "");
        }

        this.notaryInvoicesReportAll[i].Fecha = (element.fecha ? this.dateToString(element.fecha) : "");

        this.notaryInvoicesReportAll[i].Numero_de_Factura = (element.numeroFactura ? element.numeroFactura : "");

        this.notaryInvoicesReportAll[i].Fecha_Cierre = (element.fechaCierre ? this.dateToString(element.fechaCierre) : "");

        this.notaryInvoicesReportAll[i].A = (element.facturaA ? element.facturaA : "");

        this.notaryInvoicesReportAll[i].Contrato = (element.contrato ? element.contrato : "");

        this.notaryInvoicesReportAll[i].Transaccion = (element.transaccion ? element.transaccion : "");

        this.notaryInvoicesReportAll[i].Participante = (element.transaccion ? element.transaccion : "");

        if (element.r3) {
          if (element.r3.sellerInformation) {
            this.notaryInvoicesReportAll[i].Participante = (element.r3.sellerInformation.fullName ? element.r3.sellerInformation.fullName : "");
          }
          if (element.r3.propertyInformation) {
            this.notaryInvoicesReportAll[i].Propiedad = (element.r3.propertyInformation.propertyAddress ? element.r3.propertyInformation.propertyAddress : "");
            
            if(element.r3.propertyInformation.state){
              this.notaryInvoicesReportAll[i].Estado_Propiedad = (element.r3.propertyInformation.state.stateName ? element.r3.propertyInformation.state.stateName : "");
            }
            this.notaryInvoicesReportAll[i].Ciudad = (element.r3.propertyInformation.city ? element.r3.propertyInformation.city : "");
           
            this.notaryInvoicesReportAll[i].ZipCode = (element.r3.propertyInformation.zipCode ? element.r3.propertyInformation.zipCode : "");
          
          }
        }


        if (element.notaryDTO) {
          this.notaryInvoicesReportAll[i].Notario_Asignado = (element.notaryDTO.fullName ? element.notaryDTO.fullName : "");
        }

        if (element.r3) {
          if (element.r3.preClosingInformation) {
            this.notaryInvoicesReportAll[i].Cuantia = (element.r3.preClosingInformation.voucherAmount ? element.r3.preClosingInformation.voucherAmount + "" : "");
          }
          if (element.r3.typeOfProperty == "damage") {
            this.notaryInvoicesReportAll[i].Tipo = "Storm Damaged Property";
          } else if (element.r3.typeOfProperty == "replacement") {
            this.notaryInvoicesReportAll[i].Tipo = "Relocation Property"
          } else {
            this.notaryInvoicesReportAll[i].Tipo = "";
          }
        }

        this.notaryInvoicesReportAll[i].Honorarios_Notariales = (element.honorariosNotariales ? element.honorariosNotariales + "" : "");

        this.notaryInvoicesReportAll[i].Honorarios_Notariales = (element.honorariosNotariales ? element.honorariosNotariales + "" : "");

        if (element.arancelesDTO) {

          this.notaryInvoicesReportAll[i].Comprobante_Presentacion = (element.arancelesDTO.comprobantePresentacion ? element.arancelesDTO.comprobantePresentacion + "" : "");

          this.notaryInvoicesReportAll[i].Comprobante_Inscripcion = (element.arancelesDTO.comprobanteInscripcion ? element.arancelesDTO.comprobanteInscripcion + "" : "");

          this.notaryInvoicesReportAll[i].Codigo_Politico = (element.arancelesDTO.codigoPolitico ? element.arancelesDTO.codigoPolitico + "" : "");

        }

        if (element.sellosEscrituraOriginalDTO) {

          this.notaryInvoicesReportAll[i].Rentas_Internas_Original = (element.sellosEscrituraOriginalDTO.rentasInternas ? element.sellosEscrituraOriginalDTO.rentasInternas + "" : "");

          this.notaryInvoicesReportAll[i].Sociedad_para_la_Asistencia_Legal_Original = (element.sellosEscrituraOriginalDTO.sociedadAsistenciaLegal ? element.sellosEscrituraOriginalDTO.sociedadAsistenciaLegal + "" : "");

          this.notaryInvoicesReportAll[i].Impuesto_Notarial_Original = (element.sellosEscrituraOriginalDTO.impuestoNotarial ? element.sellosEscrituraOriginalDTO.impuestoNotarial + "" : "");

        }

        if (element.sellosEscrituraCertificadaDTO) {

          this.notaryInvoicesReportAll[i].Rentas_Internas_Certificada = (element.sellosEscrituraCertificadaDTO.rentasInternas ? element.sellosEscrituraCertificadaDTO.rentasInternas + "" : "");

          this.notaryInvoicesReportAll[i].Sociedad_para_la_Asistencia_Legal_Certificada = (element.sellosEscrituraCertificadaDTO.sociedadAsistenciaLegal ? element.sellosEscrituraCertificadaDTO.sociedadAsistenciaLegal + "" : "");

          this.notaryInvoicesReportAll[i].Impuesto_Notarial_Certificada = (element.sellosEscrituraCertificadaDTO.impuestoNotarial ? element.sellosEscrituraCertificadaDTO.impuestoNotarial + "" : "");

        }


        if (element.sellosArancelesAdicionalesDTO) {

          this.notaryInvoicesReportAll[i].Presentacion_Telematica = (element.sellosArancelesAdicionalesDTO.presentacionTelematica ? element.sellosArancelesAdicionalesDTO.presentacionTelematica + "" : "");

          this.notaryInvoicesReportAll[i].Sello_Asistencia_Legal_Affidavit = (element.sellosArancelesAdicionalesDTO.selloAsistenciaLegalAffidavit ? element.sellosArancelesAdicionalesDTO.selloAsistenciaLegalAffidavit + "" : "");

        }

        if (element.gastosRelacionadosDTO) {

          this.notaryInvoicesReportAll[i].Presentacion_Digital = (element.gastosRelacionadosDTO.presentacionDigital ? element.gastosRelacionadosDTO.presentacionDigital + "" : "");

          this.notaryInvoicesReportAll[i].Planilla_CRIM = (element.gastosRelacionadosDTO.planillaCRIM ? element.gastosRelacionadosDTO.planillaCRIM + "" : "");

          this.notaryInvoicesReportAll[i].Planilla_Traslado_de_Dominio = (element.gastosRelacionadosDTO.planillaTrasladoDominio ? element.gastosRelacionadosDTO.planillaTrasladoDominio + "" : "");

          this.notaryInvoicesReportAll[i].Arancel_Copia_Certificada = (element.gastosRelacionadosDTO.arancelCopiaCertificada ? element.gastosRelacionadosDTO.arancelCopiaCertificada + "" : "");

          this.notaryInvoicesReportAll[i].Gastos_Miscelaneos = (element.gastosRelacionadosDTO.gastosMiscelaneos ? element.gastosRelacionadosDTO.gastosMiscelaneos + "" : "");

        }
        if (element.resumenGastosDTO) {

          this.notaryInvoicesReportAll[i].Notariales = (element.resumenGastosDTO.notariales ? element.resumenGastosDTO.notariales + "" : "");

          this.notaryInvoicesReportAll[i].Tarifas = (element.resumenGastosDTO.tarifas ? element.resumenGastosDTO.tarifas + "" : "");

          this.notaryInvoicesReportAll[i].Reembolsables = (element.resumenGastosDTO.reembolsables ? element.resumenGastosDTO.reembolsables + "" : "");

          this.notaryInvoicesReportAll[i].Total = (element.resumenGastosDTO.total ? element.resumenGastosDTO.total + "" : "");

         
        }


        if (element.status == "aceptado") {
          this.notaryInvoicesReportAll[i].Estado = "ACEPTADO";
          this.notaryInvoicesReportAll[i].Comentario_Rechazo = "";
        } else if (element.status == "rechazado") {
          this.notaryInvoicesReportAll[i].Estado = "RECHAZADO";
          this.notaryInvoicesReportAll[i].Comentario_Rechazo = (element.comentariosRechazo ? element.comentariosRechazo : "");
        } else if (element.status == "creado") {
          this.notaryInvoicesReportAll[i].Estado = "CREADO";
          this.notaryInvoicesReportAll[i].Comentario_Rechazo = "";
        } else if (element.status == "enviado") {
          this.notaryInvoicesReportAll[i].Estado = "ENVIADO";
          this.notaryInvoicesReportAll[i].Comentario_Rechazo = "";
        } else {
          this.notaryInvoicesReportAll[i].Estado = "";
          this.notaryInvoicesReportAll[i].Comentario_Rechazo = "";
        }
        i = i + 1;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not found registers', life: 3000 });
      }
    });
    this.createExcel(this.notaryInvoicesReportAll);

  }
  createExcel(objeto: any) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(objeto);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Invoices");
    });

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  estadoCreado(status: string) {
    if (status == "creado") {
      return true;
    } else {
      return false;
    }
  }
  estadoRechazado(status: string) {
    if (status == "rechazado") {
      return true;
    } else {
      return false;
    }
  }
  estadoAceptado(status: string) {
    if (status == "aceptado") {
      return true;
    } else {
      return false;
    }
  }
  estadoEnviado(status: string) {
    if (status == "enviado") {
      return true;
    } else {
      return false;
    }
  }
  hideDialog() {
    this.dialog = false;
    this.comment = "";
    this.notaryInvoiceRechazo = {};
  }
  openDialog(notaryElement: NotaryInvoiceElement) {
    this.notaryInvoiceRechazo = {};
    this.notaryInvoiceRechazo = notaryElement;
    this.dialog = true;
  }

  rechazar() {
    if (this.comment) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to decline  ' + this.notaryInvoiceRechazo.numeroFactura + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.notaryInvoiceService.getNotaryInvoice(this.notaryInvoiceRechazo.numeroFactura).subscribe(res => {
            this.notaryInvoice = {};
            if (res) {
              this.notaryInvoice = res;
              this.updateInvoiceDecline(this.notaryInvoice);
            }
          })
        }
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'Comment is required', life: 3000 });
    }
  }




  updateInvoiceDecline(notaryInvoice: NotaryInvoiceElement) {
    notaryInvoice.status = "rechazado";
    notaryInvoice.comentariosRechazo = this.comment;
    this.notaryInvoiceService.updateNotaryInvoice(notaryInvoice).subscribe(
      (_apiResp: any) => {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'NotaryInvoice decline', life: 3000 });
        this.recargar();
      },
      (_err: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'NotaryInvoice not decline', life: 3000 });
      }
    )
  }

  recargar() {
    this.loading = true;
    this.notaryInvoices = [];
    let currentPage = this.lastTableLazyLoadEvent.first / this.lastTableLazyLoadEvent.rows;
    let nameNotary = ' ';
    if (this.keycloakService.isUserInRole("closingNotary")) {
      nameNotary = `notary:"${this.keycloakService.getUsername()}"`;
    }
    this.apollo
      .query<any>({
        query: gql`
          {
            findAllNotaryInvoicePaginatorSearch(page: ${currentPage}, size: ${this.lastTableLazyLoadEvent.rows}, searchData:{
              ${nameNotary}
            }){
              totalPages
              totalElements
              last
              numberOfElements
              empty
              first
              content{
                active
                transaccion
                createdDate
                lastModifiedDate
                createdByUser
                modifiedByUser
                version
                numeroFactura
                fecha
                fechaCierre
                facturaA
                contrato
                honorariosNotariales
                status
                resumenGastosDTO{
                  notariales
                  tarifas
                  reembolsables
                  total
                }
                gastosRelacionadosDTO{
                  presentacionDigital
                  planillaCRIM
                  planillaTrasladoDominio
                  arancelCopiaCertificada
                  gastosMiscelaneos
                }
                sellosArancelesAdicionalesDTO{
                  presentacionTelematica
                  selloAsistenciaLegalAffidavit
                }
                sellosEscrituraCertificadaDTO{
                  rentasInternas
                  sociedadAsistenciaLegal
                  impuestoNotarial
                }
                sellosEscrituraOriginalDTO{
                  rentasInternas
                  sociedadAsistenciaLegal
                  impuestoNotarial
                }
                arancelesDTO{
                  comprobantePresentacion
                  comprobanteInscripcion
                  codigoPolitico
                }
                r3{
                  r3FileNumber
                  status
                  preClosingInformation{
                    voucherAmount
                  }
                }
              }
            }
          }
          `
      })
      .subscribe(
        ({ data, loading }) => {
          if (data.findAllNotaryInvoicePaginatorSearch.empty) {
            this.messageService.add({ severity: 'info', summary: 'You have no assigned Invoice' });
            this.notaryInvoice = {};
            this.loading = loading;
          } else {
            this.loading = loading;
            console.log(data.findAllNotaryInvoicePaginatorSearch.content);
            this.notaryInvoices = data.findAllNotaryInvoicePaginatorSearch.content;
            this.allRegisters = data.findAllNotaryInvoicePaginatorSearch.totalElements;

          }
        }
      );
    this.loading = false;
  }
  searchFilter(event?: LazyLoadEvent) {
    this.isSearch = true;
    this.loading = true;
    let currentPage;
    let size;
    if (event) {
      currentPage = event.first / event.rows;
      this.first = event.first;
      size = event.rows;
    } else {
      this.first = 0;
      size = 10;
      currentPage = this.first / size;  
    }
    let nameNotary = ' ';
    if (this.keycloakService.isUserInRole("closingNotary")) {
      nameNotary = `notary:"${this.keycloakService.getUsername()}"`;
    }
    let inputR3FileNumber = (this.searchElement.r3FileNumber ? `r3FileNumber: "${this.searchElement.r3FileNumber}",` : ' ');
    if (this.searchElement.fecha) {
      var date = new Date(this.searchElement.fecha.getTime() - this.searchElement.fecha.getTimezoneOffset() * 60000).toISOString();
    }
    let inputDate = (this.searchElement.fecha ? `fecha:"${date}",` : ' ');
    let inputContrato = (this.searchElement.contrato ? `contrato: "${this.searchElement.contrato}",` : ' ');

    let inputStatus = (this.selectedStatus ? (this.selectedStatus.value ? `status: "${this.selectedStatus.value}",` : ' ') : ' ');

    if (inputR3FileNumber || inputStatus || inputDate || inputContrato || nameNotary) {
      this.apollo
        .query<any>({
          query: gql`
          {
            findAllNotaryInvoicePaginatorSearch(page: ${currentPage}, size: ${size}, searchData:{
              ${inputR3FileNumber}
              ${inputDate}
              ${inputContrato}
              ${inputStatus}
              ${nameNotary}
            }){
              totalPages
              totalElements
              last
              numberOfElements
              empty
              first
              content{
                active
                transaccion
                createdDate
                lastModifiedDate
                createdByUser
                modifiedByUser
                version
                numeroFactura
                fecha
                fechaCierre
                facturaA
                contrato
                honorariosNotariales
                status
                resumenGastosDTO{
                  notariales
                  tarifas
                  reembolsables
                  total
                }
                gastosRelacionadosDTO{
                  presentacionDigital
                  planillaCRIM
                  planillaTrasladoDominio
                  arancelCopiaCertificada
                  gastosMiscelaneos
                }
                sellosArancelesAdicionalesDTO{
                  presentacionTelematica
                  selloAsistenciaLegalAffidavit
                }
                sellosEscrituraCertificadaDTO{
                  rentasInternas
                  sociedadAsistenciaLegal
                  impuestoNotarial
                }
                sellosEscrituraOriginalDTO{
                  rentasInternas
                  sociedadAsistenciaLegal
                  impuestoNotarial
                }
                arancelesDTO{
                  comprobantePresentacion
                  comprobanteInscripcion
                  codigoPolitico
                }
                r3{
                  r3FileNumber
                  status
                  preClosingInformation{
                    voucherAmount
                  }
                }
              }
            }
          }
          `
        })
        .subscribe(
          ({ data, loading }) => {
            if (data.findAllNotaryInvoicePaginatorSearch.empty) {
              this.dialogSearch = false;
              this.messageService.add({ severity: 'info', summary: 'You have no assigned Invoice' });
              this.notaryInvoices = [];
              this.allRegisters = 0;
              this.loading = loading;
            } else {
              this.loading = loading;
              this.dialogSearch = false;
              this.notaryInvoices = data.findAllNotaryInvoicePaginatorSearch.content;
              this.allRegisters = data.findAllNotaryInvoicePaginatorSearch.totalElements;

            }
          }
        );
    } else {
      this.dialogSearch = true;
      this.messageService.add({ severity: 'info', summary: 'Please add a filter to search' });
    }
    this.loading = false;
  }

  
  onLazyLoad(event: LazyLoadEvent) {
    if (this.isSearch) {
      this.searchFilter(event);
    } else {
      this.cargarInvoiceGraphql(event);
    }
  }
}