import { Component, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig, LazyLoadEvent } from 'primeng/api';
import { ContractorElement } from 'app/models/contractor.model';
import { ContractorRequestServiceService } from 'app/services/contractorServices/contractor-request-service.service';
import { ContractorModalComponent } from '../contractor-modal/contractor-modal.component';

@Component({
  selector: 'app-contractor',
  templateUrl: './contractor.component.html',
  providers: [DialogService, MessageService]
})
export class ContractorComponent implements OnInit {

  private lastTableLazyLoadEvent: LazyLoadEvent;

  contractorDialog: boolean;
  contractors: ContractorElement[];
  contractor: ContractorElement;
  selectedcontractors: ContractorElement[];
  submitted: boolean;
  loading: boolean;
  allRegisters: number;
  cols: any[];

  constructor(
    public messageService: MessageService,
    public dialogService: DialogService,
    private contractorService: ContractorRequestServiceService,
    private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService
  ) { }

  ref: DynamicDialogRef;


  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.loading = true;

  }

  cargarNotaries() {
    this.loading = true;
    this.contractors = []
    setTimeout(() => {
      this.contractorService.getContractors().subscribe(res => {
        this.loading = false;
        if (res) {
          this.contractors = res;
          this.allRegisters = res.length;
        }
      })
    }, 1000);
  }
  clear(table: Table) {
    table.clear();

  }

  createContractor() {
    this.ref = this.dialogService.open(ContractorModalComponent, {
      header: 'Add Asset Manager',
      width: '70%',
      contentStyle: { "max-height": "500px", "overflow": "auto" },
      baseZIndex: 10000
    });

    this.ref.onClose.subscribe((response: boolean) => {
      if (response == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Asset Manager Create ', life: 3000 });
        this.loadContractors(this.lastTableLazyLoadEvent);

      }

    });
  }

  deleteSelectedContractor() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected contractors?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        console.log(this.selectedcontractors)
        for (let i = 0; i < this.selectedcontractors.length; i++) {
          this.contractorService.deleteContractor(this.selectedcontractors[i]).subscribe();
        }
        this.loadContractors(this.lastTableLazyLoadEvent);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Asset Manager Deleted', life: 3000 });
      }
    });
  }

  editContractor(contractor) {
    this.contractor = contractor;
    this.ref = this.dialogService.open(ContractorModalComponent, {
      header: 'Edit Contractor',
      width: '70%',
      contentStyle: { "max-height": "500px", "overflow": "auto" },
      baseZIndex: 10000,
      data: { contractor: this.contractor }
    });
    this.ref.onClose.subscribe((response: boolean) => {
      if (response == true) {
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Asset Manager Edit ' + contractor.name, life: 3000 });
        this.loadContractors(this.lastTableLazyLoadEvent);
      }

    });
  }

  deleteContractor(contractor) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + contractor.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        contractor.active = false;
        this.contractorService.updateContractor(contractor).subscribe((_apiResp: any) => {

          this.loadContractors(this.lastTableLazyLoadEvent);
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Asset Manager Deleted', life: 3000 });
        },
          (_err: any) => {

            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Asset Manager not  Deleted', life: 3000 });

          }
        )
      }
    });
  }

  detailNotay(contractor) {
    this.ref = this.dialogService.open(ContractorModalComponent, {
      header: 'View details',
      width: '70%',
      contentStyle: { "max-height": "500px", "overflow": "auto" },
      baseZIndex: 10000,
      data: { contractor: contractor }
    });
  }



  InactivateContractor(contractor) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to inactivate  ' + contractor.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        contractor.active = false;
        this.contractorService.updateContractor(contractor).subscribe(
          (_apiResp: any) => {
            this.loadContractors(this.lastTableLazyLoadEvent);

            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Asset Manager Inactivate', life: 3000 });
          },
          (_err: any) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Asset Manager not inactivate', life: 3000 });

          }
        )
      }
    });
  }


  ActivateContractor(bank) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to activate  ' + bank.bankName + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        bank.active = true;
        this.contractorService.updateContractor(bank).subscribe(
          (_apiResp: any) => {
            this.loadContractors(this.lastTableLazyLoadEvent);

            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Asset Manager activate', life: 3000 });

          },
          (_err: any) => {

            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Asset Manager not activate', life: 3000 });

          }
        )
      }
    });
  }

  loadContractors(event: LazyLoadEvent) {
    this.loading = true;
    this.lastTableLazyLoadEvent = event;
    let currentPage = event.first / event.rows;
    setTimeout(() => {
      this.contractors = [];
      this.contractorService.getContractorPaginator(currentPage, event.rows).subscribe(res => {
        this.loading = false;
        if (res) {
          this.contractors = res.content;
          this.allRegisters = res.totalElements;
        }
      })
    }, 1000);
  }

}
