import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-states-list',
  templateUrl: './states-list.component.html',
  styleUrls: ['../app.component.scss']
})


export class StatesListComponent implements OnInit {

  title = 'http-get';
  url: string = 'https://microservices.firefly-intranet.com/closing-api/states/';
  error={};
  stateId = [];
  stateName = [];
  
  ngOnInit() {   // <---
   
  }
  send (){
    
  }

}
