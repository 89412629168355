import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClosingComponent } from './closing/closing.component';
import { BanksComponent } from './banks/banks.component';
import { NotaryComponent } from './notary/notary.component';
import { ContractorComponent } from './contractor/contractor.component';
import { AppMainComponent } from './layout/main/app.main.component';
import { StatesComponent } from './states/states.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardR3Component } from './dashboardR3/dashboardR3.component';
import { SellingAgentComponent } from './selling-agent/selling-agent.component';
import { ListingAgentComponent } from './listing-agent/listing-agent.component';
import { NotaryInvoiceComponent } from './notary-invoice/notary-invoice.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppMainComponent,
          children: [
            { path: "closing", component: ClosingComponent, pathMatch: "full" },
            { path: "R3", component: DashboardR3Component, pathMatch: "full" },
            { path: "", component: DashboardComponent, pathMatch: "full"},
            { path: "banks", component: BanksComponent, pathMatch: "full" },
            { path: "notary", component: NotaryComponent, pathMatch: "full" },
            { path: "contractor", component: ContractorComponent, pathMatch: "full" },
            { path: "sellingAgent", component: SellingAgentComponent, pathMatch: "full" },
            { path: "listingAgent", component: ListingAgentComponent, pathMatch: "full" },
            { path: "notaryInvoice", component: NotaryInvoiceComponent, pathMatch: "full" },
            { path: "states", component: StatesComponent, pathMatch: "full" }
          ]
        }
      ])
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
