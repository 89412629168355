import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ListingAgentServicesService } from 'app/services/listingAgentServices/listing-agent-services.service';
import { ListingAgentElement } from 'app/models/listingAgent.model';
import { MessageService, } from "primeng/api";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: 'app-listing-agent-modal',
  templateUrl: './listing-agent-modal.component.html',
})
export class ListingAgentModalComponent implements OnInit {
  public formListingAgent: UntypedFormGroup;
  public isEdit: boolean = false;
  listingAgent: ListingAgentElement;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private formBuilder: UntypedFormBuilder, private listingAgentService: ListingAgentServicesService,
    public messageService: MessageService) {
    if (config.data) {
      this.listingAgent = (config.data.listingAgent);
    }
  }

  ngOnInit(): void {
    this.formListingAgent = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      active: [true],
      createdByUser: [''],
      fullName: [''],
      userName: [''],
      name: [''],
      createdDate: [Date],
      lastModifiedDate: [Date],
      modifiedByUser: [''],
      version: [0]
    });
    if (this.listingAgent) {
      this.isEdit = true;
      this.formListingAgent.patchValue(this.listingAgent);
    }
  }

  Cancel() {
    this.ref.close()
  }

  onSubmit() {
    if (this.formListingAgent.dirty && this.formListingAgent.valid) {
      this.formListingAgent.value.fullName = this.formListingAgent.value.firstName +" "+ this.formListingAgent.value.lastName;
      if (this.isEdit) {
        this.listingAgentService.updateListingAgent(this.formListingAgent.value).subscribe(
          (_apiResp: any) => {
            this.ref.close(true)
          },
          (_err: any) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'ListingAgent not  edit', life: 3000 });
          }
        )
      } else {
        this.listingAgentService.addListingAgent(this.formListingAgent.value).subscribe(
          (_apiResp: any) => {
            this.ref.close(true)
          },
          (_err: any) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'ListingAgent not  create', life: 3000 });
          }
        )
      }

    }

  }

}
