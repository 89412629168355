import { Component, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from "primeng/api";
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {MessageService, ConfirmationService} from 'primeng/api'; 
import { StateElement } from 'app/models/state.model';
import { RequestsService } from 'app/services/requestServices/requests.service';
import {StateModalComponent} from '../state-modal/state-modal.component';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.css'],
  providers: [DialogService, MessageService]
})
export class StatesComponent implements OnInit {

  private lastTableLazyLoadEvent: LazyLoadEvent;

  
  stateDialog: boolean;
  states: StateElement[];
  state: StateElement;
  selectedStates: StateElement[];
  submitted: boolean;
  loading: boolean;
  allRegisters: number;
  cols: any[];


  constructor(
    public messageService: MessageService,
    public dialogService: DialogService,
    private statesService: RequestsService,
    private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService
  ) { }


  ref: DynamicDialogRef;

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.loading = true;
    //this.cargarStates();
  }

  cargarStates() {
    this.loading = true;
    this.states = [];
    setTimeout(() => {
      this.statesService.getStates().subscribe(res => {
        this.loading = false;
      
        if(res){
          this.states = res;
          this.allRegisters = res.length;
          
        }
      })
    }, 1000);
  }
  clear(table: Table) {
    table.clear();

}

openDialog(){
  this.ref = this.dialogService.open(StateModalComponent, {
    header: 'Add state',
    width: '70%',
    contentStyle: {"max-height": "500px", "overflow": "auto"},
    baseZIndex: 10000
});

this.ref.onClose.subscribe((response: boolean) =>{
  if (response == true) {
   // this.banks = this.banks.filter(val => val.bankId !== bank.bankId);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'State Create ', life: 3000});
      this.loadStates(this.lastTableLazyLoadEvent);
 
    }
   
});
}


deleteSelectedStates() {
  this.confirmationService.confirm({
    message: 'Are you sure you want to delete the selected states?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      
      for (let i = 0; i < this.selectedStates.length; i++) {
      this.statesService.deleteState(this.selectedStates[i]).subscribe();
    }
    this.loadStates(this.lastTableLazyLoadEvent);
    this.messageService.add({severity:'success', summary: 'Successful', detail: 'States Deleted', life: 3000});
  }
});
}

editState(state){
  this.state = state;
  this.ref = this.dialogService.open(StateModalComponent, {
    header: 'Edit state',
    width: '70%',
    contentStyle: {"max-height": "500px", "overflow": "auto"},
    baseZIndex: 10000,
    data: {state: this.state}
});
this.ref.onClose.subscribe((response: boolean) =>{
  if (response == true) {
   // this.banks = this.banks.filter(val => val.bankId !== bank.bankId);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'State Edit '+ state.stateName, life: 3000});
      this.loadStates(this.lastTableLazyLoadEvent); 
    }
 
});
}

deleteState(state){
  this.confirmationService.confirm({
    message: 'Are you sure you want to delete the state?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      
      this.statesService.deleteState(state).subscribe(
        (apiResp: any) => {
         
          this.loadStates(this.lastTableLazyLoadEvent);
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'State Deleted', life: 3000});
        },
        (err: any) => {
         
          this.messageService.add({severity:'error', summary: 'Error', detail: 'State not  Deleted', life: 3000});
         
        }
      )  }
});
}

detailState(state){
  this.ref = this.dialogService.open(StateModalComponent, {
    header: 'View details',
    width: '70%',
    contentStyle: {"max-height": "500px", "overflow": "auto"},
    baseZIndex: 10000,
    data: {state: state}
});
}


InactivateState(state){
  this.confirmationService.confirm({
    message: 'Are you sure you want to inactivate  ' + state.stateName + '?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      state.active = false;
       this.statesService.updateState(state).subscribe(
         
        (apiResp: any) => {
          this.loadStates(this.lastTableLazyLoadEvent);
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'State Inactivate', life: 3000});
         
        },
        (err: any) => {

          this.messageService.add({severity:'error', summary: 'Error', detail: 'Bank not inactivate', life: 3000});
         
        }
      ) 
    }
});
}


ActivateState(state){
  this.confirmationService.confirm({
    message: 'Are you sure you want to activate  ' + state.stateName + '?',
    header: 'Confirm',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      state.active = true;
      this.statesService.updateState(state).subscribe(
        (apiResp: any) => {
          this.loadStates(this.lastTableLazyLoadEvent);
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'State activate', life: 3000});
         
        },
        (err: any) => {
          
          this.messageService.add({severity:'error', summary: 'Error', detail: 'State not activate', life: 3000});
         
        }
      ) 
    }
});
}



loadStates(event: LazyLoadEvent) {  
  this.loading = true;
  this.lastTableLazyLoadEvent = event;
  let currentPage = event.first / event.rows ;
  setTimeout(() => {
    this.states = [];
    this.statesService.getStatesPaginator(currentPage, event.rows).subscribe(res => {
      this.loading = false;
    
      if(res){
      
        this.states = res.content;
        this.allRegisters = res.totalElements;
        
      }
    })
  }, 1000);
}


}
