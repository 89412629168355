<p-toast></p-toast>
<div class="card">
    <p-table #dt [value]="notaries" [paginator]="true" [rows]="10" [totalRecords]="allRegisters" [loading]="loading"
        [(selection)]="selectednotaries" [lazy]="true" (onLazyLoad)="loadNotaries($event)" 
        [paginator]="true" [rowsPerPageOptions]="[10,25,50]"
        [globalFilterFields]="['notaryId','name', 'middleName', 'lastName', 'address','zipcode','city','state']">

        <ng-template pTemplate="caption">
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <button type="button" (click)="createNotary()" pButton icon="pi pi-plus" label="New"
                        class="mr-2"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedNotary()"
                        [disabled]="!selectednotaries || !selectednotaries.length"></button>
                </ng-template>

                <ng-template pTemplate="right">
                    <span class="p-input-icon-left">
                        <em class="pi pi-search"></em>
                        <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Search..." />
                    </span>
                </ng-template>
            </p-toolbar>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem" id=" ">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th id=" " pSortableColumn="notaryId">Notary Id. <p-sortIcon field="notaryId"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="middleName">Middle Name <p-sortIcon field="middleName"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="lastName">Last Name <p-sortIcon field="lastName"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="city">City <p-sortIcon field="city"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="state">State <p-sortIcon field="state"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="address">Address <p-sortIcon field="address"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="active">Active <p-sortIcon field="address"></p-sortIcon>
                </th>
                <th id=" " pSortableColumn="zipcode">Zipcode <p-sortIcon field="zipcode"></p-sortIcon>
                </th>
                <th id=" "> </th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-notary>
            <tr>
                <td>
                    <p-tableCheckbox [value]="notary"></p-tableCheckbox>
                </td>
                <td>{{notary.notaryId}}</td>
                <td>{{notary.name}}</td>
                <td>{{notary.middleName}}</td>
                <td>{{notary.lastName}}</td>
                <td>{{notary.city}}</td>
                <td>{{notary.state.stateName}}</td>
                <td>{{notary.address}}</td>
                <td *ngIf="notary.active">
                    <p-tag styleClass="mr-2" icon="pi pi-check" severity="success" value="Yes"></p-tag>
                </td>
                <td *ngIf="!notary.active">
                    <p-tag styleClass="mr-2" icon="pi pi-times" severity="danger" value="No"></p-tag>
                </td>
                <td>{{notary.zipcode}}</td>
                <td>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded mr-2"
                        (click)="editNotary(notary)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded  mr-2"
                        (click)="deleteNotary(notary)"></button>
                    <button pButton pRipple icon="pi pi-eye" pTooltip="Detail notary" class="p-button-rounded mr-2"
                        (click)="detailNotay(notary)"></button>
                    <button *ngIf="notary.active" pButton pRipple icon="pi pi-times" pTooltip="inactivate Notary"
                        class="p-button-rounded" (click)="InactivateNotary(notary)"></button>
                    <button *ngIf="!notary.active" pButton pRipple icon="pi pi-check" pTooltip="Activate Notary"
                        class="p-button-rounded" (click)="ActivateNotary(notary)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                All Registers {{notaries ? allRegisters : 0 }} notaries.
            </div>
        </ng-template>
    </p-table>
</div>