import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SellingAgentElement } from 'app/models/sellingAgent.model';

@Injectable({
  providedIn: 'root'
})
export class SellingAgentServicesService {
  url:string = environment.urlBase
  constructor(private http: HttpClient) { }
  headers: HttpHeaders = new HttpHeaders({
    "Content-type": "application/json",
    "accept": "*"
  });
  getSellingAgents() {
    let url_sellingAgent = this.url+'users/selling-agents';
    return this.http.get<SellingAgentElement[]>(url_sellingAgent,{headers: this.headers});
  }

  addSellingAgent(sellingAgent) {
    let url_sellingAgent = this.url+'sellingAgent/';
    return this.http.post<SellingAgentElement>(url_sellingAgent,sellingAgent,{headers: this.headers});
  }
  updateSellingAgent(sellingAgent) {
    let url_sellingAgent = this.url+'sellingAgent/';
    return this.http.put<SellingAgentElement>(url_sellingAgent,sellingAgent,{headers: this.headers});
  }

  deleteSellingAgent(sellingAgent){
    let url_sellingAgent = this.url+'sellingAgent/'+sellingAgent.userName;
    return this.http.delete<SellingAgentElement>(url_sellingAgent, {observe : 'response'});
  }

  getSellingAgentPaginator(first, total){
    let url_banks = this.url+'sellingAgent/'+first+'/'+total;
    return this.http.get<any>(url_banks,{headers: this.headers});
  }

}
