import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { NotaryRequestServiceService } from 'app/services/notaryServices/notary-request-service.service';
import { NotaryElement } from 'app/models/notary.model';
import { ConfirmationService, MessageService, } from "primeng/api";
import { R3Element } from 'app/models/R3.model';
import { GenericElement } from 'app/models/generic.model';
import { R3requestsService } from 'app/services/R3Services/R3.service';
import { NotaryInvoiceElement } from 'app/models/notaryInvoice.model';
import { ResumenGastosElement } from 'app/models/resumenGastos.model';
import { GastosRelacionadosElement } from 'app/models/gastosRelacionados.model';
import { SellosArancelesAdicionalesElement } from 'app/models/sellosArancelesAdicionales.model';
import { SellosEscrituraCertificadaElement } from 'app/models/sellosEscrituraCertificada.model';
import { SellosEscrituraOriginalElement } from 'app/models/sellosEscrituraOriginal.model';
import { ArancelesElement } from 'app/models/aranceles.model';
import { NotaryInvoiceRequestService } from 'app/services/notaryInvoiceServices/notary-invoice-request-service.service';
import { DatePipe } from '@angular/common'
import { KeycloakService } from 'keycloak-angular';
import { Apollo, gql } from 'apollo-angular';

@Component({
  selector: 'app-notary-invoice-modal',
  templateUrl: './notary-invoice-modal.component.html',
  styleUrls: ['./notary-invoice-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NotaryInvoiceModalComponent implements OnInit {


  public r3FileNumber: number;
  public isLoad: boolean = true;
  public isEdit: boolean = false;
  public isView: boolean = false;
  public submitted: boolean = true;
  public R3: string;
  public R3Element: R3Element;
  public R3s: R3Element[];
  public notary: number;
  public notaries: NotaryElement[];
  public notaryInvoice: NotaryInvoiceElement;
  public transaccion: string;
  public transacciones: GenericElement[];
  public tipo: GenericElement;
  public tipos: GenericElement[];
  public numeroFactura: string = '';
  public fecha: Date;
  public fechaCierre: Date;
  public facturaA: string = '';
  public contrato: string = '';
  public honorariosNotariales: number;
  public aranceles: ArancelesElement = {};
  public sellosEscrituraOriginal: SellosEscrituraOriginalElement = {};
  public sellosEscrituraCertificada: SellosEscrituraCertificadaElement = {};
  public sellosArancelesAdicionales: SellosArancelesAdicionalesElement = {};
  public gastosRelacionados: GastosRelacionadosElement = {};
  public resumenGastos: ResumenGastosElement = {};
  public datepipe: DatePipe;
  public isNotary: boolean = false;
  public isAdmin: boolean = false;
  public notaryInvoiceRechazo: NotaryInvoiceElement;
  public dialog: boolean = false;
  public comment: string = "";
  public tiempoTranscurrido = new Date();

  constructor(
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private notaryInvoiceService: NotaryInvoiceRequestService,
    private notaryService: NotaryRequestServiceService,
    private R3Service: R3requestsService,
    public keycloakService: KeycloakService,
    public messageService: MessageService,
    public apollo: Apollo
  ) {
    if (config.data) {
      this.numeroFactura = (config.data.numeroFactura);
      this.isLoad = false;
      this.loadNotaryInvoice(this.numeroFactura);
      this.isView = (config.data.isView);
      this.isEdit = (config.data.isEdit);
    }
    if (!this.notaryInvoice) {
      this.notaryInvoice = {};
      this.notaryInvoice.r3 = {};
      this.notaryInvoice.r3.propertyInformation = {};
      this.notaryInvoice.r3.propertyInformation.state = {};
      this.notaryInvoice.r3.financials = {};
      this.notaryInvoice.r3.financials.closingAgent = {};
      this.notaryInvoice.r3.postClosingInformation = [];
      this.notaryInvoice.r3.closingInformation = {};
      this.notaryInvoice.r3.closingInformation.closingInformationExtentionInformationDTO = [];
      this.notaryInvoice.r3.preClosingInformation = {};
      this.notaryInvoice.r3.preClosingInformation.programManager = {};
      this.notaryInvoice.r3.sellerInformation = {};
      this.notaryInvoice.r3.buyerInformation = [];
      this.notaryInvoice.r3.active = false;
      this.notaryInvoice.r3.postClosingComplete = false;
      this.notaryInvoice.active = true;
      this.notaryInvoice.arancelesDTO = {};
      this.notaryInvoice.status = 'creado';
      this.notaryInvoice.sellosEscrituraOriginalDTO = {};
      this.notaryInvoice.sellosEscrituraCertificadaDTO = {};
      this.notaryInvoice.sellosArancelesAdicionalesDTO = {};
      this.notaryInvoice.gastosRelacionadosDTO = {};
      this.notaryInvoice.resumenGastosDTO = {};
    }
  }

  validarIsAdminOrNotary() {
    if (this.keycloakService.isUserInRole("closingAdmin") || this.keycloakService.isUserInRole("closingSuperUser")) {
      this.isAdmin = true;
      this.isNotary = false;
    } else if (this.keycloakService.isUserInRole("closingNotary")) {
      this.isAdmin = false;
      this.isNotary = true;

    } else {
      this.isAdmin = false;
      this.isNotary = false;
    }
  }
  myAlert(): void {
    console.log(JSON.stringify(this.R3));
    this.notaryInvoice.r3 = this.R3s[this.findIndexByIdR3(this.R3)];
    console.log('response', this.notaryInvoice.r3);
    if (this.notaryInvoice.r3.sellerInformation.fullName == null) {
      this.notaryInvoice.r3.sellerInformation.fullName = (this.notaryInvoice.r3.sellerInformation.name ? this.notaryInvoice.r3.sellerInformation.name : "") + " " + (this.notaryInvoice.r3.sellerInformation.lastName ? this.notaryInvoice.r3.sellerInformation.lastName : "");
    }
    if (this.notaryInvoice.r3.typeOfProperty != null) {
      this.cargarTipo(this.notaryInvoice.r3.typeOfProperty);
    }

  }
  cargarTipo(typeOfProperty: string) {
    if (typeOfProperty == "damage") {
      this.tipo = { name: "Storm Damaged Property", value: "damage" };
    } else if (typeOfProperty == "replacement") {
      this.tipo = { name: "Relocation Property", value: "replacement" };
    } else {
      this.tipo = {};
    }
  }
  myAlert2() {
    console.log(this.notary);
  }
  findIndexByIdR3(id: string): number {
    let index = -1;
    for (let i = 0; i < this.R3s.length; i++) {
      if (this.R3s[i].r3FileNumber === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  findIndexByIdNotary(id: number): number {
    let index = -1;
    for (let i = 0; i < this.notaries.length; i++) {
      if (this.notaries[i].notaryId === id) {
        index = i;
        break;
      }
    }
    return index;
  }


  ngOnInit(): void {
    this.cargarTransacciones();
    this.cargarTipos();
    this.validarIsAdminOrNotary();
    this.loadNotaries();
    this.loadR3s();



  }
  loadR3s() {

    if (this.isNotary) {
      var nameNotary = ' ';
      if (this.keycloakService.isUserInRole("closingNotary")) {
        nameNotary = this.keycloakService.getUsername();
      }
      this.R3Service.getR3sNotary(nameNotary).subscribe(res => {
        if (res) {
          this.R3s = res;
        }
      });
    } else {
      this.R3Service.getR3s().subscribe(res => {
        if (res) {
          this.R3s = res;
        }
      });
    }
  }
  loadNotaries() {
    if (this.isNotary) {
      this.notaryService.getNotaryByName(this.keycloakService.getUsername()).subscribe(res => {
        if (res) {
          this.notaries = res;
        }
      });
    } else {
      this.notaryService.getNotaries().subscribe(res => {
        if (res) {
          this.notaries = res;
        }
      });
    }
  }
  loadNotaryInvoice(numeroFactura) {
    setTimeout(() => {
      this.notaryInvoiceService.getNotaryInvoice(numeroFactura).subscribe(res => {
        this.notaryInvoice = {};
        if (res) {
          this.notaryInvoice = res;
          this.R3 = this.notaryInvoice.r3.r3FileNumber;
          this.cargarTipo(this.notaryInvoice.r3.typeOfProperty);
          this.R3 = this.notaryInvoice.r3.r3FileNumber;
          this.transaccion = this.notaryInvoice.transaccion;
          console.log('Notary', this.notaryInvoice.notaryDTO);
          this.notary = this.notaryInvoice.notaryDTO.notaryId;
          this.datepipe = new DatePipe('en-US');
          this.notaryInvoice.fecha = (res.fecha != null ? new Date(this.datepipe.transform(res.fecha, 'MM/dd/yyyy')) : null);
          this.notaryInvoice.fechaCierre = (res.fechaCierre != null ? new Date(this.datepipe.transform(res.fechaCierre, 'MM/dd/yyyy')) : null);
          this.isLoad = true;
        }
      })
    }, 1000);
  }
  cargarTipos() {
    this.tipos = [
      { name: "Storm Damaged Property", value: "damage" },
      { name: "Relocation Property", value: "replacement" }
    ]
  }
  cargarTransacciones() {
    this.transacciones = [
      { name: 'Escritura de Compraventa & Condiciones Restrictivas', value: 'Escritura de Compraventa & Condiciones Restrictivas' },
      { name: 'Escritura de Compraventa & Hogar Seguro', value: 'Escritura de Compraventa & Hogar Seguro' },
      { name: 'Escritura de Compraventa de Hipoteca Directa', value: 'Escritura de Compraventa de Hipoteca Directa' },
      { name: 'Escrituracion de Cancelacion de Hipoteca', value: 'Escrituracion de Cancelacion de Hipoteca' },
      { name: 'Otro (Tipo)', value: 'Otro (Tipo)' }
    ]
  }


  Cancel() {
    this.ref.close();
  }

  ConfirmClose() {
    this.isView = false;
    this.confirmationService.confirm({
      message: 'Do you want to save changes?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onSubmit();
      },
      reject: () => {
        this.ref.close();
      }
    });
  }

  CloseNI() {
    this.ref.close();
  }


  onSubmit(rechazo?: string) {
    var isRechazo = false;
    var error = false;
    this.notaryInvoice.status = "creado";

    var errorMessage = "Error";

    if (this.transaccion) {
      this.notaryInvoice.transaccion = this.transaccion;
    }
    if (this.tipo) {
      this.notaryInvoice.r3.typeOfProperty = this.tipo.value;
    }
    if (this.notary) {
      this.notaryInvoice.notaryDTO = this.notaries[this.findIndexByIdNotary(this.notary)];
    }

    if (rechazo == 'rechazado') {
      this.notaryInvoice.comentariosRechazo = this.comment;
      this.notaryInvoice.status = "rechazado";
      isRechazo = true;
    } else if (rechazo == 'aceptado') {
      this.notaryInvoice.comentariosRechazo = "";
      this.notaryInvoice.status = "aceptado";
      isRechazo = true;
    }


    if (!this.notaryInvoice.r3.r3FileNumber) {
      error = true;
      errorMessage = "R3 File number is required";
    } else if (!this.notaryInvoice.fecha) {
      error = true;
      errorMessage = "Fecha is required";
    } else if (!this.notaryInvoice.numeroFactura) {
      error = true;
      errorMessage = "Número de Factura is required";
    } else if (!this.notaryInvoice.facturaA) {
      error = true;
      errorMessage = "A is required";
    } else if (!this.notaryInvoice.contrato) {
      error = true;
      errorMessage = "Contrato Address is required";
    } else if (!this.notaryInvoice.transaccion) {
      error = true;
      errorMessage = "Transacción is required";
    } else if (!this.notaryInvoice.notaryDTO) {
      error = true;
      errorMessage = "Notario Asignado is required";
    }
    if (!error) {
      if (isRechazo) {
        this.confirmationService.confirm({
          message: 'Are you sure you want to ' + (this.notaryInvoice.status == 'rechazado' ? 'decline ' : 'Accept ') + this.notaryInvoice.numeroFactura + '?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.notaryInvoiceService.updateNotaryInvoice(this.notaryInvoice).subscribe(_res => {
              this.ref.close(true);
            }, err => {
              this.messageAlert('error', 'Error', err.error.error);
            })
          }
        });
      } else if (this.isEdit) {
        this.notaryInvoiceService.updateNotaryInvoice(this.notaryInvoice).subscribe(_res => {
          this.ref.close(true);
        }, err => {
          this.messageAlert('error', 'Error', err.error.error);
        })
      } else {
        this.notaryInvoiceService.addNotaryInvoice(this.notaryInvoice).subscribe(_res => {
          this.ref.close(true);
        }, err => {
          this.messageAlert('error', 'Error', err.error.error);
        })
      }
    } else {
      this.messageAlert('error', 'Error', errorMessage);
    }
  }

  messageAlert(severity: string, summary: string, detail: string) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  }
  hideDialog() {
    this.dialog = false;
    this.comment = "";
  }
  openDialog() {
    this.dialog = true;
  }
  estadoCreado(status: string) {
    if (status == "creado") {
      return true;
    } else {
      return false;
    }
  }
  estadoRechazado(status: string) {
    if (status == "rechazado") {
      return true;
    } else {
      return false;
    }
  }
  estadoAceptado(status: string) {
    if (status == "aceptado") {
      return true;
    } else {
      return false;
    }
  }
  estadoEnviado(status: string) {
    if (status == "enviado") {
      return true;
    } else {
      return false;
    }
  }

}
