<div class="card">
    <form  [formGroup]="formSellingAgent" (ngSubmit)="onSubmit()">
        <div class="p-fluid grid">
            <div class="field col-6 md:col-6">
                <label for="name">First Name</label>
                <input type="text" name="firstName" id="firstName" pInputText  formControlName="firstName">
            </div>
            <div class="field col-6 md:col-6">
                <label>Last Name</label>
                <input type="text" name="lastName" id="lastName" pInputText  formControlName="lastName">
            </div>
            <div class="field col-6 md:col-6">
                <label>Full Name</label>
                <input type="text" name="fullName" [attr.disabled]="true" id="fullName" pInputText  formControlName="fullName">
            </div>
            <div class="field col-6 md:col-6">
                <label>User Name</label>
                <input type="text" name="userName" id="userName" pInputText  formControlName="userName">  
            </div>
        </div>
        <br/>
        <div class="field col-6 md:col-6">
            <button *ngIf="this.isEdit== false" class="mr-2" type="submit" [disabled]="formSellingAgent.invalid" pButton
                label="Save"></button>
            <button *ngIf="this.isEdit== true" class="mr-2" type="submit" [disabled]="formSellingAgent.invalid" pButton
                label="Update"></button>
            <button type="button" pButton label="Cancel" (click)="Cancel()"></button>
        </div>
    </form>
</div>

