import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { SellingAgentServicesService } from 'app/services/sellingAgentServices/selling-agent-services.service';
import { SellingAgentElement } from 'app/models/sellingAgent.model';
import { MessageService, } from "primeng/api";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: 'app-selling-agent-modal',
  templateUrl: './selling-agent-modal.component.html'
})
export class SellingAgentModalComponent implements OnInit {

  public formSellingAgent: UntypedFormGroup;
  public isEdit: boolean = false;
  sellingAgent: SellingAgentElement;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private formBuilder: UntypedFormBuilder, private sellingAgentService: SellingAgentServicesService,
    public messageService: MessageService) {
    if (config.data) {
      this.sellingAgent = (config.data.sellingAgent);
    }
  }

  ngOnInit(): void {
    this.formSellingAgent = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      fullName:  [''],
      name:  [''],
      active: [true],
      createdByUser: [''],
      userName: ['', [Validators.required]],
      createdDate: [Date],
      lastModifiedDate: [Date],
      modifiedByUser: [''],
      version: [0]
    });
    if (this.sellingAgent) {
      this.isEdit = true;
      this.formSellingAgent.patchValue(this.sellingAgent);
    }
  }

  Cancel() {
    this.ref.close()
  }

  onSubmit() {
    if (this.formSellingAgent.dirty && this.formSellingAgent.valid) {
      this.formSellingAgent.value.fullName = ((this.formSellingAgent.value.firstName ? this.formSellingAgent.value.firstName: '') +" "+ (this.formSellingAgent.value.lastName ? this.formSellingAgent.value.lastName : ''));

      if (this.isEdit) {
        this.sellingAgentService.updateSellingAgent(this.formSellingAgent.value).subscribe(
          (_apiResp: any) => {
            this.ref.close(true)
          },
          (_err: any) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'SellingAgent not  edit', life: 3000 });
          }
        )
      } else {
        this.sellingAgentService.addSellingAgent(this.formSellingAgent.value).subscribe(
          (_apiResp: any) => {
            this.ref.close(true)
          },
          (_err: any) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'SellingAgent not  create', life: 3000 });
          }
        )
      }

    }

  }

}
