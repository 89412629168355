import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContractorElement } from 'app/models/contractor.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContractorRequestServiceService {
  url:string = environment.urlBase
  constructor(private http: HttpClient) { }
  headers: HttpHeaders = new HttpHeaders({
    "Content-type": "application/json",
    "accept": "*"
  });
  getContractors() {
    let url_contractor = this.url+'contractor/';
    return this.http.get<ContractorElement[]>(url_contractor,{headers: this.headers});
  }

  addContractor(contractor) {
    let url_contractor = this.url+'contractor/';
    return this.http.post<ContractorElement>(url_contractor,contractor,{headers: this.headers});
  }
  updateContractor(contractor) {
    let url_contractor = this.url+'contractor/';
    return this.http.put<ContractorElement>(url_contractor,contractor,{headers: this.headers});
  }

  deleteContractor(contractor){
    let url_contractor = this.url+'contractor/'+contractor.contractorId;
    return this.http.delete<ContractorElement>(url_contractor, {observe : 'response'});
  }

  getContractorPaginator(first, total){
    let url_contractor = this.url+'contractor/'+first+'/'+total;
    return this.http.get<any>(url_contractor,{headers: this.headers});
  }

}
