<p-toast></p-toast>
<p-progressSpinner *ngIf="!isLoad" style="margin-left: 50%; transform: translateX(-50%);"></p-progressSpinner>
<p-tabView *ngIf="isLoad">
    <p-tabView (onChange)="changeTab($event)">
        <!--Cabecera fija en todas las pestañas-->
        <div class="grid p-fluid" style="border: 1px;">
            <div class="field col-12 md:col-3">
                <label for="integeronly">FHA</label>
                <input type="input" (keydown)="onKeydownLettersAndNumbersAndGuion($event)" [readonly]="isView"
                    [disabled]="isContactInformation" class="p-inputtext-sm" [(ngModel)]="closing.fha" pInputText />
                <small class="p-error" *ngIf="submitted && !closing.fha">FHA is required.</small>
            </div>
            <div class="field col-12 md:col-3">
                <label for="integeronly">Deval File Number</label>
                <div class="p-inputgroup">
                    <input type="input" (keydown)="onKeydownNumbersAndGuion($event)" (keypress)="addFormatDevalFileNumber()"
                        [readonly]="isView || isEdit" [disabled]="isContactInformation" class="p-inputtext-sm"
                        [(ngModel)]="closing.devalFileNumber" pInputText />
                    <button [disabled]="isContactInformation" type="button" (click)="deleteDevalNumber()"
                        *ngIf="!isView || !isContactInformation" pButton pRipple icon="pi pi-times"
                        styleClass="p-button-danger"></button>
                </div>
                <small class="p-error" *ngIf="submitted && !closing.devalFileNumber">Deval File Number is
                    required.</small>
            </div>
            <div class="field col-12 md:col-3">
                <label for="integeronly">Closing Processor</label>
                <p-dropdown [readonly]="isView" [disabled]="isContactInformation" [options]="closingProcessors" 
                    optionLabel="fullName" styleClass="form-control" [(ngModel)]="closing.closingProcessor" 
                    placeholder="ClosingProcessors" class="p-inputtext-sm">
                </p-dropdown>
                <small class="p-error" *ngIf="submitted && !closing.closingProcessor">Closing Processor is
                    required.</small>
            </div>
            <div class="field col-12 md:col-3">
                <label for="integeronly">Contract Date</label>
                <p-calendar [disabled]="isContactInformation" [disabled]="isView" inputId="calendar"
                    placeholder="mm/dd/yyyy" class=" ng-dirty p-inputtext-sm" [(ngModel)]="closing.contractDate"
                    [showIcon]="true">
                </p-calendar>
                <small class="p-error" *ngIf="submitted && !closing.contractDate">Contract Date is required.</small>
            </div>
        </div>

        <p-tabPanel header="Closing Contact Information">
            <p-divider align="center">
                <span class="p-tag">Closing Contact Information</span>
            </p-divider>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <p-panel>
                        <ng-template pTemplate="header">
                            Property Information
                        </ng-template>
                        <div class="grid p-fluid">
                            <div class="field col-12 md:col-6">
                                <label for="integeronly">Property Address</label>
                                <input type="input" [readonly]="isView"
                                    [(ngModel)]="closing.propertyInformation.propertyAddress" class="p-inputtext-sm"
                                    pInputText />
                                <small class="p-error"
                                    *ngIf="submitted && !closing.propertyInformation.propertyAddress">Property Address
                                    is
                                    required.</small>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="label">City </label>
                                <input type="input" [readonly]="isView" [(ngModel)]="closing.propertyInformation.city"
                                    class="p-inputtext-sm" pInputText />
                                <small class="p-error" *ngIf="submitted && !closing.propertyInformation.city">City is
                                    required.</small>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="integeronly">State</label>
                                <p-dropdown [readonly]="isView" [readonly]="isView" [options]="states"
                                    optionLabel="stateName" styleClass="form-control"
                                    [(ngModel)]="closing.propertyInformation.state" placeholder="State" id="stateId"
                                    name="stateId" dataKey="stateId" class="p-inputtext-sm">
                                </p-dropdown>
                                <small class="p-error"
                                    *ngIf="submitted && !closing.propertyInformation.state.stateId">State is
                                    required.</small>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="integeronly">ZipCode</label>
                                <input type="input" [readonly]="isView"  maxlength="5"
                                    [(ngModel)]="closing.propertyInformation.zipCode"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    minlength="5" id="zipCodeProperty" onkeyup="validateZipCode()"
                                    class="p-inputtext-sm" pInputText />
                                <small class="p-error" *ngIf="submitted && !closing.propertyInformation.zipCode">ZipCode
                                    is required.</small>
                            </div>
                        </div>
                        <ng-template pTemplate="footer">

                        </ng-template>
                    </p-panel>
                </div>
                <div class="field col-12 md:col-6">
                    <p-panel>
                        <ng-template pTemplate="header">
                            Buyer Information
                        </ng-template>
                        <p-table #dt [value]="buyerInformations" [(selection)]="selectedBuyerInformations">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 3rem" id=" ">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th id=" ">Name </th>
                                    <th id=" ">Type </th>
                                    <th id=" ">Phone</th>
                                    <th [hidden]="isView" id=" "></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-buyerInformation>
                                <tr>
                                    <td>
                                        <p-tableCheckbox [value]="buyerInformation"></p-tableCheckbox>
                                    </td>
                                    <td>{{( (buyerInformation.name ? buyerInformation.name : '') + " " +
                                        (buyerInformation.middleName ? buyerInformation.middleName : '' ) + " " +
                                        (buyerInformation.lastName? buyerInformation.lastName : '') )}}</td>
                                    <td>{{buyerInformation.typeOfBuyer}}</td>
                                    <td>{{buyerInformation.phoneNumber}}</td>
                                    <td [hidden]="isView">
                                        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded  mr-2"
                                            (click)="editBuyerInformation(buyerInformation)"></button>
                                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded"
                                            (click)="deleteBuyerInformation(buyerInformation)"></button>
                                    
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">
                                <p-toolbar styleClass="mb-4">
                                    <ng-template pTemplate="left">

                                    </ng-template>
                                    <ng-template pTemplate="right">
                                        <div [hidden]="isView">
                                            <button type="button" (click)="openDialogBuyer()" pButton icon="pi pi-plus"
                                                class="mr-2"></button>
                                            <button pButton pRipple icon="pi pi-trash" class="p-button-danger"
                                                (click)="deleteSelectedBuyerInformations()"
                                                [disabled]="!selectedBuyerInformations || !selectedBuyerInformations.length"></button>
                                        </div>
                                    </ng-template>
                                </p-toolbar>
                            </ng-template>
                        </p-table>
                        <ng-template pTemplate="footer">

                        </ng-template>
                    </p-panel>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Pre-Closing Information">
            <p-divider align="center">
                <span class="p-tag">Pre-Closing Information</span>
            </p-divider>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-4">
                    <label class="label">Sale Amount </label>
                    <p-inputNumber [readonly]="isView" mode="currency" currency="USD"
                        locale="en-US" [(ngModel)]="closing.preClosingInformation.saleAmount" class="p-inputtext-sm">
                    </p-inputNumber>
                    <small class="p-error" *ngIf="submitted && !closing.preClosingInformation.saleAmount">Sale Amount is
                        required.</small>
                </div>

                <div class="field col-12 md:col-4">
                    <label for="integeronly">Method of Sale</label>
                    <p-dropdown (onChange)="changeMethod()" [readonly]="isView" [options]="methods" optionLabel="name" optionValue="value"
                        styleClass="form-control" [(ngModel)]="closing.preClosingInformation.methodOfSale" placeholder="Method of Sale" class="p-inputtext-sm">
                    </p-dropdown>
                    <small class="p-error" *ngIf="submitted && !closing.preClosingInformation.methodOfSale">Method of Sale is required.</small>
                </div>
                <div class="field col-12 md:col-4">
                    <label for="integeronly">Lender</label>
                    <p-dropdown [readonly]="isView" [disabled]="lenderDisabled" [options]="banks" optionLabel="bankName"
                        styleClass="form-control" [(ngModel)]="closing.preClosingInformation.lenderBank"
                        placeholder="Bank" id="bankId" name="bankId" dataKey="bankId" class="p-inputtext-sm">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-4">
                    <label class="label">Date Earnest Money Received </label>
                    <p-calendar dateFormat="mm/dd/yy" [disabled]="isView" inputId="calendar"
                        [(ngModel)]="closing.preClosingInformation.dateEarnestMoneyReceived" placeholder="mm/dd/yyyy"
                        class=" ng-dirty p-inputtext-sm" [showIcon]="true">
                    </p-calendar>
                </div>
                <div class="field col-12 md:col-4">
                    <label class="label">Earnest Money Amount</label>
                    <p-inputNumber [readonly]="isView" mode="currency" currency="USD"
                        locale="en-US" [(ngModel)]="closing.preClosingInformation.ernestMoneyAmount"
                        class="p-inputtext-sm">
                    </p-inputNumber>
                </div>
                <div class="field col-12 md:col-4">
                    <label class="label">AM Contractor </label>
                    <p-dropdown [readonly]="isView" [options]="contractors" optionLabel="name" styleClass="form-control"
                        [(ngModel)]="closing.preClosingInformation.amContractor" placeholder="Contractors"
                        id="contractorId" name="contractorId" dataKey="contractorId" class="p-inputtext-sm">
                    </p-dropdown>
                    <small class="p-error"
                        *ngIf="submitted && !closing.preClosingInformation.amContractor.contractorId">AM Contractor is
                        required.</small>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Closing Information">
            <p-divider align="center">
                <span class="p-tag">Closing Information</span>
            </p-divider>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-6">
                            <label for="integeronly">Lender</label>
                            <p-dropdown [readonly]="isView" [options]="banks" [disabled]="lenderDisabled"
                                optionLabel="bankName" styleClass="form-control"
                                [(ngModel)]="closing.preClosingInformation.lenderBank" placeholder="Bank" id="bankId"
                                name="bankId" dataKey="bankId" class="p-inputtext-sm">
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="integeronly">Received Package from lender</label>
                            <p-calendar [disabled]="isView" inputId="calendar" placeholder="mm/dd/yyyy"
                                class=" ng-dirty p-inputtext-sm" [(ngModel)]="closing.closingInformation.receivedPachageFromLender"
                                [showIcon]="true">
                            </p-calendar>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="label">Scheduled Closing Date:
                                (Leave blank if closing
                                date not scheduled) </label>
                            <p-calendar  dateFormat="mm/dd/yy"
                                [disabled]="isView"
                                inputId="calendar" [(ngModel)]="closing.closingInformation.scheduleClosingDate"
                                placeholder="mm/dd/yyyy" class=" ng-dirty p-inputtext-sm" [showIcon]="true">
                            </p-calendar>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="label">Actual Closing Date:
                                (Leave blank if you have
                                not closed yet) </label>
                            <p-calendar dateFormat="mm/dd/yy" (onSelect)="changeActual()"
                                [disabled]="isView || (closing.closingInformation.closingCancelledDate != null)"
                                inputId="calendar" [(ngModel)]="closing.closingInformation.actualClosingDate"
                                placeholder="mm/dd/yyyy" class=" ng-dirty p-inputtext-sm" [showIcon]="true">
                            </p-calendar>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="label">Closing Cancelled Date:
                                (Leave blank if closing is
                                not cancelled) </label>
                            <p-calendar dateFormat="mm/dd/yy"
                                [disabled]="isView || (closing.closingInformation.actualClosingDate != null)"
                                inputId="calendar" [(ngModel)]="closing.closingInformation.closingCancelledDate"
                                placeholder="mm/dd/yyyy" class=" ng-dirty p-inputtext-sm" [showIcon]="true">
                            </p-calendar>
                        </div>
                        <div class="field col-12 md:col-6">
                            <br />
                            <label class="label">Earnest Money Dispostion </label>
                            <p-dropdown [readonly]="isView" [options]="earnestMoneyDispositions" optionLabel="name"
                                styleClass="form-control" [(ngModel)]="closing.closingInformation.earnestMoneyDispostion"
                                placeholder="Earnest Money Dispostion" optionValue="value"
                                class="p-inputtext-sm">
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-12">
                            <label for="label">Cancellation Reason </label>
                            <textarea [(ngModel)]="closing.closingInformation.cancellationReason"
                                pInputTextarea [readonly]="isView"></textarea>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-6">
                    <div class="field col-12 md:col-12">
                        <br />
                        <p-table #dt [value]="extensionClosings" [(selection)]="selectedExtensionClosings">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 3rem" id=" ">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th id=" ">Extension Closing Date</th>
                                    <th id=" ">Comment</th>
                                    <th id=" ">Waived</th>
                                    <th [hidden]="isView" id=" "></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-extensionClosing>
                                <tr>
                                    <td>
                                        <p-tableCheckbox [value]="extensionClosing"></p-tableCheckbox>
                                    </td>
                                    <td>{{extensionClosing.extentionClosingDate | date: 'MM/dd/YYYY'}}</td>
                                    <td>{{extensionClosing.comment}}</td>
                                    <td *ngIf="extensionClosing.waive">
                                        <p-tag styleClass="mr-2" icon="pi pi-check" severity="success" value="Yes">
                                        </p-tag>
                                    </td>
                                    <td *ngIf="!extensionClosing.waive">
                                        <p-tag styleClass="mr-2" icon="pi pi-times" severity="danger" value="No">
                                        </p-tag>
                                    </td>
                                    <td [hidden]="isView">
                                        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded  mr-2"
                                            (click)="editExtensionClosing(extensionClosing)"></button>
                                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded"
                                            (click)="deleteExtensionClosing(extensionClosing)"></button>

                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">
                                <p-toolbar styleClass="mb-4">
                                    <ng-template pTemplate="left">

                                    </ng-template>
                                    <ng-template pTemplate="right">
                                        <div [hidden]="isView">
                                            <button type="button"
                                                [disabled]="closing.closingInformation.actualClosingDate"
                                                (click)="openDialog()" pButton icon="pi pi-plus"
                                                class="mr-2"></button>&nbsp;
                                            <button pButton pRipple icon="pi pi-trash" class="p-button-danger"
                                                (click)="deleteSelectedExtensionClosings()"
                                                [disabled]="!selectedExtensionClosings || !selectedExtensionClosings.length"></button>
                                        </div>
                                    </ng-template>
                                </p-toolbar>
                            </ng-template>
                        </p-table>

                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Post-Closing Information">
            <p-divider align="center">
                <span class="p-tag">Post-Closing Information</span>
            </p-divider>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-3">
                    <label for="label">Notary Name </label>
                    <p-dropdown [readonly]="isView" [options]="notaries" optionLabel="fullName" styleClass="form-control"
                        [(ngModel)]="closing.postClosingInformation.notaryId" placeholder="Notary" class="p-inputtext-sm"
                        id="notaryId" name="notaryId" dataKey="notaryId">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-3">
                    <label for="label">Deed Number </label>
                    <p-inputNumber [readonly]="isView" [useGrouping]="false"
                        [(ngModel)]="closing.postClosingInformation.deedNumber" class="p-inputtext-sm"></p-inputNumber>
                </div>
                <div class="field col-12 md:col-3">
                    <label for="label">Deed Date </label>
                    <p-calendar dateFormat="mm/dd/yy" [disabled]="isView" inputId="calendar"
                        [(ngModel)]="closing.postClosingInformation.deedDate" placeholder="mm/dd/yyyy"
                        class=" ng-dirty p-inputtext-sm" [showIcon]="true">
                    </p-calendar>
                </div>
                <div class="field col-12 md:col-3">
                    <label for="label">Post Closing Upload Package Date </label>
                    <p-calendar dateFormat="mm/dd/yy" [disabled]="isView" inputId="calendar"
                        [(ngModel)]="closing.postClosingInformation.uploadPackageDate" placeholder="mm/dd/yyyy"
                        class=" ng-dirty p-inputtext-sm" [showIcon]="true">
                    </p-calendar>
                </div>
            </div>

            <div>
                <p-checkbox [readonly]="isView"  [binary]="true" [(ngModel)]="closing.postClosingComplete" ></p-checkbox>
                <label for="sf"> Post closing complete</label>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Financials">
            <p-divider align="center">
                <span class="p-tag">Financials Information</span>
            </p-divider>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-4">
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-12">
                            <label class="label">Closing Agent Fee</label>
                            <p-inputNumber [readonly]="isView"
                                [(ngModel)]="closing.financials.closingAgent.fee" mode="currency" currency="USD" locale="en-US" class="p-inputtext-sm">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-12">
                            <label class="label">Tax Amount </label>
                            <p-inputNumber [readonly]="isView" mode="currency" currency="USD"
                                locale="en-US" [(ngModel)]="closing.financials.closingAgent.taxAmount"
                                class="p-inputtext-sm">
                            </p-inputNumber>

                        </div>
                        <div class="field col-12 md:col-12">
                            <label class="label">Total Closing Fee </label>
                            <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                                [(ngModel)]="closing.financials.closingAgent.totalClosingFee" class="p-inputtext-sm">
                            </p-inputNumber>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-12">
                            <label class="label">Listing Agent</label>
                            <p-dropdown [readonly]="isView" [options]="listingAgents" optionLabel="fullName" styleClass="form-control" [showClear]="true"
                                    [(ngModel)]="closing.financials.listingAgent.userData" placeholder="ListingAgents" class="p-inputtext-sm">
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="label">Listing Agent Gross Fee</label>
                            <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                                [(ngModel)]="closing.financials.listingAgent.gross" class="p-inputtext-sm">
                            </p-inputNumber>

                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="label">Listing Agent Taxable</label>
                            <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                                [(ngModel)]="closing.financials.listingAgent.taxable" class="p-inputtext-sm">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="label">Listing Agent Net</label>
                            <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                                [(ngModel)]="closing.financials.listingAgent.net" class="p-inputtext-sm">
                            </p-inputNumber>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-12">
                            <label class="label">Selling Agent</label>
                            <p-dropdown [readonly]="isView" [options]="sellingAgents" optionLabel="fullName" styleClass="form-control" [showClear]="true"
                                        [(ngModel)]="closing.financials.sellingAgent.userData" placeholder="SellingAgents" class="p-inputtext-sm">
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="label">Selling Agent Gross Fee</label>
                            <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                                [(ngModel)]="closing.financials.sellingAgent.gross" class="p-inputtext-sm">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="label">Selling Agent Taxable</label>
                            <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                                [(ngModel)]="closing.financials.sellingAgent.taxable" class="p-inputtext-sm">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="label">Selling Agent Net</label>
                            <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                                [(ngModel)]="closing.financials.sellingAgent.net" class="p-inputtext-sm">
                            </p-inputNumber>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>

    <p-dialog [(visible)]="dialog" [focusTrap]="false" [style]="{width: '800px', height: '600px'}" header="Extension Closing Add"
        [modal]="true" styleClass="p-fluid">
        <ng-template pTemplate="content">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-4">
                    <div class="field col-12 md:col-12">
                        <label for="extentionClosingDate">Extension Closing Date</label>
                        <p-calendar  dateFormat="mm/dd/yy" [disabled]="isView"
                            id="extentionClosingDate" [(ngModel)]="extensionClosing.extentionClosingDate"
                            placeholder="mm/dd/yyyy" class="p-inputtext-sm">
                        </p-calendar>
                        <small class="p-error" *ngIf="submitted && !extensionClosing.extentionClosingDate">Extension
                            Closing is required.</small>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="field col-12 md:col-12">
                        <label for="comment">Comment</label>
                        <input type="text" pInputText id="comment" [(ngModel)]="extensionClosing.comment" />
                        <small class="p-error" class="p-inputtext-sm"
                            *ngIf="submitted && !extensionClosing.comment">Comment is
                            required.</small>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="field col-12 md:col-12">
                        <label for="waived">Waived?</label>
                        <br />
                        <div class="field-radiobutton">
                            <p-radioButton [value]="true" id="waived_true" [(ngModel)]="extensionClosing.waive">
                            </p-radioButton>
                            <label for="waived">Yes</label>
                            <label> </label>
                            <label> </label>
                            <p-radioButton [value]="false" id="waived_false" [(ngModel)]="extensionClosing.waive">
                            </p-radioButton>
                            <label for="waived">No</label>
                        </div>
                        <small class="p-error" *ngIf="submitted && !extensionClosing.comment">Waived is
                            required.</small>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialog()"></button>
            <button [hidden]="isView" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text"
                (click)="saveExtensionClosing()"></button>
        </ng-template>
    </p-dialog>


    <p-dialog [(visible)]="dialogBuyer" [focusTrap]="false" [style]="{width: '800px', height: '600px'}" header="Buyer Information Add"
        [modal]="true">
        <ng-template pTemplate="content">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-12">
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-4">
                            <label for="label">Name </label>
                            <input type="input" (keydown)="onKeydownLettersSpacing($event)" [readonly]="isView" 
                                [(ngModel)]="buyerInformation.name" class="p-inputtext-sm" pInputText />
                            <small class="p-error" *ngIf="submitted && !buyerInformation.name">Name is required.</small>
                        </div>
                        <div class="field col-12 md:col-4">
                            <label for="label">Middle name </label>
                            <input type="input" (keydown)="onKeydownLettersSpacing($event)" [readonly]="isView"
                                [(ngModel)]="buyerInformation.middleName" class="p-inputtext-sm" pInputText />
                         </div>
                        <div class="field col-12 md:col-4">
                            <label for="label">Last name </label>
                            <input type="input" (keydown)="onKeydownLettersSpacing($event)" [readonly]="isView"
                                [(ngModel)]="buyerInformation.lastName" class="p-inputtext-sm" pInputText />
                            <small class="p-error" *ngIf="submitted && !buyerInformation.lastName">Last Name is
                                required.</small>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="integeronly">Type of buyer </label>
                    <p-dropdown [readonly]="isView" [options]="typesBuyer" optionLabel="name" styleClass="form-control" optionValue="value"
                        [(ngModel)]="buyerInformation.typeOfBuyer" placeholder="Type of buyer" class="p-inputtext-sm">
                    </p-dropdown>
                    <small class="p-error" *ngIf="submitted && !buyerInformation.typeOfBuyer">Type of buyer is required.</small>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="integeronly">Phone</label>
                    <p-inputMask [readonly]="isView" mask="+1(999)-999-9999" [(ngModel)]="buyerInformation.phoneNumber"
                        class="p-inputtext-sm">
                    </p-inputMask>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="label">Address </label>
                    <input type="input" [readonly]="isView" [(ngModel)]="buyerInformation.address"
                        class="p-inputtext-sm" pInputText />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="label">City </label>
                    <input type="input" [readonly]="isView" [(ngModel)]="buyerInformation.city" class="p-inputtext-sm"
                        pInputText />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="integeronly">State</label>
                    <p-dropdown [readonly]="isView" [options]="states" optionLabel="stateName" styleClass="form-control"
                        [(ngModel)]="buyerInformation.state" placeholder="State" id="stateId" name="stateId"
                        dataKey="stateId" class="p-inputtext-sm">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="integeronly">ZipCode</label>
                    <input type="input" [readonly]="isView" [(ngModel)]="buyerInformation.zipcode" maxlength="5"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        minlength="5" id="zipCodeBuyer" onkeyup="validateZipCode()" class="p-inputtext-sm" pInputText />
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialogBuyer()"></button>
            <button [hidden]="isView" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text"
                (click)="saveBuyerInformation()"></button>
        </ng-template>
    </p-dialog>
    <div class="field col-6 md:col-6" [hidden]="isView" *ngIf="isLoad">
        <button type="button"  class="mr-2"  pButton [label]="title" (click)="onSubmit()"></button>
        <button type="button" class="mr-2" label="Close" (click)="ConfirmCloseClosing()" pButton  ></button>
    </div>
    <div class="field col-7 md:col-7" [hidden]="!isView" *ngIf="isLoad">
        <button type="button" class="mr-2" label="Close" (click)="closeClosing()" pButton  ></button>   
    </div>