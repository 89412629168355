<div class="card">
    <form [formGroup]="formContractor" (ngSubmit)="onSubmit()">

        <div class="p-fluid grid">

            <div class="field col-6 md:col-6">
                <label for="bankName">Asset Manager Id</label>
                <input [readonly]="contractor" type="number" name="contractorId" id="contractorId" pInputText
                    formControlName="contractorId">
            </div>

            <div class="field col-6 md:col-6">
                <label for="name">Name</label>
                <input type="text" name="name" id="name" pInputText formControlName="name">
            </div>
            <div class="field col-6 md:col-6">
                <label for="bankCity">PhoneNumber</label>
                <p-inputMask mask="+1(999)-999-9999" class="p-inputtext-sm" name="phoneNumber" id="phoneNumber" formControlName="phoneNumber">
                </p-inputMask>
            </div>

            <div class="field col-6 md:col-6">
                <label for="Zipcode">Zipcode</label>
                <input type="input" formControlName="zipcode"  maxlength="5"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                minlength="5" id="zipCodeProperty" onkeyup="validateZipCode()"
                class="p-inputtext-sm" pInputText />
            </div>

            <div *ngIf="contractor" class="field col-6 md:col-6">
                <label for="state">State</label>
                <p-dropdown [options]="states" [styleClass]="'squ-primeng-dropdowlist'" name="selectedGenderName"
                    ngValue="state" formControlName="state" optionLabel="stateName"></p-dropdown>

            </div>
            <div *ngIf="!contractor" class="field col-6 md:col-6">
                <label for="state">State</label>
                <select pInputText id="state" name="state" formControlName="state">
                    <option value="">Choose State</option>
                    <option *ngFor="let state of states" [ngValue]="state">
                        {{ state.stateName }}
                    </option>
                </select>
            </div>
            <div class="field col-6 md:col-6">
                <label for="city">City</label>
                <input type="text" pInputText id="city" name="city" formControlName="city">

            </div>
            <div class="field col-6 md:col-6">
                <label for="address">Address</label>
                <input type="text" pInputText id="address" name="address" formControlName="address">
            </div>
        </div>
        <br />

        <div class="field col-6 md:col-6">
            <button *ngIf="this.isEdit== false" class="mr-2" type="submit" [disabled]="formContractor.invalid" pButton
                label="Save"></button>
            <button *ngIf="this.isEdit== true" class="mr-2" type="submit" [disabled]="formContractor.invalid" pButton
                label="Update"></button>
            <button type="button" pButton label="Cancel" (click)="Cancel()"></button>
        </div>
    </form>
</div>