<div class="topbar clearfix">
    <div class="logo">
        <a href="#">
            <img alt="" src="assets/img/logo_round.png" />
        </a>
    </div>
    <a href="#">
        <img  alt="" src="assets/img/logo_main.png" class="app-name" />
    </a>

    <a id="topbar-menu-button" href="#" (click)="appMain.onTopbarMenuButtonClick($event)">
        <em class="pi pi-bars"></em>
    </a>

    <ul class="topbar-menu fadeInDown" [ngClass]="{ 'topbar-menu-visible': appMain.topbarMenuActive }">
        <li #profile class="profile-item" [ngClass]="{
          'active-topmenuitem': appMain.activeTopbarItem === profile
        }">
            <a href="#" (click)="appMain.onTopbarItemClick($event, profile)">
                <div class="profile-image">
                    <img alt="" src="assets/layout/images/avatar.png" />
                </div>
                <div class="profile-info">
                    <span class="topbar-item-name profile-name">{{user}}</span>
                  
                </div>
            </a>

            <ul class="fadeInDown">
                <li role="menuitem">
                    <a href="#" (click)="cerrarSesion()">
                        <em class="pi pi-sign-out"></em>
                        <span>Log Out</span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</div>
<p-confirmDialog></p-confirmDialog>