import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common'
import { BankR3Element } from 'app/models/bankR3.model';
import { R3Element } from 'app/models/R3.model';
import { ContractorR3Element } from 'app/models/contractorR3.model';
import { extensionR3 } from 'app/models/extensionR3.model';
import { GenericElement } from 'app/models/generic.model';
import { NotaryR3Element } from 'app/models/notaryR3.model';
import { StateR3Element } from 'app/models/stateR3.model';
import { BankrequestsService } from 'app/services/bankServices/bankrequests.service';
import { R3requestsService } from 'app/services/R3Services/R3.service';
import { ContractorRequestServiceService } from 'app/services/contractorServices/contractor-request-service.service';
import { NotaryRequestServiceService } from 'app/services/notaryServices/notary-request-service.service';
import { RequestsService } from 'app/services/requestServices/requests.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DashboardR3Component } from 'app/dashboardR3/dashboardR3.component';
import { BuyerInformationR3Element } from 'app/models/buyerInformationR3.model';
import { PostInformationR3Element } from 'app/models/postClosingInformationR3.model';
import { SellingAgentElement } from 'app/models/sellingAgent.model';
import { ListingAgentElement } from 'app/models/listingAgent.model';
import { ListingAgentServicesService } from 'app/services/listingAgentServices/listing-agent-services.service';
import { SellingAgentServicesService } from 'app/services/sellingAgentServices/selling-agent-services.service';
import { KeycloakService } from 'keycloak-angular';
import { SellerInformationR3Element } from 'app/models/buyerInformationR3.model copy';
import { ClosingProcessorService } from 'app/services/closingProcessorServices/closing-processor.service';
import { ClosingProcessorElement } from 'app/models/closingProcessor.model';

@Component({
  selector: 'app-R3',
  templateUrl: './R3.component.html',
  styleUrls: ['./R3.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class R3Component implements OnInit {

  public R3: R3Element;

  public R3s: R3Element[];

  public selectedR3s: R3Element[];

  public extensionR3sBack: extensionR3[] = [];

  public extensionR3s: extensionR3[] = [];

  public extensionR3: extensionR3;

  public selectedExtensionR3s: extensionR3[];

  public postClosingR3s: PostInformationR3Element[] = [];

  public postClosingR3: PostInformationR3Element;

  public selectedPostClosingR3s: PostInformationR3Element[];

  public buyerInformations: BuyerInformationR3Element[] = [];

  public buyerInformation: BuyerInformationR3Element;

  public sellerInformation: SellerInformationR3Element;

  public selectedBuyerInformations: BuyerInformationR3Element[];

  public deedInformations: PostInformationR3Element[] = [];

  public deedInformation: PostInformationR3Element;

  public selectedDeedInformations: PostInformationR3Element[];

  public submitted: boolean = true;

  public minValueZipCode: boolean = true;

  public dialog: boolean;

  public banks: BankR3Element[];

  public states: StateR3Element[];

  public contractors: ContractorR3Element[];

  public notaries: NotaryR3Element[];

  public notary : NotaryR3Element;

  public methods: GenericElement[];

  public selectMethod: string;

  public typesProperty: GenericElement[];

  public selectTypeProperty: GenericElement;

  public typesBuyer: GenericElement[];

  public title = "Save Closing R3";

  public isView: boolean;

  public isEdit: boolean;

  public r3FileNumber: number;

  public isLoad: boolean = true;

  public isContactInformation: boolean = false;

  public datepipe: DatePipe;

  public dialogBuyer: boolean = false;

  public dialogPost: boolean = false;

  public earnestMoneyDispositions: GenericElement[];

  public selectEarnestMoneyDisposition: GenericElement;

  public lenderDisabled: boolean = true;

  public letter: string = '';

  public disabledr3FileNumber: boolean = true;

  public sellingAgents: SellingAgentElement[];

  public listingAgents: ListingAgentElement[];

  public closingProcessors: ClosingProcessorElement[];

  public editBuyer: boolean = false;

  public editPost: boolean = false;

  public isAdmin: boolean = false;

  public editExtension: boolean = false;

  public hidenBuyerInformation: boolean = false;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private bankService: BankrequestsService,
    private stateService: RequestsService,
    private contractorService: ContractorRequestServiceService,
    private notaryService: NotaryRequestServiceService,
    public R3Service: R3requestsService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dashboard: DashboardR3Component,
    private listingAgentService: ListingAgentServicesService,
    private sellingAgentService: SellingAgentServicesService,
    private closingProcessorService: ClosingProcessorService,
    private keycloakService: KeycloakService
  ) {
    if (config.data) {
      this.r3FileNumber = (config.data.r3FileNumber);
      this.isLoad = false;
      this.loadR3(this.r3FileNumber);
      this.isView = (config.data.isView);
      this.isEdit = (config.data.isEdit);

    }
    if (!this.R3) {
      this.R3 = {};
      this.R3.propertyInformation = {};
      this.R3.propertyInformation.state = {};
      this.R3.financials = {};
      this.R3.financials.closingAgent = {};
      this.R3.postClosingInformation = [];
      this.R3.closingInformation = {};
      this.R3.closingInformation.closingInformationExtentionInformationDTO = [];
      this.R3.preClosingInformation = {};
      this.R3.preClosingInformation.programManager = {};
      this.R3.sellerInformation = {};
      this.R3.buyerInformation = [];
      this.R3.active = true;
      this.R3.postClosingComplete = false;
    }
  }


  ngOnInit(): void {
    this.validarIsAdmin();
    this.loadBanks();
    this.loadStates();
    this.loadContractors();
    this.loadNotaries();
    this.loadMethods();
    this.loadTypesBuyer();
    this.loadEarnest();
    this.loadTypesProperty();
    this.loadListingAgents();
    this.loadSellingAgents();
    this.loadClosingProcessors();

    if (this.isEdit) {
      this.title = "Update";
    }

  }


  validarIsAdmin() {
    if (this.keycloakService.isUserInRole("closingAdmin") || this.keycloakService.isUserInRole("closingSuperUser")
    ) {
      this.isAdmin = true;
    }
    else {
      this.isAdmin = false;
    }
  }

  loadTypesProperty() {
    this.typesProperty = [
      { name: 'Damage', value: 'damage' },
      { name: 'Replacement', value: 'replacement' },
    ]
  }
  loadMethods() {
    this.methods = [
      { name: 'Cash', value: 'cash' },
      { name: 'Financed', value: 'financed' },
      { name: 'Voucher', value: 'voucher' },
    ]
  }
  loadEarnest() {
    this.earnestMoneyDispositions = [
      { name: 'Forfeit', value: 'forfeit' },
      { name: 'Refund', value: 'refund' },
    ]
  }
  loadTypesBuyer() {
    this.typesBuyer = [
      { name: 'Occupant', value: 'occupant' },
    ]
  }

  loadListingAgents() {
    setTimeout(() => {
      this.listingAgentService.getListingAgents().subscribe(res => {
        this.listingAgents = [];
        if (res) {
          this.listingAgents = res;
        }
      })
    }, 1000);
  }

  loadSellingAgents() {
    setTimeout(() => {
      this.sellingAgentService.getSellingAgents().subscribe(res => {
        this.sellingAgents = [];
        if (res) {
          this.sellingAgents = res;
        }
      })
    }, 1000);
  }
  
  loadClosingProcessors() {
    setTimeout(() => {
      this.closingProcessorService.getClosingProcessors().subscribe(res => {
        this.closingProcessors = [];
        if (res) {
          this.closingProcessors = res;
        }
      })
    }, 1000);
  }

  loadR3(r3FileNumber) {
    setTimeout(() => {
      this.R3Service.getR3(r3FileNumber).subscribe(res => {
        this.R3 = {};
        if (res) {
          this.R3 = res;
          //this.selectMethod = this.R3.preClosingInformation.methodOfSale;
          this.notary = {};
          this.extensionR3 = {};
          this.extensionR3s = [];
          this.extensionR3sBack = [];
          this.extensionR3s = this.R3.closingInformation.closingInformationExtentionInformationDTO;
          this.extensionR3sBack = this.extensionR3s;
          this.buyerInformations = [];
          this.buyerInformations = this.R3.buyerInformation;
          this.selectTypeProperty = {};

          //this.selectTypeProperty.value = this.R3.typeOfProperty;
          this.buyerInformations = this.R3.buyerInformation;
          this.postClosingR3s = [];
          this.postClosingR3s = this.R3.postClosingInformation;
          this.datepipe = new DatePipe('en-US');
          this.R3.r3ContractDate = (res.r3ContractDate != null ? new Date(this.datepipe.transform(res.r3ContractDate, 'MM/dd/yyyy')) : null);
          this.R3.closingInformation.scheduleClosingDate = (res.closingInformation.scheduleClosingDate != null ? new Date(this.datepipe.transform(res.closingInformation.scheduleClosingDate, 'MM/dd/yyyy')) : null);
          this.R3.closingInformation.actualClosingDate = (res.closingInformation.actualClosingDate != null ? new Date(this.datepipe.transform(res.closingInformation.actualClosingDate, 'MM/dd/yyyy')) : null);
          this.R3.closingInformation.closingCancelledDate = (res.closingInformation.closingCancelledDate != null ? new Date(this.datepipe.transform(res.closingInformation.closingCancelledDate, 'MM/dd/yyyy')) : null);
          this.R3.closingInformation.receivedPackageDate = (res.closingInformation.receivedPackageDate != null ? new Date(this.datepipe.transform(res.closingInformation.receivedPackageDate, 'MM/dd/yyyy')) : null);


          if ((res.closingInformation.closingCancelledDate != null || res.closingInformation.actualClosingDate != null) && (this.isAdmin != true)) {
            this.isEdit = false;
            this.isView = true;
          }
          this.isLoad = true;
        }
      })
    }, 1000);
  }
  loadBanks() {
    setTimeout(() => {
      this.bankService.getBanks().subscribe(res => {
        this.banks = [];
        if (res) {
          this.banks = res;
        }
      })
    }, 1000);
  }
  loadStates() {

    setTimeout(() => {
      this.stateService.getStates().subscribe(res => {
        this.states = [];
        if (res) {
          this.states = res;
          this.R3.propertyInformation.state = this.states.filter(val => val.stateId == "PR")[0];
          this.R3.sellerInformation.state = this.states.filter(val => val.stateId == "PR")[0];
        }
      })
    }, 1000);
  }
  loadContractors() {

    setTimeout(() => {
      this.contractorService.getContractors().subscribe(res => {
        this.contractors = [];
        if (res) {
          this.contractors = res;
        }
      })
    }, 1000);
  }
  loadNotaries() {

    setTimeout(() => {
      this.notaryService.getNotaries().subscribe(res => {
        this.notaries = [];
        if (res) {
          this.notaries = res;
        }
      })
    }, 1000);
  }


  deleteExtensionR3(extensionR3: extensionR3) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.extensionR3s = this.extensionR3s.filter(val => val.extentionClosingDate !== extensionR3.extentionClosingDate);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Extension R3 Deleted', life: 3000 });
      }
    });
  }
  deleteSelectedExtensionR3s() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Extension R3s',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.extensionR3s = this.extensionR3s.filter(val => !this.selectedExtensionR3s.includes(val));
        this.selectedExtensionR3s = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'ExtensionR3s Deleted', life: 3000 });
      }
    });
  }

  deletePostClosingR3(postClosingR3: PostInformationR3Element) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.postClosingR3s = this.postClosingR3s.filter(val => val.deedNumber !== postClosingR3.deedNumber);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'postClosing R3 Deleted', life: 3000 });
      }
    });
  }

  deleteselectedPostClosingR3s() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected postClosing R3s',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.postClosingR3s = this.postClosingR3s.filter(val => !this.selectedPostClosingR3s.includes(val));
        this.selectedPostClosingR3s = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'postClosingR3s Deleted', life: 3000 });
      }
    });
  }
  public openDialog() {
    this.extensionR3 = {};
    this.dialog = true;
  }


  public openDialogPost() {
    this.postClosingR3 = {};
    this.postClosingR3.notaryId = {};
    this.dialogPost = true;
  }

  public openDialogBuyer() {
    this.buyerInformation = {};
    this.buyerInformation.state = this.states.filter(val => val.stateId == "PR")[0];
    this.dialogBuyer = true;
  }


  hideDialog() {
    this.dialog = false;
    this.editExtension = false;
  }

  hideDialogBuyer() {
    this.dialogBuyer = false;
    this.editBuyer = false;

  }

  editBuyerInformation(buyerInformation: BuyerInformationR3Element) {
    this.buyerInformation = { ...buyerInformation };
    this.dialogBuyer = true;
    this.editBuyer = true;
  }

  editPostClosingR3(postClosingR3: PostInformationR3Element) {
    this.postClosingR3 = { ...postClosingR3 };
    this.dialogPost = true;
    this.editPost = true;
  }

  hideDialogPost() {
    this.dialogPost = false;
  }

  editExtensionClosing(extensionR32: extensionR3) {
    this.extensionR3 = { ...extensionR32 };
    this.dialog = true;
    this.editExtension = true;
  }

  saveExtensionR3() {
    this.submitted = true;

    if (this.editExtension) {
      this.extensionR3s[this.findIndexByIdExtension(this.extensionR3.id)] = this.extensionR3;
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Extension R3 Update',
        life: 3000,
      });
    } else {
      this.extensionR3.id = this.extensionR3s.length + 1;
      this.extensionR3s.push(this.extensionR3);
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Extension R3 Create',
        life: 3000,
      });
    }

    this.extensionR3s = [...this.extensionR3s];
    this.dialog = false;
    this.editExtension = false;

  }

  savePostClosingR3() {
    this.submitted = true;
    console.log(this.postClosingR3)

    if (this.editPost) {
      this.postClosingR3s[this.findIndexByIdPostClosing(this.postClosingR3.id)] = this.postClosingR3;
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Post-Closing R3 Update',
        life: 3000,
      });
    } else {
      this.postClosingR3.id = this.postClosingR3s.length + 1;
      this.postClosingR3s.push(this.postClosingR3);
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Post-Closing R3 Information Create',
        life: 3000,
      });
    }

    this.postClosingR3s = [...this.postClosingR3s];
    this.dialogPost = false;
    this.editPost = false;

  }

  onSubmit() {

    var error = false;
    var errorMessage = "Error";

    if (this.extensionR3s) {
      this.R3.closingInformation.closingInformationExtentionInformationDTO = this.extensionR3s;
    }
    if (this.buyerInformations) {
      this.R3.buyerInformation = this.buyerInformations;
    }
    if (this.postClosingR3s) {
      this.R3.postClosingInformation = this.postClosingR3s;
    }
    if (!this.R3.r3FileNumber) {
      error = true;
      errorMessage = "FHA is required";
    } else if (this.R3.buyerInformation.length <= 0) {
      error = true;
      errorMessage = "Buyer Information is required";
    } else if (!this.R3.closingProcessor) {
      error = true;
      errorMessage = "R3 Processor is required";
    } /*else if (!this.R3.r3ContractDate) {
      error = true;
      errorMessage = "Contract Date is required";
    }*/ else if (!this.R3.propertyInformation.propertyAddress) {
      error = true;
      errorMessage = "Property Address is required";
    } else if (!this.R3.propertyInformation.city) {
      error = true;
      errorMessage = "City is required";
    } else if (!this.R3.propertyInformation.state.stateId) {
      error = true;
      errorMessage = "State is required";
    } else if (!this.R3.propertyInformation.zipCode) {
      error = true;
      errorMessage = "ZipCode is required";
    } else if (!this.R3.preClosingInformation.voucherAmount) {
      error = true;
      errorMessage = "Sale Amount is required";
    } else if (!this.R3.preClosingInformation.methodOfSale) {
      error = true;
      errorMessage = "Method Of Sale is required";
    } else if (!this.R3.preClosingInformation.programManager.contractorId) {
      error = true;
      errorMessage = "AM Contractor is required";
    }
    /** Setear el Status segun la fehca ingresada */
    if (this.R3.closingInformation.actualClosingDate) {
      this.R3.status = 'Closed';
    } else if (this.R3.closingInformation.closingCancelledDate) {
      this.R3.status = 'Cancelled';
    } else {
      this.R3.status = 'Open';
    }

    if (!error) {
      if (this.isEdit) {
        this.R3Service.updateR3s(this.R3).subscribe(_res => {
          this.ref.close(true);
        }, err => {
          this.messageAlert('error', 'Error', err.error.error);
        })
      } else {
        this.R3Service.addR3s(this.R3).subscribe(_res => {
          this.messageAlert('success', 'Successful', 'R3 is ADD');
          this.ref.close(true);
        }, err => {
          this.messageAlert('error', 'Error', err.error.error);
        })
      }
    } else {
      this.messageAlert('error', 'Error', errorMessage);
    }
  }

  messageAlert(severity: string, summary: string, detail: string) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  }

  Cancel() {
    this.ref.close();
  }



  ConfirmCloseR3() {
    this.isView = false;
    this.confirmationService.confirm({
      message: 'Do you want to save changes?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onSubmit();
      },
      reject: () => {
        this.ref.close();
      }
    });
  }

  closeR3() {
    this.ref.close();
  }

  changeTypeProperty() {
    if (this.R3.typeOfProperty == 'damage') {
      this.letter = 'PR-R3-D';
      this.disabledr3FileNumber = false;
      this.R3.r3FileNumber = null;
      this.hidenBuyerInformation = true;
      this.buyerInformations[0] = {};
      this.buyerInformations[0].state = {};
      this.buyerInformations[0].typeOfBuyer = 'occupant';
      this.buyerInformations[0].name = 'Departamento de Vivienda'; 
      this.buyerInformations[0].address = 'Ave. Ponce de León 958 pda. 15 1/2'; 
      this.buyerInformations[0].city = 'San Juan';
      this.buyerInformations[0].state.stateId = "PR";
      this.buyerInformations[0].state.stateName = "Puerto Rico"; 
      this.buyerInformations[0].zipcode = '00923';
      this.buyerInformations[0].phoneNumber = '787-759-9407';


    } else if (this.R3.typeOfProperty == 'replacement') {
      this.letter = 'PR-R3-R';
      this.R3.r3FileNumber = null;
      this.disabledr3FileNumber = false;
      this.hidenBuyerInformation = false;
      this.buyerInformation={};
      this.buyerInformations = this.buyerInformations.filter(val => val.id !== this.buyerInformation.id);

    } else {
      this.R3.r3FileNumber = null;
      this.disabledr3FileNumber = true;
    }
  }


  changeTab(e) {
    var n = e.index;

    console.warn(n);
    switch (n) {
      case 0:
        this.isContactInformation = false;
        break;
      case 1:
        this.isContactInformation = true;
        break;
      case 2:
        this.isContactInformation = true;
        break;
      case 3:
        this.isContactInformation = true;
        break;
      case 4:
        this.isContactInformation = true;
        break;
      default:
        this.isContactInformation = true;
    }


  }

  onKeydownLettersAndNumbers(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 90) || (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode == 8)) {
      return true;
    } else {
      return false;
    }
  }
  onKeydownLetters(event) {
    if ((event.keyCode >= 58 && event.keyCode <= 90) || (event.keyCode == 8)) {
      return true;
    } else {
      return false;
    }
  }
  onKeydownLettersSpacing(event) {
    if ((event.keyCode >= 58 && event.keyCode <= 90) || (event.keyCode == 8) || (event.keyCode == 32)) {
      return true;
    } else {
      return false;
    }
  }
  onKeydownNumbers(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode == 8)) {
      return true;
    } else {
      return false;
    }
  }
  onKeydownNumbersAndGuion(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode == 8) || (event.keyCode == 189 || event.keyCode == 109)) {
      return true;
    } else {
      return false;
    }
  }
  addFormatr3FileNumber() {
    let shorten_D = this.R3.r3FileNumber ? this.R3.r3FileNumber.substring(0, 7) : '';
    let shorten_ = this.R3.r3FileNumber ? this.R3.r3FileNumber.substring(7, 8) : '';
    if (shorten_D != this.letter && (shorten_ != "-")) {
      this.R3.r3FileNumber = this.letter + "-" + (this.R3.r3FileNumber ? this.R3.r3FileNumber : '');
    } else if (shorten_D == this.letter && (shorten_ != "-")) {
      this.R3.r3FileNumber = this.letter + "-" + (this.R3.r3FileNumber ? this.R3.r3FileNumber.substring(1) : '');
    }
  }
  deleteDevalNumber() {
    this.R3.r3FileNumber = '';
  }

  deleteBuyerInformation(buyerInformation2: BuyerInformationR3Element) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete Buyer Information ',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.buyerInformations = this.buyerInformations.filter(val => val.id !== buyerInformation2.id);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Buyer Information Deleted', life: 3000 });
      }
    });
  }
  deleteSelectedBuyerInformations() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected Buyer Information',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.buyerInformations = this.buyerInformations.filter(val => !this.selectedBuyerInformations.includes(val));
        this.selectedBuyerInformations = null;
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'BuyerInformations Deleted', life: 3000 });
      }
    });
  }

  saveBuyerInformation() {
    var error = false;
    var errorMessage = "";

    if (!this.buyerInformation.typeOfBuyer) {
      error = true;
      errorMessage = "Type Of Buyer is required";
    } else if (!this.buyerInformation.name) {
      error = true;
      errorMessage = "Name is required";
    }
    if (!error) {
      console.log('edit', this.editBuyer);
      console.log('buyer', this.buyerInformation);
      if (this.editBuyer) {
        this.buyerInformations[this.findIndexById(this.buyerInformation.id)] = this.buyerInformation;
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Buyer information Update',
          life: 3000,
        });
      } else {
        this.buyerInformation.id = this.buyerInformations.length + 1;
        this.buyerInformations.push(this.buyerInformation);
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Buyer Information Create',
          life: 3000,
        });
      }

      this.buyerInformations = [...this.buyerInformations];
      this.dialogBuyer = false;
      this.editBuyer = false;
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
        life: 3000,
      });
    }
  }

  findIndexById(id: number): number {
    let index = -1;
    for (let i = 0; i < this.buyerInformations.length; i++) {
      if (this.buyerInformations[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  findIndexByIdPostClosing(id: number): number {
    let index = -1;
    for (let i = 0; i < this.postClosingR3s.length; i++) {
      if (this.postClosingR3s[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  findIndexByIdExtension(id: number): number {
    let index = -1;
    for (let i = 0; i < this.extensionR3s.length; i++) {
      if (this.extensionR3s[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  validateZipCode() {
    var zipCodeBuyer = (<HTMLInputElement>document.getElementById("zipCodeBuyer")).value;
    var zipCodeProperty = (<HTMLInputElement>document.getElementById("zipCodeProperty")).value;

    if (zipCodeBuyer.length <= 4 || zipCodeProperty.length <= 4) {
      this.minValueZipCode = true;
    }
    else {
      this.minValueZipCode = false;
    }
  }

  changeActual() {
    console.log(this.extensionR3sBack);
    this.extensionR3s = this.extensionR3sBack;
  }

}
