import {APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http'; 
import {FormsModule,ReactiveFormsModule } from "@angular/forms"
import {MatInputModule} from '@angular/material/input';
import {BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ToggleButtonModule } from 'primeng/togglebutton';
import {TooltipModule} from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { ToolbarModule } from 'primeng/toolbar';
import { RatingModule } from 'primeng/rating';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {AvatarModule} from 'primeng/avatar';
import {SlideMenuModule} from 'primeng/slidemenu';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {PasswordModule} from 'primeng/password';
import {InputMaskModule} from 'primeng/inputmask';
import {TabViewModule} from 'primeng/tabview';
import {InputSwitchModule} from 'primeng/inputswitch';
import {SelectButtonModule} from 'primeng/selectbutton';
import {PanelMenuModule} from 'primeng/panelmenu';
import {FieldsetModule} from 'primeng/fieldset';
import {AccordionModule} from 'primeng/accordion';
import {CardModule} from 'primeng/card';
import {CheckboxModule} from 'primeng/checkbox';
import {ListboxModule} from 'primeng/listbox';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { StatesListComponent } from './states-list/states-list.component';
import { BanksComponent } from './banks/banks.component';
import { MatDialogModule} from '@angular/material/dialog';
import { ClosingComponent } from './closing/closing.component';
import { MatTableModule } from '@angular/material/table' ;
import { AppMainComponent } from './layout/main/app.main.component';
import { AppSideBarComponent } from './layout/sidebar/app.sidebar.component';
import { AppFooterComponent } from './layout/footer/app.footer.component';
import { AppTopbarComponent } from './layout/topbar/app.topbar.component';
import { AppConfigComponent } from './layout/config/app.config.component';
import { AppSidebartabcontentComponent } from './layout/sidebar/app.sidebartabcontent.component';
import { AppMenuComponent } from './layout/menu/app.menu.component';
import { AppMenuitemComponent } from './layout/menu/app.menuitem.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { DialogModule } from "primeng/dialog";
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import { BankModalComponent } from './bank-modal/bank-modal.component';
import { ConfirmationService, MessageService  } from 'primeng/api';
import { NotaryComponent } from './notary/notary.component';
import { NotaryModalComponent } from './notary-modal/notary-modal.component';
import { MenuService } from './layout/menu/app.menu.service';
import {PanelModule} from 'primeng/panel';
import {DividerModule} from 'primeng/divider';
import { StatesComponent } from './states/states.component';
import { StateModalComponent } from './state-modal/state-modal.component';
import { initializer } from '../utils/app-init';
import { ContractorComponent } from './contractor/contractor.component';
import { ContractorModalComponent } from './contractor-modal/contractor-modal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GraphQLModule } from './graphql.module';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { TagModule } from 'primeng/tag';
import {SpeedDialModule} from 'primeng/speeddial';
import { R3Component } from './R3/R3.component';
import { DashboardR3Component } from './dashboardR3/dashboardR3.component';
import { SellingAgentComponent } from './selling-agent/selling-agent.component';
import { ListingAgentComponent } from './listing-agent/listing-agent.component';
import { ListingAgentModalComponent } from './listing-agent-modal/listing-agent-modal.component';
import { SellingAgentModalComponent } from './selling-agent-modal/selling-agent-modal.component';
import { NotaryInvoiceComponent } from './notary-invoice/notary-invoice.component';
import { NotaryInvoiceModalComponent } from './notary-invoice-modal/notary-invoice-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    StatesListComponent,
    BanksComponent,
    ClosingComponent,
    AppMainComponent,
    AppSideBarComponent,
    AppFooterComponent,
    AppTopbarComponent,
    AppConfigComponent,
    AppSidebartabcontentComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    ClosingComponent,
    BankModalComponent,
    NotaryComponent,
    NotaryModalComponent,
    StatesComponent,
    StateModalComponent,
    ContractorComponent,
    ContractorModalComponent,
    DashboardComponent,
    R3Component,
    DashboardR3Component,
    SellingAgentComponent,
    ListingAgentComponent,
    ListingAgentModalComponent,
    SellingAgentModalComponent,
    NotaryInvoiceComponent,
    NotaryInvoiceModalComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatDialogModule,
    TableModule,
    ToastModule,
    CalendarModule,
    SliderModule,
    MultiSelectModule,
    ButtonModule,
    DropdownModule,
    ProgressBarModule,
    InputTextModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    AvatarModule,
    SlideMenuModule,
    TieredMenuModule,
    VirtualScrollerModule,
    ScrollPanelModule,
    PasswordModule,
    InputMaskModule,
    TabViewModule,
    InputSwitchModule,
    SelectButtonModule,
    PanelMenuModule,
    FieldsetModule,
    AccordionModule,
    TooltipModule,
    CardModule,
    ToggleButtonModule,
    CheckboxModule,
    ListboxModule,
    CommonModule,
    DialogModule ,
    DynamicDialogModule,
    ReactiveFormsModule,
    PanelModule,
    DividerModule,
    KeycloakAngularModule,
    GraphQLModule,
    ProgressSpinnerModule,
    TagModule,
    SpeedDialModule
  ],
  providers: [ConfirmationService, MessageService, MenuService,
    {
    provide: APP_INITIALIZER,
    useFactory: initializer,
    deps: [KeycloakService],
    multi: true,
  }
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
