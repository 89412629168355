import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ClosingProcessorElement } from 'app/models/closingProcessor.model';

@Injectable({
  providedIn: 'root'
})
export class ClosingProcessorService {
  url:string = environment.urlBase
  constructor(private http: HttpClient) { }
  headers: HttpHeaders = new HttpHeaders({
    "Content-type": "application/json",
    "accept": "*"
  });
  
  getClosingProcessors() {
    let url_closingProcessor = this.url+'users/closing-processor';
    return this.http.get<ClosingProcessorElement[]>(url_closingProcessor,{headers: this.headers});
  }

}
