import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppMainComponent } from '../main/app.main.component';
import { MenuService } from './app.menu.service';


@Component({
    /* tslint:disable:component-selector */
    selector: '[app-menuitem]',
    /* tslint:enable:component-selector */
    templateUrl: './app.menuitem.component.html',
    host: {
        '[class.active-menuitem]': 'active'
    },
    animations: [
        trigger('children', [
            state('void', style({
                height: '0px'
            })),
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('void => visibleAnimated, visibleAnimated => void',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppMenuitemComponent implements OnInit, OnDestroy {

    @Input() item: any;

    @Input() index: number;

    @Input() root: boolean;

    @Input() parentKey: string;

    hover: boolean;

    active = false;

    menuSourceSubscription: Subscription;

    menuResetSubscription: Subscription;

    key: string;

    constructor(
        public appMain: AppMainComponent,
        public router: Router,
        private cd: ChangeDetectorRef,
        private menuService: MenuService,
        
    ) {
        this.menuSourceSubscription = this.menuService.menuSource$.subscribe(key => {
            // deactivate current active menu
            if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
                this.active = false;
            }
        });

        this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
            this.active = false;
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(params => {
                if (this.item.routerLink) {
                    this.updateActiveStateFromRoute();
                } else {
                    this.active = false;
                }
            });
    }

    ngOnInit() {
        this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);
    }
    ngOnDestroy() {
        // @ts-ignore: Object is possibly 'null'
        if (this.menuSourceSubscription) {
            // @ts-ignore: Object is possibly 'null'
            this.menuSourceSubscription.unsubscribe();
        }
        // @ts-ignore: Object is possibly 'null'
        if (this.menuResetSubscription) {
            // @ts-ignore: Object is possibly 'null'
            this.menuResetSubscription.unsubscribe();
        }

    }

    updateActiveStateFromRoute() {
        this.active = this.router.isActive(this.item.routerLink[0], this.item.items ? false : true);
    }

    itemClick(event: Event): boolean {
        // avoid processing disabled items
        // @ts-ignore: Object is possibly 'null'
        if (this.item.disabled) {
            event.preventDefault();
            return true;
        }
        // notify other items
        // @ts-ignore: Object is possibly 'null'
        this.menuService.onMenuStateChange(this.key);

        // execute command
        // @ts-ignore: Object is possibly 'null'
        if (this.item.command) {
            // @ts-ignore: Object is possibly 'null'
            this.item.command({ originalEvent: event, item: this.item });
        }

        // toggle active state
        // @ts-ignore: Object is possibly 'null'
        if (this.item.items) {
            // @ts-ignore: Object is possibly 'null'
            this.active = !this.active;
        } else {
            // activate item
            // @ts-ignore: Object is possibly 'null'
            this.active = true;

            // hide overlay menus
            // @ts-ignore: Object is possibly 'null'
            if (this.appMain.overlay || !this.appMain.isDesktop()) {
                // @ts-ignore: Object is possibly 'null'
                this.appMain.sidebarActive = false;
            }
        }

    }

}
