<p-toast></p-toast>
<p-progressSpinner *ngIf="!isLoad" style="margin-left: 50%; transform: translateX(-50%);"></p-progressSpinner>
<p-tabView *ngIf="isLoad">
    <p-tabView (onChange)="changeTab($event)">
        <!--Cabecera fija en todas las pestañas-->
        <div class="grid p-fluid" style="border: 1px;">
            <div class="field col-12 md:col-3">
                <label for="integeronly">Type of Property</label>
                <p-dropdown (onChange)="changeTypeProperty()" [disabled]="isView || isContactInformation" [options]="typesProperty"
                    optionLabel="name" styleClass="form-control" [(ngModel)]="R3.typeOfProperty" optionValue="value"
                    placeholder="TypeProperty of Sale" id="id" class="p-inputtext-sm">
                </p-dropdown>
                <small class="p-error" *ngIf="submitted && !R3.typeOfProperty">Type of Property is required.</small>
            </div>
            <div class="field col-12 md:col-3">
                <label for="integeronly">R3 File Number</label>
                <div class="p-inputgroup">
                    <input type="input" (keydown)="onKeydownNumbersAndGuion($event)" (keypress)="addFormatr3FileNumber()"
                        [readonly]="isView" [disabled]="isContactInformation || disabledr3FileNumber"
                        class="p-inputtext-sm" [(ngModel)]="R3.r3FileNumber" pInputText />
                    <button [disabled]="isContactInformation || disabledr3FileNumber" type="button"
                        (click)="deleteDevalNumber()" *ngIf="!isView || !isContactInformation" pButton pRipple
                        icon="pi pi-times" styleClass="p-button-danger"></button>
                </div>
                <small class="p-error" *ngIf="submitted && !R3.r3FileNumber">R3 File Number is
                    required.</small>
            </div>
            <div class="field col-12 md:col-3">
                <label for="integeronly">Closing Processor</label>
                <p-dropdown [readonly]="isView" [disabled]="isContactInformation" [options]="closingProcessors" 
                    optionLabel="fullName" styleClass="form-control" [(ngModel)]="R3.closingProcessor" 
                    placeholder="ClosingProcessors" class="p-inputtext-sm">
                </p-dropdown>
                <small class="p-error" *ngIf="submitted && !R3.closingProcessor">Closing Processor is
                    required.</small>
            </div>
            <div class="field col-12 md:col-3">
                <label for="integeronly">R3 Contract Date</label>
                <p-calendar [disabled]="isView" [disabled]="isContactInformation" [disabled]="isView" inputId="calendar"
                    placeholder="mm/dd/yyyy" class=" ng-dirty p-inputtext-sm" [(ngModel)]="R3.r3ContractDate"
                    [showIcon]="true">
                </p-calendar>
               <!-- <small class="p-error" *ngIf="submitted && !R3.r3ContractDate">Contract Date is required.</small>-->
            </div>
        </div>

        <p-tabPanel header="Closing Contact Information">
            <p-divider align="center">
                <span class="p-tag">Closing Contact Information</span>
            </p-divider>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <p-panel>
                        <ng-template pTemplate="header">
                            Property Information
                        </ng-template>
                        <div class="grid p-fluid">
                            <div class="field col-12 md:col-6">
                                <label for="integeronly">Property Address</label>
                                <input type="input" [readonly]="isView"
                                    [(ngModel)]="R3.propertyInformation.propertyAddress" class="p-inputtext-sm"
                                    pInputText />
                                <small class="p-error"
                                    *ngIf="submitted && !R3.propertyInformation.propertyAddress">Property Address
                                    is
                                    required.</small>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="label">City </label>
                                <input type="input" [readonly]="isView" [(ngModel)]="R3.propertyInformation.city"
                                    class="p-inputtext-sm" pInputText />
                                <small class="p-error" *ngIf="submitted && !R3.propertyInformation.city">City is
                                    required.</small>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="integeronly">State</label>
                                <p-dropdown [readonly]="isView" [readonly]="isView" [options]="states"
                                    optionLabel="stateName" styleClass="form-control"
                                    [(ngModel)]="R3.propertyInformation.state" placeholder="State" id="stateId"
                                    name="stateId" dataKey="stateId" class="p-inputtext-sm">
                                </p-dropdown>
                                <small class="p-error" *ngIf="submitted && !R3.propertyInformation.state.stateId">State
                                    is
                                    required.</small>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="integeronly">ZipCode</label>
                                <input type="input" maxlength="5" [readonly]="isView" [(ngModel)]="R3.propertyInformation.zipCode"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    minlength="5" id="zipCodeProperty" onkeyup="validateZipCode()"
                                    class="p-inputtext-sm" pInputText />
                                <small class="p-error" *ngIf="submitted && !R3.propertyInformation.zipCode">ZipCode
                                    is required.</small>
                            </div>
                        </div>
                        <ng-template pTemplate="footer">

                        </ng-template>
                    </p-panel>
                </div>
                <div class="field col-12 md:col-6">
                    <p-panel>
                        <ng-template pTemplate="header">
                            Buyer Information
                        </ng-template>
                        <p-table #dt [value]="buyerInformations" [(selection)]="selectedBuyerInformations">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 3rem" id=" ">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th id=" ">Name </th>
                                    <th id=" ">Type </th>
                                    <th [hidden]="isView" id=" "></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-buyerInformation>
                                <tr>
                                    <td>
                                        <p-tableCheckbox [value]="buyerInformation"></p-tableCheckbox>
                                    </td>
                                    <td>{{( (buyerInformation.name ? buyerInformation.name : '') + " " +
                                        (buyerInformation.middleName ? buyerInformation.middleName : '' ) + " " +
                                        (buyerInformation.lastName? buyerInformation.lastName : '') )}}</td>
                                    <td>{{buyerInformation.typeOfBuyer}}</td>
                                    <td [hidden]="isView">
                                        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded  mr-2"
                                            (click)="editBuyerInformation(buyerInformation)"></button>
                                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded "
                                            (click)="deleteBuyerInformation(buyerInformation)"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">
                                <p-toolbar styleClass="mb-4">
                                    <ng-template pTemplate="left">

                                    </ng-template>
                                    <ng-template pTemplate="right">
                                        <div [hidden]="isView || hidenBuyerInformation">
                                            <button type="button" (click)="openDialogBuyer()" pButton icon="pi pi-plus"
                                                class="mr-2"></button>&nbsp;
                                            <button pButton pRipple icon="pi pi-trash" class="p-button-danger"
                                                (click)="deleteSelectedBuyerInformations()"
                                                [disabled]="!selectedBuyerInformations || !selectedBuyerInformations.length"></button>
                                        </div>
                                    </ng-template>
                                </p-toolbar>
                            </ng-template>
                        </p-table>
                        <ng-template pTemplate="footer">

                        </ng-template>
                    </p-panel>
                </div>
                <div class="field col-12 md:col-6">
                    <p-panel>
                        <ng-template pTemplate="header">
                            Seller Information
                        </ng-template>
                        <div class="grid p-fluid">
                            <div class="field col-12 md:col-6">
                                <label for="label">Name </label>
                                <input type="input" (keydown)="onKeydownLettersSpacing($event)" [readonly]="isView"
                                    [(ngModel)]="R3.sellerInformation.name" class="p-inputtext-sm" pInputText />
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="label">Last name </label>
                                <input type="input" (keydown)="onKeydownLettersSpacing($event)" [readonly]="isView"
                                    [(ngModel)]="R3.sellerInformation.lastName" class="p-inputtext-sm" pInputText />
            
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="label">Address </label>
                                <input type="input" [readonly]="isView" [(ngModel)]="R3.sellerInformation.address"
                                    class="p-inputtext-sm" pInputText />
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="label">City </label>
                                <input type="input" [readonly]="isView" [(ngModel)]="R3.sellerInformation.city" class="p-inputtext-sm"
                                    pInputText />
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="integeronly">State</label>
                                <p-dropdown [readonly]="isView" [options]="states" optionLabel="stateName" styleClass="form-control"
                                    [(ngModel)]="R3.sellerInformation.state" placeholder="State" id="stateId" name="stateId"
                                    dataKey="stateId" class="p-inputtext-sm">
                                </p-dropdown>
                            </div>
                            <div class="field col-12 md:col-6">
                                <label for="integeronly">ZipCode</label>
                                <input type="input" maxlength="5" [readonly]="isView" [(ngModel)]="R3.sellerInformation.zipcode"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    minlength="5" id="zipCodeBuyer" onkeyup="validateZipCode()" class="p-inputtext-sm" pInputText />
                            </div>
                        </div>
                        <ng-template pTemplate="footer">

                        </ng-template>
                    </p-panel>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Pre-Closing Information">
            <p-divider align="center">
                <span class="p-tag">Pre-Closing Information</span>
            </p-divider>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-4">
                    <label class="label">Voucher Amount </label>
                    <p-inputNumber [readonly]="isView" mode="currency" currency="USD"
                        locale="en-US" [(ngModel)]="R3.preClosingInformation.voucherAmount" class="p-inputtext-sm">
                    </p-inputNumber>
                    <small class="p-error" *ngIf="submitted && !R3.preClosingInformation.voucherAmount">Sale Amount is
                        required.</small>
                </div>

                <div class="field col-12 md:col-4">
                    <label for="integeronly">Method of Sale</label>
                    <p-dropdown  [readonly]="isView" [options]="methods" optionLabel="name" optionValue="value"
                        styleClass="form-control" [(ngModel)]="R3.preClosingInformation.methodOfSale" placeholder="Method of Sale" class="p-inputtext-sm">
                    </p-dropdown>
                    <small class="p-error" *ngIf="submitted && !R3.preClosingInformation.methodOfSale">Method of Sale is required.</small>
                </div>

                <div class="field col-12 md:col-4">
                    <label class="label">Program Manager </label>
                    <p-dropdown [readonly]="isView" [options]="contractors" optionLabel="name" styleClass="form-control"
                        [(ngModel)]="R3.preClosingInformation.programManager" placeholder="Contractors" id="contractorId"
                        name="contractorId" dataKey="contractorId" class="p-inputtext-sm">
                    </p-dropdown>
                    <small class="p-error" *ngIf="submitted && !R3.preClosingInformation.programManager.contractorId">AM
                        Contractor is
                        required.</small>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Closing Information">
            <p-divider align="center">
                <span class="p-tag">Closing Information</span>
            </p-divider>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-6">
                            <label class="label">Listing Agent</label>
                            <p-dropdown  [readonly]="isView" [options]="listingAgents" optionLabel="fullName" 
                                styleClass="form-control" [(ngModel)]="R3.closingInformation.listingAgent" placeholder="ListingAgents" class="p-inputtext-sm">
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="label">Selling Agent</label>
                            <p-dropdown [readonly]="isView" [options]="sellingAgents" optionLabel="fullName" styleClass="form-control"
                                    [(ngModel)]="R3.closingInformation.sellingAgent" placeholder="SellingAgents" class="p-inputtext-sm">
                            </p-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="integeronly">Received Package Date</label>
                            <p-calendar [disabled]="isView" inputId="calendar" placeholder="mm/dd/yyyy"
                                class=" ng-dirty p-inputtext-sm" [(ngModel)]="R3.closingInformation.receivedPackageDate" [showIcon]="true">
                            </p-calendar>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="label">Scheduled Closing Date:
                                (Leave blank if closing
                                date not scheduled) </label>
                            <p-calendar  
                                [disabled]="isView"
                                inputId="calendar" [(ngModel)]="R3.closingInformation.scheduleClosingDate" placeholder="mm/dd/yyyy"
                                class=" ng-dirty p-inputtext-sm" [showIcon]="true">
                            </p-calendar>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="label">Actual Closing Date:
                                (Leave blank if you have
                                not closed yet) </label>
                            <p-calendar  (onSelect)="changeActual()"
                                [disabled]="isView || (R3.closingInformation.closingCancelledDate != null) "
                                inputId="calendar" [(ngModel)]="R3.closingInformation.actualClosingDate" placeholder="mm/dd/yyyy"
                                class=" ng-dirty p-inputtext-sm" [showIcon]="true">
                            </p-calendar>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label class="label">Closing Cancelled Date:
                                (Leave blank if closing is
                                not cancelled.
                            </label>
                            <p-calendar 
                                [disabled]="isView || (R3.closingInformation.actualClosingDate != null)"
                                inputId="calendar" [(ngModel)]="R3.closingInformation.closingCancelledDate" placeholder="mm/dd/yyyy"
                                class=" ng-dirty p-inputtext-sm" [showIcon]="true">
                            </p-calendar>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-6">
                    <div class="field col-12 md:col-12">
                        <p-table #dt [value]="extensionR3s" [(selection)]="selectedExtensionR3s">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 3rem" id=" ">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th id=" ">Extension Closing Date</th>
                                    <th id=" ">Comment</th>
                                    <th id=" ">Waived</th>
                                    <th [hidden]="isView" id=" "></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-extensionR3>
                                <tr>
                                    <td>
                                        <p-tableCheckbox [value]="extensionR3"></p-tableCheckbox>
                                    </td>
                                    <td>{{extensionR3.extentionClosingDate | date: 'MM/dd/YYYY'}}</td>
                                    <td>{{extensionR3.comment}}</td>
                                    <td *ngIf="extensionR3.waive">
                                        <p-tag styleClass="mr-2" icon="pi pi-check" severity="success" value="Yes">
                                        </p-tag>
                                    </td>
                                    <td *ngIf="!extensionR3.waive">
                                        <p-tag styleClass="mr-2" icon="pi pi-times" severity="danger" value="No">
                                        </p-tag>
                                    </td>
                                    <td [hidden]="isView">
                                        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded  mr-2"
                                            (click)="editExtensionClosing(extensionR3)"></button>
                                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded  mr-2"
                                            (click)="deleteExtensionR3(extensionR3)"></button>

                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">
                                <p-toolbar styleClass="mb-4">
                                    <ng-template pTemplate="left">

                                    </ng-template>
                                    <ng-template pTemplate="right">
                                        <div [hidden]="isView">
                                            <button type="button" [disabled]="R3.closingInformation.actualClosingDate"
                                                (click)="openDialog()" pButton icon="pi pi-plus"
                                                class="mr-2"></button>&nbsp;
                                            <button pButton pRipple icon="pi pi-trash" class="p-button-danger"
                                                (click)="deleteSelectedExtensionR3s()"
                                                [disabled]="!selectedExtensionR3s || !selectedExtensionR3s.length"></button>
                                        </div>
                                    </ng-template>
                                </p-toolbar>
                            </ng-template>
                        </p-table>

                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Post-Closing Information">
            <p-divider align="center">
                <span class="p-tag">Post-Closing Information</span>
            </p-divider>
            <p-table #dt [value]="postClosingR3s" [(selection)]="selectedPostClosingR3s">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem" id=" ">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th id=" ">Notary</th>
                        <th id=" ">Deed Number</th>
                        <th id=" ">Deed Date</th>
                        <th id=" ">Notary Cost</th>
                        <th id=" ">Notary Invoice #</th>
                        <th [hidden]="isView" id=" "></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-postClosingR3>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="postClosingR3"></p-tableCheckbox>
                        </td>
                        <td>{{postClosingR3.notaryId.fullName}}</td>
                        <td>{{postClosingR3.deedNumber}}</td>
                        <td>{{postClosingR3.deedDate | date: 'MM/dd/YYYY'}}</td>
                        <td>{{postClosingR3.notaryCost}}</td>
                        <td>{{postClosingR3.notaryInvoiceNumber}}</td>
                        <td [hidden]="isView">
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded  mr-2"
                                (click)="editPostClosingR3(postClosingR3)"></button>
                            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded "
                                (click)="deletePostClosingR3(postClosingR3)"></button>
                        </td>
                    </tr>

                    
                </ng-template>
              
                <ng-template pTemplate="summary">
                    <p-toolbar styleClass="mb-4">
                        <ng-template pTemplate="left">
                          
                        </ng-template>
                        <ng-template pTemplate="right">
                            <div [hidden]="isView">
                                <button type="button" [disabled]="R3.closingInformation.actualClosingDate"
                                    (click)="openDialogPost()" pButton icon="pi pi-plus" 
                                    class="mr-2"></button>&nbsp;
                                <button pButton pRipple icon="pi pi-trash" class="p-button-danger"
                                    (click)="deleteselectedPostClosingR3s()"
                                    [disabled]="!selectedPostClosingR3s || !selectedPostClosingR3s.length"></button>
                            </div>
                        </ng-template>
                    </p-toolbar>
                </ng-template>
            </p-table>

            <div>
                <p-checkbox [readonly]="isView"  [binary]="true" [(ngModel)]="R3.postClosingComplete" ></p-checkbox>
                <label for="sf"> Post closing complete</label>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Financials">
            <p-divider align="center">
                <span class="p-tag">Financials Information</span>
            </p-divider>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-12">
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-4">
                            <label class="label">Closing Agent Fee</label>
                            <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                                [(ngModel)]="R3.financials.closingAgent.fee" class="p-inputtext-sm">
                            </p-inputNumber>
                        </div>
                        <div class="field col-12 md:col-4">
                            <label class="label">Tax Amount </label>
                            <p-inputNumber [readonly]="isView" mode="currency" currency="USD"
                                locale="en-US" [(ngModel)]="R3.financials.closingAgent.taxAmount"
                                class="p-inputtext-sm">
                            </p-inputNumber>

                        </div>
                        <div class="field col-12 md:col-4">
                            <label class="label">Total Closing Fee </label>
                            <p-inputNumber [readonly]="isView" mode="currency" currency="USD" locale="en-US"
                                [(ngModel)]="R3.financials.closingAgent.totalClosingFee" class="p-inputtext-sm">
                            </p-inputNumber>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>


    <p-dialog [(visible)]="dialog" [focusTrap]="false" [style]="{width: '800px', height: '600px'}" header="Extension R3 Add" [modal]="true"
        styleClass="p-fluid">
        <ng-template pTemplate="content">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-4">
                    <div class="field col-12 md:col-12">
                        <label for="extentionR3Date">Extension R3 Date</label>
                        <p-calendar   [disabled]="isView" id="extentionClosingDate"
                            [(ngModel)]="extensionR3.extentionClosingDate" placeholder="mm/dd/yyyy" class="p-inputtext-sm">
                        </p-calendar>
                        <small class="p-error" *ngIf="submitted && !extensionR3.extentionClosingDate">Extension
                            R3 is required.</small>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="field col-12 md:col-12">
                        <label for="comment">Comment</label>
                        <input type="text" pInputText id="comment" [(ngModel)]="extensionR3.comment" />
                        <small class="p-error" class="p-inputtext-sm" *ngIf="submitted && !extensionR3.comment">Comment
                            is
                            required.</small>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="field col-12 md:col-12">
                        <label for="waived">Waived?</label>
                        <br />
                        <div class="field-radiobutton">
                            <p-radioButton [value]="true" id="waived_true" [(ngModel)]="extensionR3.waive">
                            </p-radioButton>
                            <label for="waived">Yes</label>
                            <label> </label>
                            <label> </label>
                            <p-radioButton [value]="false" id="waived_false" [(ngModel)]="extensionR3.waive">
                            </p-radioButton>
                            <label for="waived">No</label>
                        </div>
                        <small class="p-error" *ngIf="submitted && !extensionR3.comment">Waived is
                            required.</small>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialog()"></button>
            <button [hidden]="isView" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text"
                (click)="saveExtensionR3()"></button>
        </ng-template>
    </p-dialog>

    <p-dialog [focusTrap]="false" [(visible)]="dialogPost" [style]="{width: '800px', height: '600px'}" header="Deed Add" [modal]="true"
        styleClass="p-fluid">
        <ng-template pTemplate="content">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <label for="label">Notary </label>
                    <p-dropdown [readonly]="isView" [options]="notaries" optionLabel="fullName" Class="form-control"
                        [(ngModel)]="postClosingR3.notaryId" placeholder="Notary" class="p-inputtext-sm">

                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="label">Recording Date </label>
                    <p-calendar  [disabled]="isView" inputId="calendar"
                        [(ngModel)]="postClosingR3.recordingDate" placeholder="mm/dd/yyyy"
                        class=" ng-dirty p-inputtext-sm" [showIcon]="true">
                    </p-calendar>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="label">Deed Number </label>
                    <p-inputNumber [readonly]="isView" [useGrouping]="false" [(ngModel)]="postClosingR3.deedNumber"
                        class="p-inputtext-sm"></p-inputNumber>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="label">Deed Date </label>
                    <p-calendar  [disabled]="isView" inputId="calendar"
                        [(ngModel)]="postClosingR3.deedDate" placeholder="mm/dd/yyyy"
                        class=" ng-dirty p-inputtext-sm" [showIcon]="true">
                    </p-calendar>
                </div>
                <div class="field col-12 md:col-6">
                    <label class="label">Notary Cost </label>
                    <p-inputNumber [readonly]="isView" mode="currency" currency="USD"
                        locale="en-US" [(ngModel)]="postClosingR3.notaryCost" class="p-inputtext-sm">
                    </p-inputNumber>
                    
                </div>
                <div class="field col-12 md:col-6">
                    <label class="label">Notary Invoice</label>
                    <p-inputNumber [readonly]="isView" [useGrouping]="false"
                        [(ngModel)]="postClosingR3.notaryInvoiceNumber" class="p-inputtext-sm">
                    </p-inputNumber>
                </div>
                <div class="field col-12 md:col-12">
                    <label for="label">Type Deed </label>
                    <input type="input" (keydown)="onKeydownLetters($event)" [readonly]="isView"
                        [(ngModel)]="postClosingR3.typeDeed" class="p-inputtext-sm" pInputText />
               </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialogPost()"></button>
            <button [hidden]="isView" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text"
                (click)="savePostClosingR3()"></button>
        </ng-template>
    </p-dialog>


    <p-dialog [focusTrap]="false" [(visible)]="dialogBuyer" [style]="{width: '500px', height: '500px'}" header="Buyer Information Add"
        [modal]="true">
        <ng-template pTemplate="content">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-12">
                    <label for="integeronly">Type of buyer </label>
                    <p-dropdown [readonly]="isView" [options]="typesBuyer" optionLabel="name" optionValue="value" styleClass="form-control"
                        [(ngModel)]="buyerInformation.typeOfBuyer" placeholder="Type of buyer" class="p-inputtext-sm">
                    </p-dropdown>
                    <small class="p-error" *ngIf="submitted && !buyerInformation.typeOfBuyer">Type of buyer is required.</small>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="label">Name </label>
                    <input type="input" (keydown)="onKeydownLettersSpacing($event)" [readonly]="isView"
                        [(ngModel)]="buyerInformation.name" class="p-inputtext-sm" pInputText />
                    <small class="p-error" *ngIf="submitted && !buyerInformation.name">Name is required.</small>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="label">Last name </label>
                    <input type="input" (keydown)="onKeydownLettersSpacing($event)" [readonly]="isView"
                        [(ngModel)]="buyerInformation.lastName" class="p-inputtext-sm" pInputText />

                </div>
                <div class="field col-12 md:col-6">
                    <label for="label">Address </label>
                    <input type="input" [readonly]="isView" [(ngModel)]="buyerInformation.address"
                        class="p-inputtext-sm" pInputText />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="label">City </label>
                    <input type="input" [readonly]="isView" [(ngModel)]="buyerInformation.city" class="p-inputtext-sm"
                        pInputText />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="integeronly">State</label>
                    <p-dropdown [readonly]="isView" [options]="states" optionLabel="stateName" styleClass="form-control"
                        [(ngModel)]="buyerInformation.state" placeholder="State" id="stateId" name="stateId"
                        dataKey="stateId" class="p-inputtext-sm">
                    </p-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="integeronly">ZipCode</label>
                    <input type="input" maxlength="5" [readonly]="isView" [(ngModel)]="buyerInformation.zipcode"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        minlength="5" id="zipCodeBuyer" onkeyup="validateZipCode()" class="p-inputtext-sm" pInputText />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="integeronly">Phone</label>
                    <p-inputMask [readonly]="isView" mask="+1(999)-999-9999" [(ngModel)]="buyerInformation.phoneNumber"
                        class="p-inputtext-sm">
                    </p-inputMask>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialogBuyer()"></button>
            <button [hidden]="isView" pButton pRipple label="Add" icon="pi pi-check" class="p-button-text"
                (click)="saveBuyerInformation()"></button>
        </ng-template>
    </p-dialog>

    
    <div class="field col-7 md:col-7" [hidden]="isView" *ngIf="isLoad">
        <button type="button" class="mr-2" pButton [label]="title" (click)="onSubmit()"></button>
        <button type="button" class="mr-2" label="Close" (click)="ConfirmCloseR3()" pButton  ></button>   
    </div>
    <div class="field col-7 md:col-7" [hidden]="!isView" *ngIf="isLoad">
        <button type="button" class="mr-2" label="Close" (click)="closeR3()" pButton  ></button>   
    </div>
   