import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotaryInvoiceElement } from 'app/models/notaryInvoice.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotaryInvoiceRequestService {
  url:string = environment.urlBase
  constructor(private http: HttpClient) { }
  headers: HttpHeaders = new HttpHeaders({
    "Content-type": "application/json",
    "accept": "*"
  });

  getNotaryInvoices() {
    let url_notaryInvoice = this.url+'notaryInvoice/';
    return this.http.get<NotaryInvoiceElement[]>(url_notaryInvoice,{headers: this.headers});
  }
  getNotaryInvoice(numeroFactura) {
    let url_notaryInvoice = this.url+'notaryInvoice/'+numeroFactura;
    return this.http.get<NotaryInvoiceElement>(url_notaryInvoice,{headers: this.headers});
  }

  addNotaryInvoice(notaryInvoice: NotaryInvoiceElement) {
    let url_notaryInvoice = this.url+'notaryInvoice/';
    return this.http.post<NotaryInvoiceElement>(url_notaryInvoice,notaryInvoice,{headers: this.headers});
  }

  updateNotaryInvoice(notaryInvoice: NotaryInvoiceElement) {
    let url_notaryInvoice = this.url+'notaryInvoice/';
    return this.http.put<NotaryInvoiceElement>(url_notaryInvoice,notaryInvoice,{headers: this.headers});
  }

  deleteNotaryInvoice(notaryInvoice: NotaryInvoiceElement){
    let url_notaryInvoice = this.url+'notaryInvoice/'+notaryInvoice.numeroFactura;
    return this.http.delete<NotaryInvoiceElement>(url_notaryInvoice, {observe : 'response'});
  }
  
  getNotaryInvoicesPaginator(first, total){
    let url_notaryInvoice = this.url+'notaryInvoice/'+first+'/'+total;
    return this.http.get<any>(url_notaryInvoice,{headers: this.headers});
  }
}
