import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { NotaryRequestServiceService } from 'app/services/notaryServices/notary-request-service.service';
import { RequestsService } from 'app/services/requestServices/requests.service';
import { NotaryElement } from 'app/models/notary.model';
import { MessageService, } from "primeng/api";
import { StateElement } from 'app/models/state.model';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: 'app-notary-modal',
  templateUrl: './notary-modal.component.html',
  styleUrls: ['./notary-modal.component.css']
})
export class NotaryModalComponent implements OnInit {

  public formNotary: UntypedFormGroup;
  public isEdit: any = false;
  public states: StateElement[];
  notary: NotaryElement;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private formBuilder: UntypedFormBuilder, private notaryService: NotaryRequestServiceService,
    private StatesService: RequestsService, public messageService: MessageService) {
    if (config.data) {
      this.notary = (config.data.notary);
    }
  }

  ngOnInit(): void {

    this.StatesService.getStates().subscribe(res => {
      if (res) {
        this.states = res
      }
    }

    );

    this.formNotary = this.formBuilder.group({
      notaryId: ['', [Validators.required, Number]],
      name: ['', [Validators.required]],
      middleName: [''],
      phoneNumber: [''],
      userName: [''],
      lastName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      active: [true],
      createdByUser: [''],
      createdDate: [Date],
      lastModifiedDate: [Date],
      modifiedByUser: [''],
      version: [0]
    });
    if (this.notary) {
      this.isEdit = true;
      this.formNotary.patchValue(this.notary);
    }
  }

  Cancel() {
    this.ref.close()
  }

  onSubmit() {
    if (this.formNotary.dirty && this.formNotary.valid) {
      this.formNotary.value.fullName = ((this.formNotary.value.name ? this.formNotary.value.name : '') +" "+ (this.formNotary.value.lastName ? this.formNotary.value.lastName : ''));

      if (this.isEdit) {
        this.notaryService.updateNotary(this.formNotary.value).subscribe(
          (_apiResp: any) => {
            this.ref.close(true)
          },
          (_err: any) => {

            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Notary not  edit', life: 3000 });

          }
        )
      } else {
        this.notaryService.addNotary(this.formNotary.value).subscribe(
          (_apiResp: any) => {
            this.ref.close(true)
          },
          (_err: any) => {

            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Notary not  create', life: 3000 });
          }
        )
      }

    }

  }


}
