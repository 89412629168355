import { Component, OnInit, NgModule, ViewChild } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { StateElement } from 'app/models/state.model';
import { RequestsService } from 'app/services/requestServices/requests.service';
import { MessageService, } from "primeng/api";

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
@Component({
  selector: 'app-state-modal',
  templateUrl: './state-modal.component.html',
  styleUrls: ['./state-modal.component.css']
})
export class StateModalComponent implements OnInit {

  public formState: UntypedFormGroup;
  public isEdit : any = false;
  public states : StateElement[];
  public state : StateElement;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private formBuilder: UntypedFormBuilder,private StatesService:RequestsService, public messageService: MessageService) { 
      if (config.data) {
        this.state = (config.data.state);
      }
    }

  ngOnInit(): void {
    this.formState = this.formBuilder.group({
      stateId: ['', [Validators.required]],
      stateName: ['', [Validators.required]],
      active: [true],
      createdByUser: [''],
      createdDate:[ Date],
      lastModifiedDate:[ Date],
      modifiedByUser: [''],
      version:[ 0]
    });
    if (this.state) {
      this.isEdit = true;
      this.formState.patchValue(this.state);
    }

  }


  
  onSubmit() {
    if (this.formState.valid) {
      if(this.isEdit){
     
        this.StatesService.updateState(this.formState.value).subscribe( (apiResp: any) => {
          this.ref.close(true)
        },
        (err: any) => {

          this.messageService.add({severity:'error', summary: 'Error', detail: 'State not  edit', life: 3000});
        }

        )
      }else{
        this.StatesService.addState(this.formState.value).subscribe(  (apiResp: any) => {
          this.ref.close(true)
        },
        (err: any) => {
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Notary not  create', life: 3000});
        }
        )
      }
      
    }

  }
  Cancel(){
    this.ref.close()
  }

}
